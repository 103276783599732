import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  brandibbleRef,
  brandibbleCustomerAttributes,
  status
} from 'constants/PropTypes';
import get from 'utils/get';

import { InputTypes, ErrorObjectKeys } from 'constants/Forms';
import { IDLE } from 'constants/Status';
import {
  handleServerError,
  validateInput,
  validateForm,
  handleValidationErrorMessage
} from 'utils/formUtils';

import TextInput from './TextInput';
const { FIRST_NAME, LAST_NAME, EMAIL, PHONE } = InputTypes;
const { ERROR_MESSAGE, SHOW_ERROR_MESSAGE } = ErrorObjectKeys;

class GuestCredentialsForm extends PureComponent {
  constructor(props) {
    super(...arguments);

    this.state = {
      values: {
        [FIRST_NAME]: get(props, `customerAttributes.${FIRST_NAME}`, ''),
        [LAST_NAME]: get(props, `customerAttributes.${LAST_NAME}`, ''),
        [EMAIL]: get(props, `customerAttributes.${EMAIL}`, ''),
        [PHONE]: get(props, `customerAttributes.${PHONE}`, '')
      },
      errors: {
        [FIRST_NAME]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        },
        [LAST_NAME]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        },
        [EMAIL]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        },
        [PHONE]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        }
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.submitError && this.props.submitError) {
      const { values, errors } = this.state;
      return handleServerError(this.props.submitError, values, errors, state =>
        this.setState(state)
      );
    }
  }

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      values: { ...prevState.values, [target.name]: target.value }
    }));
  };

  handleBlur = ({ target }) => {
    const { values, errors } = this.state;
    handleValidationErrorMessage(target.name, values, errors, state =>
      this.setState(state)
    );
  };

  handleKeyUp = ({ target }) => {
    validateInput(target.name, this.state.values, this.state.errors, state => {
      this.setState(state);
      this.checkFormIsValid(state);
    });
  };

  checkFormIsValid = nextState => {
    const formIsValid = validateForm(nextState.values, nextState.errors);

    if (formIsValid) {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const { onSubmit, orderRef } = this.props;
    const { values } = this.state;

    onSubmit(orderRef, {
      first_name: values[FIRST_NAME],
      last_name: values[LAST_NAME],
      email: values[EMAIL],
      phone: values[PHONE]
    });
  };

  render() {
    const { values, errors } = this.state;
    return (
      <form>
        <TextInput
          name={FIRST_NAME}
          label="First Name"
          value={values[FIRST_NAME]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={false}
          filledOnMount={values[FIRST_NAME] && values[FIRST_NAME].length}
          error={
            errors[FIRST_NAME][SHOW_ERROR_MESSAGE]
              ? errors[FIRST_NAME][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          name={LAST_NAME}
          label="Last Name"
          value={values[LAST_NAME]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={false}
          filledOnMount={values[LAST_NAME] && values[LAST_NAME].length}
          error={
            errors[LAST_NAME][SHOW_ERROR_MESSAGE]
              ? errors[LAST_NAME][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          name={EMAIL}
          value={values[EMAIL]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={false}
          filledOnMount={values[EMAIL] && values[EMAIL].length}
          error={
            errors[EMAIL][SHOW_ERROR_MESSAGE]
              ? errors[EMAIL][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          name={PHONE}
          label="phone number"
          value={values[PHONE]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={false}
          filledOnMount={values[PHONE] && values[PHONE].length}
          error={
            errors[PHONE][SHOW_ERROR_MESSAGE]
              ? errors[PHONE][ERROR_MESSAGE]
              : ''
          }
        />
      </form>
    );
  }
}

GuestCredentialsForm.defaultProps = {
  customerAttributes: {},
  onSubmit: f => f,
  submitStatus: IDLE,
  brandibbleRef: {}
};

GuestCredentialsForm.propTypes = {
  customerAttributes: brandibbleCustomerAttributes,
  onSubmit: PropTypes.func,
  submitStatus: status,
  brandibbleRef: brandibbleRef
};

export default GuestCredentialsForm;
