import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/base';

import cx from 'classnames';
import v from 'vudu';
import { styles as s, colors, size } from 'styles';

const classes = v({
  textInput: {
    '@composes': [s.relative],
    marginBottom: `${size(9)}`
  },
  textInputInner: {
    '@composes': [s.col12, s.relative]
  },
  errorMessage: {
    '@composes': [s.pt3]
  },
  label: {
    '@components': [s.label, s.capitalize, s.midGray, s.absolute, s.pl6],
    top: '50%',
    transform: `translateY(-50%)`,
    transition: `250ms ease-in-out`,
    fontSize: '14px'
  },
  labelFocused: {
    '@composes': [s.labelSmall, s.gold],
    transform: `translateY(-25px)`,
    fontSize: '10px'
  },
  labelFilled: {
    color: colors.midGray,
    transform: `translateY(-25px)`,
    fontSize: '10px'
  },
  labelErrored: {
    color: colors.red
  },
  input: {
    '@composes': [s.col12, s.borderGray, s.p6, s.textInput],
    ':-webkit-autofill': {
      '-webkit-box-shadow': `inset 0 0 0px 9999px ${colors.white}`
    },
    ':not([type=checkbox]):not([type=radio])': {
      borderRadius: 0,
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none'
    }
  },
  inputFocused: {
    borderColor: colors.gold,
    backgroundColor: colors.goldFaded
  },
  inputFilled: {
    borderColor: colors.gray,
    backgroundColor: colors.transparent
  },
  inputErrored: {
    borderColor: colors.red
  }
});

const initialState = {
  isFocused: false,
  isFilled: false,
  isErrored: false
};

class TextInput extends PureComponent {
  state = initialState;

  componentDidMount() {
    if (this.props.focusOnMount) {
      this.handleFocus();
    }

    if (this.props.filledOnMount) {
      this.handleFilled();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.error && this.props.error) {
      this.setState({ isErrored: true });
    }

    if (prevProps.error && !this.props.error) {
      this.setState({ isErrored: false });
    }
  }

  handleFilled = () => this.setState({ isFilled: true });

  handleFocus = () => this.setState({ isFocused: true });

  handleBlur = event => {
    const { onBlur, value } = this.props;

    // If onBlur prop was exists
    // call it
    if (onBlur) onBlur(event);

    // If there is no value
    // reset to initial state
    if (!value || !value.length) {
      return this.resetState();
    }

    // Otherwise, we can assume the input
    // has been filled, and can set the state as so
    this.handleFilled();
  };

  resetState = () => this.setState(initialState);

  render() {
    const { isFocused, isFilled, isErrored } = this.state;

    const {
      id,
      type,
      className,
      placeholder,
      onChange,
      onKeyUp,
      value,
      name,
      label,
      error,
      autoComplete
    } = this.props;

    const labelClasses = cx(classes.label, {
      [classes.labelFocused]: isFocused,
      [classes.labelFilled]: isFilled,
      [classes.labelErrored]: isErrored
    });

    const inputClasses = cx(classes.input, className, {
      [classes.inputFocused]: isFocused,
      [classes.inputFilled]: isFilled,
      [classes.inputErrored]: isErrored
    });

    return (
      <div className={classes.textInput}>
        <div className={classes.textInputInner}>
          <label htmlFor={name}>
            <input
              id={id || name}
              type={type}
              className={inputClasses}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              onKeyUp={onKeyUp}
              value={value}
              name={name}
              autoComplete={autoComplete ? 'on' : 'new-password'}
            />
            <Text className={labelClasses}>{label || name}</Text>
          </label>
        </div>
        {isErrored && (
          <Text
            el="span"
            color="red"
            variant="labelSmall"
            className={classes.errorMessage}
          >
            {error}
          </Text>
        )}
      </div>
    );
  }
}

TextInput.defaultProps = {
  id: '',
  type: 'text',
  className: '',
  placeholder: '',
  onChange: f => f,
  onBlur: f => f,
  onKeyUp: f => f,
  value: '',
  name: '',
  label: '',
  error: '',
  autoComplete: true
};

TextInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  autoComplete: PropTypes.bool
};

export default TextInput;
