import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/base';

import cx from 'classnames';
import v from 'vudu';
import { styles as s } from 'styles';

const classes = v({
  appLoader: {
    '@composes': [
      s.flex,
      s.justifyCenter,
      s.alignCenter,
      s.fixed,
      s.t0,
      s.r0,
      s.b0,
      s.l0,
      s.bgOffwhite
    ],
    zIndex: 10,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'all 1s ease-in-out',
    transform: 'translateY(-20px)'
  },
  loading: {
    opacity: 1,
    transform: 'scale(1.05)'
  },
  loader: {
    '@composes': [
      s.relative,
      s.col8,
      s.mdCol5,
      s.flex,
      s.spaceBetween,
      s.alignCenter
    ],
    maxWidth: '540px',
    top: '-50px',

    span: {
      display: 'inline-block',
      animationName: 'fade',
      animationDuration: '2.5s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'ease-in-out',
      animationDirection: 'normal',
      animationFillMode: 'backwards'
    },

    'span:first-of-type': {
      position: 'relative',
      top: '100px'
    },

    'span:last-of-type': {
      position: 'relative',
      top: '100px'
    },

    'span:nth-of-type(2n+2)': {
      position: 'relative',
      top: '50px'
    },
    'span:nth-child(2)': { animationDelay: '0.5s' },
    'span:nth-child(3)': { animationDelay: '1s' },
    'span:nth-child(4)': { animationDelay: '1.5s' },
    'span:nth-child(5)': { animationDelay: '2s' },
    '@keyframes fade': {
      '0%': {
        opacity: '0.1',
        transform: 'translateY(0) translateX(0) skewX(-3deg) skewY(0)'
      },
      '50%': {
        opacity: '1',
        transform: 'translateY(-2px) translateX(2px) skewX(0) skewY(-2deg)'
      },
      '100%': {
        opacity: '0.1',
        transform: 'translateY(0) translateX(0) skewX(3deg) skewY(0d)'
      }
    }
  }
});

const loaderSentence = 'EACH DAY MADE EACH DAY';

class AppLoader extends Component {
  state = { loading: false };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.loading) {
      return {
        loading: true
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.loading && this.state.loading) {
      return setTimeout(() => this.setState({ loading: false }), 1000);
    }
  }

  render() {
    const appLoaderClasses = cx(classes.appLoader, {
      [classes.loading]: !!this.state.loading
    });

    return (
      <div className={appLoaderClasses}>
        <div className={classes.loader}>
          {loaderSentence.split(' ').map((word, index) => (
            <Text key={`${word}-${index}`} el="span" variant="title">
              {word}
            </Text>
          ))}
        </div>
      </div>
    );
  }
}

AppLoader.defaultProps = {
  loading: false
};

AppLoader.propTypes = {
  loading: PropTypes.bool
};

export default AppLoader;
