import ContainerBase from 'lib/ContainerBase';
import get from 'utils/get';
import triggerToast, { SUCCESS, ERROR } from 'utils/triggerToast';
import Language from 'constants/Language';
import { PENDING, FULFILLED, REJECTED } from 'constants/Status';

import { paginatedOrderData } from 'state/selectors';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  unauthenticateUser,
  paymentsAsArray,
  setDefaultPayment,
  fetchPayments,
  fetchAllCustomerOrders,
  Status
} from 'brandibble-redux';
import { setModal } from 'state/actions/ui/modalActions';

import withNavThemeContext from 'lib/withNavThemeContext';
import { DARK } from 'constants/ColorMaps';

class ProfileContainer extends ContainerBase {
  view = import('views/ProfileView');

  componentDidUpdate(prevProps) {
    if (
      get(prevProps, 'setDefaultPaymentStatus') === Status.PENDING &&
      get(this, 'props.setDefaultPaymentStatus') === Status.FULFILLED
    ) {
      triggerToast(
        Language.t('successMessages.toast.setDefaultPaymentMethod'),
        SUCCESS
      );
    }

    // Triggered by the PastItem components
    // via the withReorderActions HOC
    if (
      get(prevProps, 'attemptReorderStatus') === PENDING &&
      get(this, 'props.attemptReorderStatus') === FULFILLED
    ) {
      triggerToast(Language.t('successMessages.toast.attemptReorder'), SUCCESS);
      this.props.history.push('/checkout');
    }

    if (
      get(prevProps, 'attemptReorderStatus') === PENDING &&
      get(this, 'props.attemptReorderStatus') === REJECTED
    ) {
      triggerToast(Language.t('errorMessages.toast.attemptReorder'), ERROR);
    }
  }

  model = () => {
    const brandibbleRef = get(this, 'props.brandibbleRef');
    const customerId = get(this, 'props.userAttributes.customer_id');
    const withItemDetails = true;

    const fetchPayments = get(this, 'props.actions.fetchPayments', f => f);
    const fetchAllCustomerOrders = get(
      this,
      'props.actions.fetchAllCustomerOrders',
      f => f
    );

    return Promise.all([
      fetchPayments(brandibbleRef),
      fetchAllCustomerOrders(brandibbleRef, customerId, withItemDetails)
    ]);
  };

  afterModel = () => {
    this.props.setNavTheme(DARK);
  };
}

const mapStateToProps = state => ({
  brandibbleRef: get(state, 'brandibble.ref'),
  userAttributes: get(state, 'brandibble.user.attributes'),
  unauthenticateUserStatus: get(state, 'brandibble.status.unauthenticateUser'),
  unauthenticateUserError: get(state, 'brandibble.error.unauthenticateUser'),
  allPaymentMethods: paymentsAsArray(get(state, 'brandibble')),
  paginatedOrderData: paginatedOrderData(state),
  setDefaultPaymentStatus: get(state, 'brandibble.status.setDefaultPayment'),
  setDefaultPaymentError: get(state, 'brandibble.error.setDefaultPayment'),
  redorder: get(state, 'reorder'),
  attemptReorderStatus: get(state, 'status.attemptReorder')
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchPayments,
      fetchAllCustomerOrders,
      unauthenticateUser,
      setModal,
      setDefaultPayment
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavThemeContext(ProfileContainer));
