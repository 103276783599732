import { createSelector } from 'reselect';
import get from 'utils/get';
import currency from 'currency.js';

import { cartSummaryData } from 'state/selectors';

export default createSelector(
  state => cartSummaryData(state),
  state => get(state, 'brandibble.session.order.validatedCart', {}),
  state => get(state, 'brandibble.session.order.orderData.tip', 0),
  (cartSummaryData, validatedCart, tip) => {
    const subtotal = currency(get(validatedCart, 'data.subtotal', 0));
    const tax = currency(get(validatedCart, 'data.tax', 0));
    const tipCurrency = currency(tip);
    const total = subtotal.add(tax).add(tipCurrency);

    return {
      locationName: get(cartSummaryData, 'locationName', ''),
      locationCity: get(cartSummaryData, 'locationCity', ''),
      locationStateCode: get(cartSummaryData, 'locationStateCode', ''),
      lineItems: get(cartSummaryData, 'lineItems', []),
      menuItemsForLineItems: get(cartSummaryData, 'menuItemsForLineItems', []),
      taxRate: currency(get(validatedCart, 'data.tax_rate', 0)),
      subtotal,
      tax,
      total,
      tip: tipCurrency
    };
  }
);
