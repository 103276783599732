import { createSelector } from 'reselect';

import Mocks from 'constants/Mocks';
import isOrderingEnabled from 'utils/isOrderingEnabled';

import locationsDropdownData from './locationsDropdownData';
import storiesDropdownData from './storiesDropdownData';

const { mobileMenu } = Mocks;

export default createSelector(
  state => locationsDropdownData(state),
  state => storiesDropdownData(state),
  (locations, stories) => {
    const hasLocations = locations.length;
    const hasStories = stories.length;

    if (!hasLocations || !hasStories) return [];

    if (!isOrderingEnabled()) {
      return [
        {
          label: 'Locations',
          links: locations
        },
        {
          label: 'About',
          links: stories
        },
        ...mobileMenu.navLinks
      ];
    }

    return [
      {
        label: 'Locations',
        links: locations
      },
      {
        label: 'About',
        links: stories
      },
      ...mobileMenu.orderingEnabledLinks,
      ...mobileMenu.navLinks
    ];
  }
);
