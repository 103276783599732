import React from 'react';
import PropTypes from 'prop-types';

import isExternalUrl from 'utils/isExternalUrl';
import OrderButtonMobile from 'components/OrderButtonMobile';

import { Text, SmartLink } from 'components/base';
import Arrow from 'components/svg/Arrow';

import cx from 'classnames';
import v from 'vudu';
import { classes as c, styles as s } from 'styles';

const classes = v({
  mobileMenuItem: {
    '@composes': [
      s.block,
      s.flex,
      s.alignCenter,
      s.spaceBetween,
      s.py6,
      s.pointer
    ]
  },
  dropdown: {
    transition: `transform 250ms ease-in-out`
  }
});

const MobileMenuItem = ({
  label,
  hasSubLinks,
  isOpen,
  path,
  closeMobileMenu
}) => {
  const dropdownClasses = cx(classes.dropdown, {
    [c.rotate180]: !!isOpen
  });

  /*
    If a mobile menu item has sublinks
    render a dropdown
  */

  if (hasSubLinks) {
    return (
      <span className={classes.mobileMenuItem}>
        <Text variant="accordionLabel">{label}</Text>
        <Arrow className={dropdownClasses} />
      </span>
    );
  }

  /*
    Otherwise render a link
  */

  return (
    <button
      className={c.col12}
      type="button"
      onClick={closeMobileMenu}
      tabIndex={-1}
    >
      {label === 'Order' ? (
        <OrderButtonMobile className={classes.mobileMenuItem} />
      ) : (
        <SmartLink
          external={isExternalUrl(path)}
          to={path}
          className={classes.mobileMenuItem}
        >
          <Text variant="accordionLabel">{label}</Text>
        </SmartLink>
      )}
    </button>
  );
};

MobileMenuItem.defaultProps = {
  path: '',
  closeMobileMenu: f => f
};

MobileMenuItem.propTypes = {
  closeMobileMenu: PropTypes.func,
  label: PropTypes.string.isRequired,
  hasSubLinks: PropTypes.bool.isRequired,
  path: PropTypes.string,
  isOpen: PropTypes.bool /* React.cloneElement supplied Prop */
};

export default MobileMenuItem;
