import React, { PureComponent } from 'react';
import get from 'utils/get';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  addLineItem,
  addOptionToLineItem,
  removeOptionFromLineItem,
  removeLineItem,
  pushLineItem,
  setLineItemQuantity,
  setLineItemInstructions
} from 'brandibble-redux';
import {
  toggleAddOptionToLineItem,
  addLineItemAndOpenConfigurationModal
} from 'state/actions/brandibble/lineItemActions';

const withLineItemActions = WrappedComponent => {
  /* eslint-disable react/prefer-stateless-function */
  class WithLineItemActions extends PureComponent {
    triggerAddLineItemAndOpenConfigurationModal = (orderRef, item) => {
      const {
        withLineItemActions: { addLineItemAndOpenConfigurationModal }
      } = this.props;

      if (!item) return null;
      return addLineItemAndOpenConfigurationModal(orderRef, item);
    };

    triggerSetLineItemQuantity = (orderRef, lineItem, quantity) => {
      const {
        withLineItemActions: { setLineItemQuantity }
      } = this.props;

      return setLineItemQuantity(orderRef, lineItem, quantity);
    };

    triggerAddOrIncrementLineItem = (orderRef, item, lineItem) => {
      if (!lineItem) {
        return this.triggerAddLineItem(orderRef, item);
      }
      return this.triggerIncrementLineItemQuantity(
        orderRef,
        lineItem,
        lineItem.quantity + 1
      );
    };

    triggerDecrementOrRemoveLineItem = (orderRef, lineItem) => {
      if (!lineItem) return null;

      const lineItemQuantity = get(lineItem, 'quantity', 0);
      if (lineItemQuantity > 1) {
        return this.triggerDecrementLineItemQuantity(orderRef, lineItem);
      }
      return this.triggerRemoveLineItem(orderRef, lineItem);
    };

    triggerAddLineItem = (orderRef, item) => {
      const {
        withLineItemActions: { addLineItem }
      } = this.props;

      return addLineItem(orderRef, item);
    };

    triggerRemoveLineItem = (orderRef, lineItem) => {
      const {
        withLineItemActions: { removeLineItem }
      } = this.props;

      if (!lineItem) return null;
      return removeLineItem(orderRef, lineItem);
    };

    triggerIncrementLineItemQuantity = (orderRef, lineItem) => {
      const {
        withLineItemActions: { setLineItemQuantity }
      } = this.props;

      if (!lineItem) return null;
      return setLineItemQuantity(orderRef, lineItem, lineItem.quantity + 1);
    };

    triggerDecrementLineItemQuantity = (orderRef, lineItem) => {
      const {
        withLineItemActions: { setLineItemQuantity }
      } = this.props;

      if (!lineItem) return null;
      return setLineItemQuantity(orderRef, lineItem, lineItem.quantity - 1);
    };

    triggerAddOptionToLineItem = (orderRef, lineItem) => (
      optionGroup,
      optionItem
    ) => {
      const addOptionToLineItem = get(
        this,
        'props.withLineItemActions.addOptionToLineItem',
        f => f
      );

      if (!lineItem) return null;
      return addOptionToLineItem(orderRef, lineItem, optionGroup, optionItem);
    };

    triggerRemoveOptionFromLineItem = (orderRef, lineItem) => optionItem => {
      const removeOptionFromLineItem = get(
        this,
        'props.withLineItemActions.removeOptionFromLineItem',
        f => f
      );

      if (!lineItem) return null;
      return removeOptionFromLineItem(orderRef, lineItem, optionItem);
    };

    triggerToggleAddOptionToLineItem = (orderRef, lineItem) => (
      optionGroupMapping,
      optionItem
    ) => {
      const toggleAddOptionToLineItem = get(
        this,
        'props.withLineItemActions.toggleAddOptionToLineItem',
        f => f
      );

      return toggleAddOptionToLineItem(
        orderRef,
        lineItem,
        optionGroupMapping,
        optionItem
      );
    };

    triggerSetLineItemInstructions = (orderRef, lineItem) => instructions => {
      const setLineItemInstructions = get(
        this,
        'props.withLineItemActions.setLineItemInstructions',
        f => f
      );

      return setLineItemInstructions(orderRef, lineItem, instructions);
    };

    render() {
      const orderRef = get(this, 'props.order.ref');
      /**
       * Item refers to a raw menu item
       * that has yet to be added to cart
       */
      const item = get(this, 'props.item');

      /**
       * LineItem refers to an item that has been added
       * to cart. It can be configurable (hasOptionGroups)
       * or unconfigurable (does not have optionGroups)
       */
      const lineItem = get(this, 'props.lineItem');

      return (
        <WrappedComponent
          addLineItemAndOpenConfigurationModal={() =>
            this.triggerAddLineItemAndOpenConfigurationModal(orderRef, item)
          }
          setLineItemQuantity={quantity =>
            this.triggerSetLineItemQuantity(orderRef, lineItem, quantity)
          }
          addLineItem={() => this.triggerAddLineItem(orderRef, item)}
          removeLineItem={() => this.triggerRemoveLineItem(orderRef, lineItem)}
          addOrIncrementLineItem={() =>
            this.triggerAddOrIncrementLineItem(orderRef, item, lineItem)
          }
          decrementOrRemoveLineItem={() =>
            this.triggerDecrementOrRemoveLineItem(orderRef, lineItem)
          }
          addOptionToLineItem={(optionGroupMapping, optionItem) =>
            this.triggerAddOptionToLineItem(orderRef, lineItem)(
              optionGroupMapping,
              optionItem
            )
          }
          removeOptionFromLineItem={optionItem =>
            this.triggerRemoveOptionFromLineItem(orderRef, lineItem)(optionItem)
          }
          toggleAddOptionToLineItem={(optionGroupMapping, optionItem) =>
            this.triggerToggleAddOptionToLineItem(orderRef, lineItem)(
              optionGroupMapping,
              optionItem
            )
          }
          setLineItemInstructions={instructions =>
            this.triggerSetLineItemInstructions(orderRef, lineItem)(
              instructions
            )
          }
          {...this.props}
        />
      );
    }
  }
  /* eslint-enable */

  const mapStateToProps = state => ({
    order: get(state, 'brandibble.session.order')
  });

  const mapDispatchToProps = dispatch => ({
    withLineItemActions: bindActionCreators(
      {
        addLineItem,
        addOptionToLineItem,
        removeOptionFromLineItem,
        removeLineItem,
        pushLineItem,
        setLineItemQuantity,
        setLineItemInstructions,
        toggleAddOptionToLineItem,
        addLineItemAndOpenConfigurationModal
      },
      dispatch
    )
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithLineItemActions);
};

export default withLineItemActions;
