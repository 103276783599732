import ContainerBase from 'lib/ContainerBase';
import ContentfulData from 'lib/ContentfulData';

import { connect } from 'react-redux';

import get from 'utils/get';
import withNavThemeContext from 'lib/withNavThemeContext';
import { citiesAndLocationsCount } from 'state/selectors';

class HomeContainer extends ContainerBase {
  view = import('views/HomeView');

  model = () => {
    const { activeLocale } = this.props;
    return ContentfulData.getEntries({
      content_type: 'home',
      include: 2,
      locale: activeLocale
    }).then(res => res.items[0]);
  };

  afterModel = model => {
    this.props.setNavTheme(get(model, 'fields.headerTheme'));
  };
}

const mapStateToProps = state => {
  return {
    activeLocale: get(state, 'locale.activeLocale'),
    citiesAndLocationsCount: citiesAndLocationsCount(state)
  };
};

export { HomeContainer };
export default connect(mapStateToProps)(withNavThemeContext(HomeContainer));
