import React, { Component } from 'react';

import Vars from 'constants/Vars';
const { jsBreakpoints } = Vars;

const DeviceWidthContext = React.createContext({});

class DeviceWidthProvider extends Component {
  state = { isMobile: false };

  componentWillMount() {
    this.checkDeviceWidth();
    window.addEventListener('resize', this.checkDeviceWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkDeviceWidth);
  }

  checkDeviceWidth = () => {
    const { isMobile } = this.state;

    if (!!isMobile) {
      if (window.innerWidth > jsBreakpoints.md) {
        this.setState({ isMobile: false });
      }
    }

    if (!isMobile) {
      if (window.innerWidth < jsBreakpoints.md) {
        this.setState({ isMobile: true });
      }
    }
  };

  render() {
    return (
      <DeviceWidthContext.Provider value={this.state}>
        {this.props.children}
      </DeviceWidthContext.Provider>
    );
  }
}

export { DeviceWidthContext };
export default DeviceWidthProvider;
