import { createSelector } from 'reselect';

import get from 'utils/get';
import isEmptyObject from 'utils/isEmptyObject';
import { aboutUsIndexLink } from 'constants/DropdownLinks';

export default createSelector(
  state => get(state, 'global.data', {}),
  globalData => {
    if (isEmptyObject(globalData)) return [];

    const { fields } = globalData;

    const stories = get(fields, 'featuredStories', []).map(story => ({
      label: get(story, 'fields.title'),
      path: `/about/${get(story, 'fields.slug', '#')}`,
      nav: true
    }));

    return [aboutUsIndexLink, ...stories];
  }
);
