import React, { Component } from 'react';
import { Input } from 'components/base';
import v from 'vudu';
import { styles as s, breakpoints, colors, size } from 'styles';

const classes = v({
  emailSignup: {
    '@composes': [s.col12, s.flex, s.flexColumn, s.alignCenter, s.textInput],
    marginBottom: `${size(11)}`,
    [breakpoints.md]: {
      '@composes': [s.flexRow, s.alignInitial, s.bgOffwhite],
      marginBottom: `calc(${size(11)} * 2)`
    }
  },
  emailInput: {
    '@composes': [s.px5, s.py10, s.textCenter],
    border: `solid 1px ${colors.midGray}`,
    backgroundColor: `transparent`,
    [breakpoints.md]: {
      '@composes': [s.m10, s.p0, s.textLeft],
      border: `none`
    }
  },
  submitContainer: {
    '@composes': [s.flex],
    height: `100%`
  },
  submitButton: {
    '@component': [
      s.bgTransparent,
      s.pt10,
      s.pointer,
      s.navItem,
      s.hoverOpacity
    ],
    border: `none`,
    [breakpoints.md]: {
      '@composes': [s.p10]
    }
  }
});

export default class EmailSignup extends Component {
  state = { emailValue: '' };

  render() {
    const { emailValue } = this.state;

    return (
      <form
        className={classes.emailSignup}
        action={
          'https://tartinebakery.us15.list-manage.com/subscribe/post?u=e161d4c67e555753628eb3cac&amp;id=d93b34f799'
        }
        method="post"
        target="_blank"
        onSubmit={this.handleSubmit}
        noValidate
      >
        <Input
          className={classes.emailInput}
          id="mce=EMAIL"
          type="email"
          aria-label="Signup for our newsletter"
          name="EMAIL"
          placeholder="Signup for our newsletter"
          value={emailValue}
          handleChange={e => {
            this.setState({ emailValue: e.target.value });
          }}
        />
        <div>
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_e161d4c67e555753628eb3cac_d93b34f799"
              tabIndex="-1"
            />
          </div>
          <div className={classes.submitContainer}>
            <input
              className={classes.submitButton}
              type="submit"
              aria-label="Join Now"
              value="Join Now"
              name="subscribe"
              id="mc-embedded-subscribe"
            />
          </div>
        </div>
      </form>
    );
  }
}
