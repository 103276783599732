import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import get from 'utils/get';

const modalRoot = document.getElementById('modal-root');

class ModalPortal extends Component {
  render() {
    if (!modalRoot) return null;
    return ReactDOM.createPortal(get(this, 'props.children'), modalRoot);
  }
}

ModalPortal.defaultProps = {
  children: null
};

ModalPortal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default ModalPortal;
