import React from 'react';
import PropTypes from 'prop-types';

import { classes as c } from 'styles';

const Main = ({ children }) => (
  <main className={c.min100vh} role="main" id="MainContent">
    {children}
  </main>
);

Main.propTypes = {
  children: PropTypes.element.isRequired
};

export default Main;
