import { put, select } from 'redux-saga/effects';
import { Status, createNewOrder, currentLocation } from 'brandibble-redux';
import { PICKUP, CREDIT } from 'constants/Brandibble';
import get from 'utils/get';

const { PENDING, FULFILLED, REJECTED } = Status;

export const ON_SUBMIT_ORDER_SAGA = 'ON_SUBMIT_ORDER_SAGA';

function* onSubmitOrderSaga() {
  yield put({ type: `${ON_SUBMIT_ORDER_SAGA}_${PENDING}` });

  const brandibble = yield select(state => state.brandibble);
  const currentOrderLocation = yield select(state =>
    currentLocation(state.brandibble)
  );

  const brandibbleRef = get(brandibble, 'ref');
  const locationId = get(currentOrderLocation, 'location_id', null);

  yield put(createNewOrder(brandibbleRef, locationId, PICKUP, CREDIT));
}

/**
 * If all dispatched actions become FULFILLED
 * and ON_SUBMIT_ORDER_SAGA is in a PENDING state
 * we can assume ON_SUBMIT_ORDER_SAGA_FULFILLED
 */

function* handleOnSubmitOrderSagaFulfilled() {
  const onSubmitOrderSagaPending = yield select(
    state => state.status.onSubmitOrderSaga === PENDING
  );
  const createNewOrderFulfilled = yield select(
    state => state.brandibble.status.createNewOrder === FULFILLED
  );

  if (onSubmitOrderSagaPending && createNewOrderFulfilled) {
    yield put({ type: `${ON_SUBMIT_ORDER_SAGA}_${FULFILLED}` });
  }
}

/**
 * If any dispatched actions become REJECTED
 * and ON_UNAUTHENTICATION_SAGA is in a PENDING state
 * we can assume ON_UNAUTHENTICATION_SAGA_REJECTED
 */

function* handleOnSubmitOrderSagaRejected() {
  const onSubmitOrderSagaPending = yield select(
    state => state.status.onSubmitOrderSaga === PENDING
  );
  const createNewOrderRejected = yield select(
    state => state.brandibble.status.createNewOrder === FULFILLED
  );

  if (onSubmitOrderSagaPending && createNewOrderRejected) {
    yield put({ type: `${ON_SUBMIT_ORDER_SAGA}_${REJECTED}` });
  }
}

export { handleOnSubmitOrderSagaFulfilled, handleOnSubmitOrderSagaRejected };
export default onSubmitOrderSaga;
