import ContainerBase from 'lib/ContainerBase';
import get from 'utils/get';
import triggerToast, { SUCCESS } from 'utils/triggerToast';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userIsAuthenticated } from 'state/selectors';
import { sendSupportTicket, Status } from 'brandibble-redux';

import withNavThemeContext from 'lib/withNavThemeContext';
import { DARK } from 'constants/ColorMaps';
import Language from 'constants/Language';

class SupportContainer extends ContainerBase {
  view = import('views/SupportView');

  componentDidUpdate(prevProps) {
    if (
      get(prevProps, 'sendSupportTicketStatus') === Status.PENDING &&
      get(this, 'props.sendSupportTicketStatus') === Status.FULFILLED
    ) {
      triggerToast(
        Language.t('successMessages.toast.sendSupportTicket'),
        SUCCESS
      );
    }
  }

  afterModel = () => {
    this.props.setNavTheme(DARK);
  };
}

const mapStateToProps = state => ({
  brandibbleRef: get(state, 'brandibble.ref'),
  userIsAuthenticated: userIsAuthenticated(state),
  userAttributes: get(state, 'brandibble.user.attributes', {}),
  sendSupportTicketStatus: get(state, 'brandibble.status.sendSupportTicket'),
  sendSupportTicketError: get(state, 'brandibble.error.sendSupportTicket')
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendSupportTicket
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavThemeContext(SupportContainer));
