import { ATTEMPT_REORDER } from 'state/actions/brandibble/orderActions';

const initialState = {
  isReorderable: false,
  itemsWereRemoved: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${ATTEMPT_REORDER}_PENDING`:
      return { ...state };
    case `${ATTEMPT_REORDER}_FULFILLED`:
      return { ...state, ...payload };
    case `${ATTEMPT_REORDER}_REJECTED`:
      return { ...state };
    default:
      return state;
  }
};
