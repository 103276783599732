import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';

import cx from 'classnames';
import v from 'vudu';
import { styles as s } from 'styles';

const classes = v({
  smartLink: {
    display: 'inline-block',
    textDecoration: 'none'
  },
  navLink: {
    '@composes': [s.pb1],
    borderBottom: 'solid 1px transparent'
  },
  disabled: {
    pointerEvents: 'none'
  },
  controlled: {
    display: 'block',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline'
    }
  }
});

const SmartLink = ({
  external,
  to,
  children,
  className,
  mailto,
  nav,
  disable,
  tel,
  ariaLabel,
  onClick,
  onKeyDown,
  controlled
}) => {
  const smartLinkClasses = cx(classes.smartLink, {
    [classes.navLink]: !!nav,
    [classes.disabled]: !!disable,
    [classes.controlled]: !!controlled,
    [className]: !!className
  });

  if (mailto) {
    return (
      <a
        className={smartLinkClasses}
        href={`mailto:${to}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  if (tel) {
    return (
      <a className={smartLinkClasses} href={`tel:${to}`}>
        {children}
      </a>
    );
  }

  if (external) {
    return (
      <a
        className={smartLinkClasses}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  if (nav) {
    return (
      <NavLink exact className={smartLinkClasses} to={to} onKeyDown={onKeyDown}>
        {children}
      </NavLink>
    );
  }

  if (controlled) {
    return (
      <span
        tabIndex={0}
        className={smartLinkClasses}
        onKeyDown={onKeyDown}
        onClick={onClick}
      >
        {children}
      </span>
    );
  }

  return (
    <Link
      className={smartLinkClasses}
      to={to}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

SmartLink.propTypes = {
  to: PropTypes.string,
  external: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element
  ]),
  mailto: PropTypes.bool,
  disable: PropTypes.bool,
  tel: PropTypes.bool,
  nav: PropTypes.bool,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func
};

SmartLink.defaultProps = {
  to: '',
  external: false,
  children: '',
  className: '',
  mailto: false,
  disable: false,
  tel: false,
  nav: false,
  ariaLabel: '',
  onClick: f => f,
  onKeyDown: f => f
};

export default SmartLink;
