import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'utils/get';
import { brandibbleRef, status } from 'constants/PropTypes';

import Reaptcha from 'reaptcha';

import { InputTypes, ErrorObjectKeys } from 'constants/Forms';
import {
  handleServerError,
  handleValidationErrorMessage,
  validateInput,
  validateForm,
  sanitizeCreditCardExpiration
} from 'utils/formUtils';

import TextInput from './TextInput';

import { classes as c, format as f } from 'styles';

const {
  CREDIT_CARD_NUMBER,
  CREDIT_CARD_EXPIRATION,
  CREDIT_CARD_CVV,
  CREDIT_CARD_ZIP_CODE
} = InputTypes;
const { ERROR_MESSAGE, SHOW_ERROR_MESSAGE } = ErrorObjectKeys;

class GuestPaymentForm extends PureComponent {
  constructor(props) {
    super(...arguments);

    this.state = {
      captchaValid: false,
      values: {
        [CREDIT_CARD_NUMBER]: get(
          props,
          `orderCardAttributes.${CREDIT_CARD_NUMBER}`,
          ''
        ),
        [CREDIT_CARD_EXPIRATION]: get(
          props,
          `orderCardAttributes.${CREDIT_CARD_EXPIRATION}`,
          ''
        ),
        [CREDIT_CARD_CVV]: get(
          props,
          `orderCardAttributes.${CREDIT_CARD_CVV}`,
          ''
        ),
        [CREDIT_CARD_ZIP_CODE]: get(
          props,
          `orderCardAttributes.${CREDIT_CARD_ZIP_CODE}`,
          ''
        )
      },
      errors: {
        [CREDIT_CARD_NUMBER]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        },
        [CREDIT_CARD_EXPIRATION]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        },
        [CREDIT_CARD_CVV]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        },
        [CREDIT_CARD_ZIP_CODE]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        }
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.submitError && this.props.submitError) {
      const { values, errors } = this.state;
      return handleServerError(this.props.submitError, values, errors, state =>
        this.setState(state)
      );
    }
  }

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      values: { ...prevState.values, [target.name]: target.value }
    }));
  };

  handleBlur = ({ target }) => {
    const { values, errors } = this.state;
    handleValidationErrorMessage(target.name, values, errors, state =>
      this.setState(state)
    );
  };

  handleKeyUp = ({ target }) => {
    validateInput(target.name, this.state.values, this.state.errors, state => {
      this.setState(state);
      this.checkFormIsValid(state);
    });
  };

  checkFormIsValid = nextState => {
    const formIsValid = validateForm(nextState.values, nextState.errors);

    if (formIsValid && this.state.captchaValid) {
      this.handleSubmit();
    }
  };

  onVerify = token => {
    this.setState(
      {
        captchaValid: true
      },
      () => {
        this.checkFormIsValid(this.state);
      }
    );
  };

  handleSubmit = () => {
    const { onSubmit, orderRef } = this.props;
    const { values } = this.state;

    const creditCardDetails = {
      [CREDIT_CARD_NUMBER]: values[CREDIT_CARD_NUMBER],
      [CREDIT_CARD_EXPIRATION]: sanitizeCreditCardExpiration(
        values[CREDIT_CARD_EXPIRATION]
      ),
      [CREDIT_CARD_CVV]: values[CREDIT_CARD_CVV],
      [CREDIT_CARD_ZIP_CODE]: values[CREDIT_CARD_ZIP_CODE]
    };

    onSubmit(orderRef, 'credit', creditCardDetails);
  };

  render() {
    const { values, errors } = this.state;

    return (
      <form>
        <TextInput
          name={CREDIT_CARD_NUMBER}
          label="Card Number"
          value={values[CREDIT_CARD_NUMBER]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          error={
            errors[CREDIT_CARD_NUMBER][SHOW_ERROR_MESSAGE]
              ? errors[CREDIT_CARD_NUMBER][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          name={CREDIT_CARD_EXPIRATION}
          label="Expiry Date (MM/YY)"
          value={values[CREDIT_CARD_EXPIRATION]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          error={
            errors[CREDIT_CARD_EXPIRATION][SHOW_ERROR_MESSAGE]
              ? errors[CREDIT_CARD_EXPIRATION][ERROR_MESSAGE]
              : ''
          }
        />
        <div className={f(c.flex)}>
          <div className={f(c.col4, c.pr4)}>
            <TextInput
              name={CREDIT_CARD_CVV}
              label="CVV"
              value={values[CREDIT_CARD_CVV]}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onKeyUp={this.handleKeyUp}
              error={
                errors[CREDIT_CARD_CVV][SHOW_ERROR_MESSAGE]
                  ? errors[CREDIT_CARD_CVV][ERROR_MESSAGE]
                  : ''
              }
            />
          </div>
          <div className={f(c.col8, c.pl4)}>
            <TextInput
              name={CREDIT_CARD_ZIP_CODE}
              label="Zip Code"
              value={values[CREDIT_CARD_ZIP_CODE]}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onKeyUp={this.handleKeyUp}
              error={
                errors[CREDIT_CARD_ZIP_CODE][SHOW_ERROR_MESSAGE]
                  ? errors[CREDIT_CARD_ZIP_CODE][ERROR_MESSAGE]
                  : ''
              }
            />
          </div>
        </div>
        <div className={f(c.flex)}>
          <Reaptcha
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            onVerify={this.onVerify}
          />
        </div>
      </form>
    );
  }
}

GuestPaymentForm.defaultProps = {
  onSubmit: f => f
};

GuestPaymentForm.propTypes = {
  onSubmit: PropTypes.func,
  submitStatus: status,
  brandibbleRef: brandibbleRef
};

export default GuestPaymentForm;
