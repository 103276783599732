import React, { PureComponent } from 'react';
import get from 'utils/get';
import triggerToast, { SUCCESS } from 'utils/triggerToast';
import Language from 'constants/Language';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateUser, Status } from 'brandibble-redux';
import { resetModal } from 'state/actions/ui/modalActions';

import { Text } from 'components/base';
import { EditPasswordForm } from 'components/forms';

import { classes as c, format as f } from 'styles';

const { PENDING, FULFILLED } = Status;

class EditPasswordModal extends PureComponent {
  componentDidUpdate(prevProps) {
    if (
      get(prevProps, 'updateUserStatus') === PENDING &&
      get(this, 'props.updateUserStatus') === FULFILLED
    ) {
      triggerToast(Language.t('successMessages.toast.editPassword'), SUCCESS);
      get(this, 'props.actions.resetModal', f => f)();
    }
  }

  render() {
    const customerId = get(this, 'props.userAttributes.customer_id');
    const resetModal = get(this, 'props.actions.resetModal');
    const updateUser = get(this, 'props.actions.updateUser');
    const updateUserStatus = get(this, 'props.updateUserStatus');
    const updateUserError = get(this, 'props.updateUserError');
    const brandibbleRef = get(this, 'props.brandibbleRef');

    return (
      <div>
        <div className={f(c.center, c.mb9)}>
          <Text variant="modalTitle">Edit Password</Text>
        </div>
        <EditPasswordForm
          brandibbleRef={brandibbleRef}
          customerId={customerId}
          onSubmit={updateUser}
          submitStatus={updateUserStatus}
          submitError={updateUserError}
          resetModal={resetModal}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userAttributes: get(state, 'brandibble.user.attributes'),
  brandibbleRef: get(state, 'brandibble.ref'),
  updateUserStatus: get(state, 'brandibble.status.updateUser'),
  updateUserError: get(state, 'brandibble.error.updateUser')
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateUser,
      resetModal
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPasswordModal);
