import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'utils/get';
import triggerToast, { SUCCESS, ERROR } from 'utils/triggerToast';
import Language from 'constants/Language';
import Vars from 'constants/Vars';
import capitalize from 'utils/capitalize';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Status, locationsAsArray, setRequestedAt } from 'brandibble-redux';
import { resetModal } from 'state/actions/ui/modalActions';
import { currentOrderMenuPath } from 'state/selectors';

import { Text, Button } from 'components/base';

import v from 'vudu';
import { breakpoints, styles as s, classes as c, format as f } from 'styles';

const { IDLE, PENDING, FULFILLED, REJECTED } = Status;
const { confirmationModalMaxWidth, confirmationModalMinHeight } = Vars;

const classes = v({
  locationClosedModal: {
    '@composes': [s.flex, s.flexColumn],
    maxWidth: `${confirmationModalMaxWidth}px`,
    minHeight: `${confirmationModalMinHeight}px`
  },
  modalButtonsContainer: {
    '@composes': [s.flex, s.alignCenter, s.spaceBetween],

    [breakpoints.md]: {
      '@composes': [s.spaceAround]
    }
  }
});

class LocationClosedModal extends PureComponent {
  componentDidUpdate(prevProps) {
    const resetModal = get(this, 'props.actions.resetModal', f => f);
    if (
      get(prevProps, 'setRequestedAtStatus') === PENDING &&
      get(this, 'props.setRequestedAtStatus') === FULFILLED
    ) {
      triggerToast(
        Language.t('successMessages.toast.locationClosedModal'),
        SUCCESS
      );
      resetModal();
    }

    if (
      (get(prevProps, 'setRequestedAtStatus') === PENDING &&
        get(this, 'props.setRequestedAtStatus') === REJECTED) ||
      (get(prevProps, 'removeInvalidItemsStatus') === PENDING &&
        get(this, 'props.removeInvalidItemsStatus') === REJECTED)
    ) {
      triggerToast(
        Language.t('errorMessages.toast.locationClosedModal'),
        ERROR
      );
      resetModal();
    }
  }

  getLocation = locationsAsArray => {
    const locationId = get(this, 'props.data.locationId');

    if (locationId) {
      return locationsAsArray.find(
        location => location.location_id === locationId
      );
    }

    const currentLocationId = get(this, 'props.orderData.location_id');
    return locationsAsArray.find(
      location => location.location_id === currentLocationId
    );
  };

  renderNextAvailableDaypart = nextAvailableDaypart => {
    if (!nextAvailableDaypart) {
      return (
        <Text className={f(c.center, c.my10)} variant="title">
          Getting next available order time...
        </Text>
      );
    }

    return (
      <Text
        className={f(c.center, c.my10)}
        variant="title"
        color="gold"
      >{`${capitalize(
        nextAvailableDaypart.weekday
      )} ${nextAvailableDaypart.daypart.trim()}`}</Text>
    );
  };

  handleConfirmation = nextAvailableDaypart => {
    const actions = get(this, 'props.actions');
    const orderRef = get(this, 'props.orderRef');
    const setRequestedAt = get(actions, 'setRequestedAt', f => f);
    const nextAvailableRequestedAt = get(nextAvailableDaypart, 'utc');

    return setRequestedAt(orderRef, nextAvailableRequestedAt, true);
  };

  handleCancel = () => {
    const locationId = get(this, 'props.data.locationId');
    const resetModal = get(this, 'props.actions.resetModal');

    // If a location id is present on the data object,
    // we can assume the location closed modal
    // is is triggered by a newly requested location
    // being temporarily closed
    // In this case, we redirect the user to the current order's location
    // before resetting the modal
    if (!!locationId) {
      const redirectUrl = get(this, 'props.currentOrderMenuPath');
      this.props.history.push(redirectUrl);
    }
    resetModal();
  };

  render() {
    const locationsAsArray = get(this, 'props.locationsAsArray', []);
    const requestedServiceType = get(this, 'props.orderData.service_type');
    const isSubmitting = get(this, `props.setRequestedAtStatus`) === PENDING;

    const requestedLocation = this.getLocation(locationsAsArray);
    const nextAvailableDaypart = get(
      requestedLocation,
      `first_times.${requestedServiceType}`
    );

    return (
      <div className={classes.locationClosedModal}>
        <div className={c.grow1}>
          <Text className={f(c.center, c.mb7)} variant="modalTitle">
            {Language.t('locationClosedModal.title')}
          </Text>
          <Text variant="paragraph">
            {Language.t('locationClosedModal.description')}
          </Text>
          <div>{this.renderNextAvailableDaypart(nextAvailableDaypart)}</div>
        </div>
        <div className={classes.modalButtonsContainer}>
          <Button
            onClick={this.handleCancel}
            variant="basicText"
            text={Language.t('locationClosedModal.cancel')}
            type="button"
          />
          <Button
            onClick={() => this.handleConfirmation(nextAvailableDaypart)}
            isDisabled={!nextAvailableDaypart}
            disabledClassName={c.basicTextDisabled}
            showLoading={isSubmitting}
            type="submit"
            variant="basicText"
            text={Language.t('locationClosedModal.confirm')}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  orderData: get(state, 'brandibble.session.order.orderData', {}),
  orderRef: get(state, 'brandibble.session.order.ref', {}),
  locationsAsArray: locationsAsArray(state.brandibble),
  currentOrderMenuPath: currentOrderMenuPath(state),
  setRequestedAtStatus: get(state, 'brandibble.status.setRequestedAt', IDLE)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetModal,
      setRequestedAt
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LocationClosedModal));
