import get from 'utils/get';
import { DateTime } from 'luxon';
import { ASAP, ErrorCodes } from 'constants/Brandibble';
import Language from 'constants/Language';
import {
  setRequestedAt,
  validateCurrentCart,
  removeLineItem,
  setOrderLocationId,
  pushLineItem,
  fetchLocation,
  setMiscOptions,
  actionTypes as brandibbleActionTypes
} from 'brandibble-redux';
import { setModal } from 'state/actions/ui/modalActions';
import { fetchAndSetupMenu } from 'state/actions/brandibble/menuActions';
import { allLocations, currentOrderMenuType } from 'state/selectors';
import { CATERING } from 'constants/MenuTypes';
import {
  INVALID_ITEMS_MODAL,
  LOCATION_CLOSED_MODAL,
  CONFIRMATION_MODAL
} from 'constants/ModalVariants';

const { SET_ORDER_LOCATION_ID } = brandibbleActionTypes;

export const VALIDATE_AND_ATTEMPT_SET_REQUESTED_AT =
  'VALIDATE_AND_ATTEMPT_SET_REQUESTED_AT';
export const REMOVE_INVALID_ITEMS_AND_SET_REQUESTED_AT =
  'REMOVE_INVALID_ITEMS_AND_SET_REQUESTED_AT';
export const ATTEMPT_REORDER = 'ATTEMPT_REORDER';
export const TOGGLE_ADD_LINE_ITEM_OPTION = 'TOGGLE_ADD_LINE_ITEM_OPTION';

export const overrideIncludeUtensils = (includeUtensils = false) => (
  dispatch,
  getState
) => {
  const state = getState();
  const orderRef = get(state, 'brandibble.session.order.ref');
  return dispatch(
    setMiscOptions(orderRef, {
      include_utensils: includeUtensils
    })
  );
};

export const removeInvalidItemsAndSetRequestedAt = (
  invalidItems,
  requestedAt
) => (dispatch, getState) => {
  const state = getState();
  const orderRef = get(state, 'brandibble.session.order.ref');

  const promises = invalidItems.map(invalidItem =>
    dispatch(removeLineItem(orderRef, invalidItem))
  );

  const payload = Promise.all(promises).then(() =>
    dispatch(_finalizeSetRequestedAt(requestedAt))
  );

  return dispatch({
    type: REMOVE_INVALID_ITEMS_AND_SET_REQUESTED_AT,
    payload
  });
};

export const validateAndAttemptSetRequestedAt = requestedAt => (
  dispatch,
  getState
) => {
  const state = getState();
  const brandibbleRef = get(state, 'brandibble.ref');
  const currentOrderData = get(state, 'brandibble.session.order.orderData');

  // If the current cart is empty, don't bother validating
  if (!get(currentOrderData, 'cart', []).length) {
    return dispatch(_finalizeSetRequestedAt(requestedAt));
  }

  const payload = dispatch(
    validateCurrentCart(brandibbleRef, { requested_at: requestedAt })
  )
    .then(() => dispatch(_finalizeSetRequestedAt(requestedAt)))
    .catch(res => {
      if (res && res.errors) {
        if (
          get(res.errors[0], 'code') ===
          ErrorCodes.validateCart.locationIsClosed
        ) {
          // If location is closed
          // find next available daypart
          // and notify user, they will be ordering for then
          return dispatch(setModal(LOCATION_CLOSED_MODAL));
        }

        // If there are invalid items in the cart
        // show the invalid items modal
        if (
          get(res.errors[0], 'code') === ErrorCodes.validateCart.invalidItems
        ) {
          const [, ...invalidItems] = res.errors;
          return dispatch(
            setModal(INVALID_ITEMS_MODAL, { invalidItems, requestedAt })
          );
        }
      }
    });

  return dispatch({ type: VALIDATE_AND_ATTEMPT_SET_REQUESTED_AT, payload });
};

// The majority of this reorder flow
// was taken from the reOrder implementation in Roti
// The main differences to consider concern:
//
// 1. Checking if the necessary menu exists, and fetching it when it does
//  * This is due to the Tartine codebase not storing the current menu in its
//  own redux store, but rather getting it from menus object in brandibble redux
//
// 2. Ensuring that the orders requested_at is not set to 'asap' in the case
//    in the case the menu we are reordering for is a catering location
//  * This is due to the fact that Brandibble/Open Tender does not allow
//  the requested_at to be of string 'asap' in the case of catering orders
//
// With that in mind, Reorder Semantics:
//
// Reordering a collection of items will do one of two things:
//  1. if the current session's order has a location_id, we will attempt to order the
//     same set of items from the current order's location (i.e. ignore the location_id
//     of the original order)
//  2. otherwise, we will set our order's location to be that of the previous order
export const attemptReorder = (locationId, items, shouldClearCart = true) => (
  dispatch,
  getState
) => {
  const state = getState();
  const orderRef = get(state, 'brandibble.session.order.ref');
  const cart = get(state, 'brandibble.session.order.lineItemsData', []);
  const currentOrderLocationId = get(
    state,
    'brandibble.session.order.orderData.location_id'
  );

  const currentOrderLocationIdAsInt = parseInt(currentOrderLocationId, 10);
  const locationIdAsInt = parseInt(locationId, 10);

  /**
   * If the current order a has a locationId
   * and that location id is not the same
   * as the location id on the item the customer is attempting
   * to reorder, we display a modal, that requires the user to
   * updated their location id before proceeding to attempt to reorder
   */
  if (
    currentOrderLocationId &&
    currentOrderLocationIdAsInt !== locationIdAsInt
  ) {
    return dispatch(
      setModal(CONFIRMATION_MODAL, {
        confirmationActionType: SET_ORDER_LOCATION_ID,
        title: Language.t('setOrderLocationIdModal.title'),
        description: Language.t('setOrderLocationIdModal.description'),
        reorderItems: items,
        locationId
      })
    );
  }

  const payload = new Promise((resolve, reject) => {
    // we need to ensure that our order has a location_id (an order without a
    // location_id doesn't make sense), so if it doesn't have one, we have to
    // set it manually
    (get(state, 'brandibble.session.order.orderData.location_id')
      ? Promise.resolve()
      : dispatch(setOrderLocationId(orderRef, locationIdAsInt))
    )
      .then(() => {
        // We'll want to ensure we have the latest menu and location for that menu
        // in memory, which is handled by fetch and setup menu
        const nextState = getState();
        const orderLocationId = get(
          nextState,
          'brandibble.session.order.orderData.location_id'
        );
        return dispatch(fetchAndSetupMenu(orderLocationId));
      })
      .then(() => {
        // 3. if the location we are ordering from is a catering location
        // and the requested_at is for 'asap', we need to turn that into
        // the closes IS08601 equivalent, as catering location's requested_at
        // must be a valid date/time string (and unlike OLO, cannot be of string 'asap')
        const nextState = getState();
        const locations = allLocations(nextState);
        const orderData = get(nextState, 'brandibble.session.order.orderData');
        const orderRequestedAt = get(orderData, 'requested_at');
        const orderLocationId = get(orderData, 'location_id');
        const isCateringLocation = !!locations.find(
          location => location.brandibbleCateringLocationId === orderLocationId
        );
        const isAsap = orderRequestedAt === ASAP;

        if (isCateringLocation && isAsap) {
          const orderRef = get(nextState, 'brandibble.session.order.ref');
          const now = DateTime.local();
          const nowInUTC = now.setZone('utc');
          const nowFormatted = `${nowInUTC.toISO().split('.')[0]}Z`;

          return dispatch(setRequestedAt(orderRef, nowFormatted, false));
        }
        return Promise.resolve();
      })
      .then(() => {
        const nextState = getState();
        const brandibbleRef = get(nextState, 'brandibble.ref');
        const orderLocationId = get(
          nextState,
          'brandibble.session.order.orderData.location_id'
        );
        const menusById = get(nextState, 'brandibble.session.menus');

        const removals = shouldClearCart
          ? cart.map(item => dispatch(removeLineItem(orderRef, item)))
          : [];
        Promise.all(removals).then(() => {
          const defaultResponse = {
            isReorderable: false,
            itemsWereRemoved: shouldClearCart && !!removals.length
          };

          return _buildLineItemsForReorder(
            brandibbleRef,
            orderLocationId,
            menusById,
            items
          )
            .then(({ reorderItems, failedItems }) => {
              if (reorderItems.length) {
                const pushedLineItems = reorderItems.map(item =>
                  dispatch(pushLineItem(orderRef, item))
                );

                Promise.all(pushedLineItems).then(() => {
                  if (failedItems.length) {
                    resolve({
                      ...defaultResponse,
                      isReorderable: true,
                      itemsWereRemoved: true
                    });
                    return;
                  }

                  resolve({ ...defaultResponse, isReorderable: true });
                });
              } else {
                // In this case the order was NOT reorderable
                // so we reject resulting in an action status of REJECTED
                reject({ ...defaultResponse });
              }
            })
            .catch(() => {
              // If the buildLineItemsForReorder fails for some reason
              // the order is also NOT reorderable
              // so we reject resulting in an action status of REJECTED
              reject({ ...defaultResponse });
            });
        });
      });
  });

  return dispatch({
    type: ATTEMPT_REORDER,
    payload
  });
};

/**
 * PRIVATE
 */

const _finalizeSetRequestedAt = requestedAt => (dispatch, getState) => {
  const state = getState();
  const orderRef = get(state, 'brandibble.session.order.ref');
  const orderData = get(state, 'brandibble.session.order.orderData');

  const { wantsFuture, reason } = _determineIfWantsFuture(requestedAt);

  if (reason === Reason.isPast) {
    requestedAt = ASAP;
  }

  /**
   * In the case of a catering order, we need to ensure
   * an order's requested_at is never set to 'asap'.
   *
   * If we find that we are attempting to set the requested_at to 'asap'
   * and the current order is a catering order,
   * we instead request the location with the most up to date
   * data regarding orderable days and times (see include_time flag),
   * and instead set the requested_at to the first available time for
   * that location
   */

  if (requestedAt === ASAP && currentOrderMenuType(state) === CATERING) {
    const brandibbleRef = get(state, 'brandibble.ref');
    const locationId = get(orderData, 'location_id');
    const serviceType = get(orderData, 'service_type');
    const now = DateTime.local().toJSDate();

    return dispatch(
      fetchLocation(brandibbleRef, locationId, {
        service_type: serviceType,
        requested_at: now,
        include_times: true
      })
    ).then(res => {
      const firstAvailableOrderTime = get(
        res,
        `value.first_times.${serviceType}.utc`
      );

      const { wantsFuture } = _determineIfWantsFuture(firstAvailableOrderTime);

      return dispatch(
        setRequestedAt(orderRef, firstAvailableOrderTime, wantsFuture)
      );
    });
  }

  return dispatch(setRequestedAt(orderRef, requestedAt, wantsFuture));
};

const _buildLineItemsForReorder = (
  brandibbleRef,
  orderLocationId,
  menusById,
  items
) =>
  new Promise(resolve => {
    const keyForMenu = Object.keys(menusById).find(
      menuKey => menusById[menuKey].location_id === orderLocationId
    );
    const menu = menusById[keyForMenu];
    const failedItems = [];

    const reorderItems = items
      .map(item => {
        const orphan = brandibbleRef.orders.buildLineItemOrphan(
          item,
          get(menu, 'menu', [])
        );

        /**
         * If an orphan can't be built we consider it a
         * failed item, and push it into the
         * array of failed items
         */

        if (!orphan) {
          failedItems.push(item);
        }

        /**
         * Otherwise, If an orphan can be built
         * set the quantity and return as
         * a valid reorder item
         */
        orphan.quantity = item.quantity;
        return orphan;
      })
      .filter(mappedItem => !!mappedItem);

    return resolve({ reorderItems, failedItems });
  });

const Reason = {
  isAsap: 'is asap',
  isPast: 'is past',
  isFuture: 'is future',
  isNow: 'is now'
};

const _determineIfWantsFuture = requestedAt => {
  // If the requestedtAt is 'asap'
  // set wantsFuture to false
  if (requestedAt === ASAP) {
    return {
      wantsFuture: false,
      reason: Reason.isAsap
    };
  }

  const now = DateTime.local();
  const requestedAtAsLuxonDateTime = DateTime.fromISO(requestedAt);

  // If the requestedtAt is in the past (rare case)
  // set wantsFuture to false
  if (requestedAtAsLuxonDateTime < now) {
    return {
      wantsFuture: false,
      reason: Reason.isPast
    };
  }

  if (requestedAtAsLuxonDateTime === now) {
    return {
      wantsFuture: false,
      reason: Reason.isNow
    };
  }

  // If the requestedAt is in the future
  // set wantsFuture to true
  if (requestedAtAsLuxonDateTime > now) {
    return {
      wantsFuture: true,
      reason: Reason.isFuture
    };
  }
};
