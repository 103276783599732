export const ASAP = 'asap';
export const PICKUP = 'pickup';
export const CREDIT = 'credit';
export const PAST = 'past';
export const PRESENT = 'PRESENT';

export const ErrorCodes = {
  validateCart: {
    locationIsClosed: 'orders.validate.location_closed',
    invalidItems: 'orders.validate.invalid_items'
  },
  validateOrder: {
    duplicateEmail: 'orders.validate.duplicate_email'
  }
};
