import ContainerBase from 'lib/ContainerBase';
import ContentfulData from 'lib/ContentfulData';

import { connect } from 'react-redux';

import get from 'utils/get';
import withNavThemeContext from 'lib/withNavThemeContext';
import { DARK } from 'constants/ColorMaps';

class TermsContainer extends ContainerBase {
  view = import('views/TermsView');

  model = () => {
    const { activeLocale } = this.props;
    return ContentfulData.getEntries({
      content_type: 'termsOfService',
      include: 2,
      locale: activeLocale
    }).then(res => res.items[0]);
  };

  afterModel = () => {
    this.props.setNavTheme(DARK);
  };
}

const mapStateToProps = state => {
  return {
    activeLocale: get(state, 'locale.activeLocale')
  };
};

export default connect(mapStateToProps)(withNavThemeContext(TermsContainer));
