/**
 * Regex
 */
const emailRegex = RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
const phoneRegex = RegExp(
  '^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$'
);
const creditCardNumberRegex = RegExp(
  '^((4\\d{3})|(5[1-5]\\d{2})|(6011))-?\\d{4}-?\\d{4}-?\\d{4}|3[4,7]\\d{13}$'
);
const creditCardExpirationRegex = RegExp('^(0[1-9]|1[0-2])/?([0-9]{2})$');
const creditCardCVVRegex = RegExp('^[0-9]{3,4}$');
const creditCardZipCodeRegex = RegExp('^\\d{5}(?:[-\\s]\\d{4})?$');

/**
 * Validations for export
 */
const isValidName = name => !!name && name.length;
const isValidEmail = email => !!email && email.length && emailRegex.test(email);
const isValidPhoneNumber = phone =>
  !!phone && phone.length && phoneRegex.test(phone);
const isValidPassword = password =>
  !!password && password.length && password.length >= 8;
const isValidPasswordConfirmation = (password, confirmPassword) =>
  password === confirmPassword;
const isValidCreditCardNumber = cardNumber =>
  !!cardNumber && cardNumber.length && creditCardNumberRegex.test(cardNumber);
const isValidCreditCardExpiration = expiration =>
  !!expiration &&
  expiration.length &&
  creditCardExpirationRegex.test(expiration);
const isValidCreditCardCVV = cvv =>
  !!cvv && cvv.length && creditCardCVVRegex.test(cvv);
const isValidCreditCardZipCode = zip =>
  !!zip && zip.length && creditCardZipCodeRegex.test(zip);
const isValidMessage = message => !!message && message.length;

export {
  isValidName,
  isValidEmail,
  isValidPhoneNumber,
  isValidPassword,
  isValidPasswordConfirmation,
  isValidCreditCardNumber,
  isValidCreditCardExpiration,
  isValidCreditCardCVV,
  isValidCreditCardZipCode,
  isValidMessage
};
