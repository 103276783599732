import React from 'react';
import PropTypes from 'prop-types';

const Hamburger = ({ className, width, height, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
  >
    <desc>Menu</desc>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M26.5,30 L41.5,30 C41.7761424,30 42,30.2238576 42,30.5 C42,30.7761424 41.7761424,31 41.5,31 L26.5,31 C26.2238576,31 26,30.7761424 26,30.5 C26,30.2238576 26.2238576,30 26.5,30 Z M26.5,37 L41.5,37 C41.7761424,37 42,37.2238576 42,37.5 C42,37.7761424 41.7761424,38 41.5,38 L26.5,38 C26.2238576,38 26,37.7761424 26,37.5 C26,37.2238576 26.2238576,37 26.5,37 Z M26.5,44 L41.5,44 C41.7761424,44 42,44.2238576 42,44.5 C42,44.7761424 41.7761424,45 41.5,45 L26.5,45 C26.2238576,45 26,44.7761424 26,44.5 C26,44.2238576 26.2238576,44 26.5,44 Z"
      transform="translate(-26 -30)"
    />
  </svg>
);

Hamburger.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

Hamburger.defaultProps = {
  className: ``,
  width: 16,
  height: 16,
  fill: `#4A494A`
};

export default Hamburger;
