import React from 'react';

import loafImg from 'assets/loaf-transparent.svg';
import tartineLogo from 'assets/tartine-black.svg';

import get from 'utils/get';
import isI18nEnabled from 'utils/isI18nEnabled';
import isOrderingEnabled from 'utils/isOrderingEnabled';

import v from 'vudu';
import {
  breakpoints,
  classes as c,
  size,
  styles as s,
  format as f
} from 'styles';

import EmailSignup from 'components/EmailSignup';
import LocaleSelector from 'components/LocaleSelector';
import { SmartLink, Text } from 'components/base';

import Mocks from 'constants/Mocks';
import Language from 'constants/Language';

const { footer } = Mocks;

const classes = v({
  footer: {
    '@composes': [s.relative, s.p7],
    overflow: 'hidden',
    [breakpoints.md]: {
      '@composes': [s.py8, s.px11]
    }
  },
  footerInner: {
    '@composes': [s.siteMaxWidth]
  },
  linksRow: {
    '@composes': [s.row, s.col12]
  },
  navLinksCol: {
    '@composes': [s.col, s.col12, s.mdCol3, s.flex, s.flexColumn]
  },
  footerLink: {
    '@composes': [s.hoverOpacity],
    ':not(:last-of-type)': {
      '@composes': [s.mb3]
    }
  },
  contactLinksCol: {
    '@composes': [s.col, s.col12, s.mdCol3, s.center],
    [breakpoints.md]: {
      textAlign: `left`,
      paddingLeft: `${size(`gutter`)}`
    }
  },
  signatureRow: {
    '@composes': [s.row, s.col12, s.center, s.mt6],
    [breakpoints.md]: {
      '@composes': [s.absolute, s.mt0],
      bottom: '3.75rem',
      textAlign: `left`
    }
  },
  hideMdDown: {
    display: `none`,
    [breakpoints.md]: {
      display: `flex`
    }
  },
  copyrightRow: {
    '@composes': [s.relative, c.col12, c.row],
    height: `16.8125rem`,
    [breakpoints.md]: {
      '@composes': [s.flex, s.justifyEnd, s.alignCenter]
    }
  },
  copyrightContainer: {
    '@composes': [s.inlineFlex, s.flexColumn, s.alignStart, s.right],
    marginTop: `8.75rem`,
    [breakpoints.md]: {
      marginTop: 0,
      marginRight: `4.6875rem`
    }
  },
  copyright: {
    '@composes': [s.mb2],
    [breakpoints.md]: {
      '@composes': [s.mb3]
    }
  },
  footerLogo: {
    '@composes': [s.mb9],
    maxWidth: '7.5rem',
    [breakpoints.md]: {
      '@composes': [s.mb11],
      maxWidth: '100%'
    }
  },
  loaf: {
    '@composes': [s.absolute],
    pointerEvents: 'none',
    right: '-9.375rem',
    bottom: '-4.6875rem',
    maxWidth: '25rem',
    transform: `rotateZ(15deg)`,
    [breakpoints.md]: {
      maxWidth: '35rem',
      transform: `rotateZ(0)`
    }
  },
  LocaleSelector: {
    '@composes': [s.center, s.mb9],

    [breakpoints.md]: {
      '@composes': [s.textLeft]
    }
  }
});

const renderLink = (link, index) => {
  /*
    Hide links that should only appear if
    ordering is enabled
  */
  if (!isOrderingEnabled() && link.orderingEnabledOnly) {
    return null;
  }

  if (link.mailto) {
    return (
      <SmartLink
        className={classes.footerLink}
        mailto
        key={index}
        to={link.path}
      >
        <Text variant="footerNavItem">{link.label}</Text>
      </SmartLink>
    );
  }

  if (link.external) {
    return (
      <SmartLink
        className={classes.footerLink}
        external
        key={index}
        to={link.path}
      >
        <Text variant="footerNavItem">{link.label}</Text>
      </SmartLink>
    );
  }

  return (
    <SmartLink className={classes.footerLink} key={index} to={link.path}>
      <Text variant="footerNavItem">{link.label}</Text>
    </SmartLink>
  );
};

const Footer = props => (
  <footer className={classes.footer} role="contentinfo">
    <div className={classes.footerInner}>
      <div>
        <EmailSignup />
      </div>
      {isI18nEnabled() && (
        <div className={classes.LocaleSelector}>
          <LocaleSelector
            setActiveLocale={get(props, 'setActiveLocale', f => f)}
            locales={get(props, 'allLocales', [])}
            activeLocale={get(props, 'activeLocale')}
          />
        </div>
      )}
      <div className={classes.linksRow}>
        <div className={f(classes.navLinksCol, classes.hideMdDown)}>
          {footer.navLinks.map(renderLink)}
        </div>
        <div className={classes.contactLinksCol}>
          <div className={f(classes.hideMdDown, c.flexColumn, c.mb9)}>
            {footer.contactLinks.map(renderLink)}
          </div>
          <div className={c.col12}>
            <span>
              {renderLink(footer.socialLinks[0])} /{' '}
              {renderLink(footer.socialLinks[1])}
            </span>
          </div>
        </div>
      </div>
      <div className={classes.signatureRow}>
        <Text variant="signature" color="red">
          <span className={c.block}>{Language.t(`brand.tag.line1`)}</span>
          <span className={c.block}>{Language.t(`brand.tag.line2`)}</span>
        </Text>
      </div>
    </div>
    <div className={classes.copyrightRow}>
      <div className={classes.copyrightContainer}>
        <SmartLink className={c.hoverOpacity} to={footer.logo.path}>
          <img
            className={classes.footerLogo}
            alt="Tartine Logo"
            src={tartineLogo}
          />
        </SmartLink>
        <Text className={classes.copyright} variant="copyright">
          2021 Tartine
        </Text>
        <Text className={classes.copyright} variant="copyright">
          All rights reserved
        </Text>
        <SmartLink className={c.hoverOpacity} to={footer.privacy.path}>
          <Text className={classes.copyright} variant="copyright">
            Privacy Policy
          </Text>
        </SmartLink>
        <SmartLink className={c.hoverOpacity} to={footer.sitemap.path}>
          <Text className={classes.copyright} variant="copyright">
            Site Map
          </Text>
        </SmartLink>
        <SmartLink className={c.hoverOpacity} to={footer.terms.path}>
          <Text className={classes.copyright} variant="copyright">
            Terms of Service
          </Text>
        </SmartLink>
      </div>
    </div>
    <img className={classes.loaf} src={loafImg} alt="loaf mark" />
  </footer>
);

export default Footer;
