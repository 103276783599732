import PropTypes from 'prop-types';
import { IDLE, PENDING, FULFILLED, REJECTED } from 'constants/Status';

/*
  Generic
*/

const link = PropTypes.shape({
  label: PropTypes.string,
  path: PropTypes.string
});

const linkWithSublinks = PropTypes.shape({
  label: PropTypes.string,
  path: PropTypes.string,
  links: PropTypes.arrayOf(link)
});

/*
  Bradibble Redux
*/

const status = PropTypes.oneOf([IDLE, PENDING, FULFILLED, REJECTED]);

const brandibbleRef = PropTypes.shape({
  adapter: PropTypes.object,
  events: PropTypes.object,
  customers: PropTypes.object,
  locations: PropTypes.object,
  addresses: PropTypes.object,
  menus: PropTypes.object,
  orders: PropTypes.object,
  payments: PropTypes.object,
  allergens: PropTypes.object,
  favorites: PropTypes.object,
  ratings: PropTypes.object,
  images: PropTypes.object
});

const brandibbleOrderRef = PropTypes.shape({
  uuid: PropTypes.string,
  cart: PropTypes.object,
  locationId: PropTypes.number,
  serviceType: PropTypes.string,
  requestedAt: PropTypes.string,
  paymentType: PropTypes.string
});

const brandibbleMenuItemOptionGroup = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.number,
  max_options: PropTypes.number,
  min_option: PropTypes.number,
  slug: PropTypes.string
});

const brandibbleMenuItem = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  large_image_url: PropTypes.string,
  max_quantity: PropTypes.number,
  ingredients: PropTypes.string,
  price: PropTypes.string,
  option_groups: PropTypes.arrayOf(brandibbleMenuItemOptionGroup)
});

const brandibbleMenuCategory = PropTypes.shape({
  name: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.arrayOf(brandibbleMenuItem),
  slug: PropTypes.string
});

const brandibblePaymentMethod = PropTypes.shape({
  card_type: PropTypes.string,
  customer_card_id: PropTypes.number,
  is_default: PropTypes.bool,
  is_level_up: PropTypes.bool,
  last4: PropTypes.string
});

const brandibbleCustomerAttributes = PropTypes.shape({
  accepts_marketing: PropTypes.bool,
  company: PropTypes.string,
  customer_id: PropTypes.number,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  phone: PropTypes.string
});

/**
 * This is the orderData node
 * available via the currently (not yet completed)
 * order
 */
const brandibbleOrderData = PropTypes.shape({
  uuid: PropTypes.string,
  location_id: PropTypes.number,
  service_type: PropTypes.string,
  requested_at: PropTypes.string,
  payment_type: PropTypes.string,
  cart: PropTypes.array
});

/**
 * This is an order that has been submitted
 * and is retrieved via the customer/orders endpoint
 */
const brandibbleCustomerOrder = PropTypes.shape({
  order_id: PropTypes.number,
  address: PropTypes.object,
  phone: PropTypes.string,
  submitted_date: PropTypes.string,
  submitted_time: PropTypes.string,
  requested_date: PropTypes.string,
  requested_time: PropTypes.string,
  order_type: PropTypes.string,
  service_type: PropTypes.string,
  location_id: PropTypes.number,
  location_name: PropTypes.string,
  subtotal: PropTypes.number,
  tax: PropTypes.number,
  total: PropTypes.number,
  items: PropTypes.array,
  credit_card: PropTypes.object
});

const brandibbleDaypartServiceType = PropTypes.shape({
  hours: PropTypes.array,
  is_orderable: PropTypes.bool
});

const brandibbleDaypart = PropTypes.shape({
  daypart: PropTypes.string,
  ends_at: PropTypes.string,
  is_current: PropTypes.bool,
  starts_at: PropTypes.string,
  weekday: PropTypes.string,
  service_types: PropTypes.shape({
    pickup: brandibbleDaypartServiceType,
    delivery: brandibbleDaypartServiceType
  })
});

const brandibbleOptionGroup = PropTypes.shape({
  id: PropTypes.number,
  max_options: PropTypes.number,
  min_options: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
  option_items: PropTypes.array
});

const brandibbleOptionGroupMapping = PropTypes.shape({
  id: PropTypes.number,
  optionGroupData: brandibbleOptionGroup,
  name: PropTypes.string,
  canAddMoreToThisGroup: PropTypes.bool,
  requiresMoreInThisGroup: PropTypes.bool,
  currentlySelectedCount: PropTypes.number,
  totalAllowedCount: PropTypes.number,
  remainingIncludedOptions: PropTypes.number,
  additionalOptionsCount: PropTypes.number,
  totalEffectOnPrice: PropTypes.string
});

const brandibbleLineItem = PropTypes.shape({
  uuid: PropTypes.string,
  quantity: PropTypes.number,
  madeFor: PropTypes.string,
  instruction: PropTypes.string,
  isValid: PropTypes.bool,
  error: PropTypes.array,
  productData: PropTypes.object,
  optionGroupMappings: PropTypes.arrayOf(brandibbleOptionGroupMapping)
});

const brandibbleLineItemsData = PropTypes.arrayOf(brandibbleLineItem);

/*
  Contentful
*/

const contentfulModel = PropTypes.shape({
  fields: PropTypes.object,
  isError: PropTypes.bool,
  sys: PropTypes.object
});

const contentfulMedia = PropTypes.shape({
  sys: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string
  }),
  fields: PropTypes.shape({
    title: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string
    })
  })
});

const hoursData = PropTypes.shape({
  timezone: PropTypes.string,
  MON: PropTypes.string,
  TUES: PropTypes.string,
  WED: PropTypes.string,
  THURS: PropTypes.string,
  FRI: PropTypes.string,
  SAT: PropTypes.string,
  SUN: PropTypes.string
});

const pdfMenu = PropTypes.shape({
  fields: PropTypes.shape({
    title: PropTypes.string,
    details: PropTypes.string,
    pdf: contentfulMedia
  })
});

const locale = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
  default: PropTypes.bool,
  fallback: PropTypes.string
});

const upsellItem = PropTypes.shape({
  fields: PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    image: contentfulMedia
  })
});

/*
  UI
*/

const orderCard = PropTypes.shape({
  item: brandibbleMenuItem,
  lineItem: brandibbleLineItem,
  isConfigurable: PropTypes.bool,
  hasBasePrice: PropTypes.bool
});

/*
  Selectors
*/

const announcementBarData = PropTypes.shape({
  content: PropTypes.string,
  linkCta: PropTypes.string,
  linkUrl: PropTypes.string
});

const location = PropTypes.shape({
  id: PropTypes.string,
  city: PropTypes.string,
  listIndex: PropTypes.number
});

const selectedCityLocations = PropTypes.arrayOf(location);

const city = PropTypes.shape({
  label: PropTypes.string,
  acronym: PropTypes.string,
  path: PropTypes.string,
  locationsCount: PropTypes.number,
  locations: PropTypes.array
});

const citiesAndLocationsCount = PropTypes.arrayOf(city);

const locationsGeoJSON = PropTypes.shape({
  type: PropTypes.string,
  geometry: PropTypes.shape({
    type: PropTypes.string,
    coordinates: PropTypes.array,
    properties: PropTypes.object
  })
});

/*
  Libs
*/

const luxonDateTime = PropTypes.shape({
  toJSDate: PropTypes.func
});

export {
  link,
  linkWithSublinks,
  orderCard,
  contentfulModel,
  contentfulMedia,
  city,
  citiesAndLocationsCount,
  locationsGeoJSON,
  location,
  selectedCityLocations,
  hoursData,
  announcementBarData,
  pdfMenu,
  locale,
  upsellItem,
  status,
  brandibbleRef,
  brandibbleMenuCategory,
  brandibbleMenuItem,
  brandibbleMenuItemOptionGroup,
  brandibbleLineItem,
  brandibbleLineItemsData,
  brandibblePaymentMethod,
  brandibbleCustomerAttributes,
  brandibbleOrderData,
  brandibbleOrderRef,
  brandibbleCustomerOrder,
  brandibbleDaypartServiceType,
  brandibbleDaypart,
  brandibbleOptionGroup,
  brandibbleOptionGroupMapping,
  luxonDateTime
};
