import get from 'utils/get';

import {
  addLineItem,
  removeLineItem,
  addOptionToLineItem,
  removeOptionFromLineItem
} from 'brandibble-redux';
import { setModal, resetModal } from 'state/actions/ui/modalActions';

import { PRESENT } from 'constants/Brandibble';
import { LINE_ITEM_CONFIGURATION_MODAL } from 'constants/ModalVariants';

export const TOGGLE_ADD_OPTION_TO_LINE_ITEM = 'TOGGLE_ADD_OPTION_TO_LINE_ITEM';

export const toggleAddOptionToLineItem = (
  orderRef,
  lineItem,
  optionGroupMapping,
  optionItem
) => dispatch => {
  const optionGroupData = get(optionGroupMapping, 'optionGroupData');
  const optionItemPresentInOptionGroupMapping = get(
    optionGroupMapping,
    'optionItems',
    []
  ).find(optionItem => get(optionItem, 'presence') === PRESENT);

  const payload = async () => {
    try {
      /**
       * If an option item is already PRESENT in the
       * optionGroup, we remove it before adding the new one
       */
      if (!!optionItemPresentInOptionGroupMapping) {
        await dispatch(
          removeOptionFromLineItem(
            orderRef,
            lineItem,
            get(optionItemPresentInOptionGroupMapping, 'optionItemData')
          )
        );
      }

      await dispatch(
        addOptionToLineItem(orderRef, lineItem, optionGroupData, optionItem)
      );
    } catch (err) {
      console.error(err);
    }
  };

  return dispatch({
    type: TOGGLE_ADD_OPTION_TO_LINE_ITEM,
    payload: payload()
  });
};

export const addLineItemAndOpenConfigurationModal = (
  orderRef,
  item
) => dispatch =>
  dispatch(addLineItem(orderRef, item)).then(res => {
    const lineItem = get(res, 'value.lineItem');
    return dispatch(setModal(LINE_ITEM_CONFIGURATION_MODAL, { lineItem }));
  });

export const removeLineItemAndCloseConfigurationModal = lineItem => (
  dispatch,
  getState
) => {
  const state = getState();
  const orderRef = get(state, 'brandibble.session.order.ref');

  return dispatch(removeLineItem(orderRef, lineItem)).then(() =>
    dispatch(resetModal())
  );
};

export const removeInvalidLineItems = () => (dispatch, getState) => {
  const state = getState();
  const orderRef = get(state, 'brandibble.session.order.ref');
  const lineItemsData = get(
    state,
    'brandibble.session.order.lineItemsData',
    []
  );

  if (!lineItemsData.length) {
    return Promise.resolve();
  }

  const lineItemsToRemove = lineItemsData.reduce(
    (itemsToRemove, currentItem) => {
      if (!get(currentItem, 'isValid')) {
        itemsToRemove.push(dispatch(removeLineItem(orderRef, currentItem)));
      }
      return itemsToRemove;
    },
    []
  );

  if (!lineItemsToRemove.length) {
    Promise.resolve();
  }

  return Promise.all(lineItemsToRemove);
};
