export const SET_LOCATION_TITLE = 'SET_LOCATION_TITLE';
export const UNSET_LOCATION_TITLE = 'UNSET_LOCATION_TITLE';

export const setLocationTitle = locationTitle => ({
  type: SET_LOCATION_TITLE,
  payload: locationTitle
});

export const unsetLocationTitle = () => ({
  type: UNSET_LOCATION_TITLE
});
