import { createSelector } from 'reselect';
import get from 'utils/get';

import { CATERING } from 'constants/MenuTypes';

import { allLocations, currentOrderMenuType } from 'state/selectors';

export default createSelector(
  state => get(state, 'brandibble.session.order.orderData', []),
  state => allLocations(state),
  state => currentOrderMenuType(state),
  (orderData, allLocations, menuType) => {
    const currentOrderLocationId = get(orderData, 'location_id');

    if (!currentOrderLocationId) return false;

    if (menuType === CATERING) {
      const cateringLocation = allLocations.find(
        location =>
          location.brandibbleCateringLocationId === currentOrderLocationId
      );

      if (!cateringLocation) return null;

      return `${cateringLocation.path}/catering`;
    }

    const orderLocation = allLocations.find(
      location => location.brandibbleOrderLocationId === currentOrderLocationId
    );

    if (!orderLocation) return null;

    return `${orderLocation.path}/order`;
  }
);
