const ratios = [
  [1, 1], // Square 1:1
  [2, 1], // Landscape 2:1
  [1, 2] // Portrait 1:2
];

export const ratio = name => {
  if (name === 'square') return ratios[0];
  if (name === 'landscape') return ratios[1];
  if (name === 'portrait') return ratios[2];
  return null;
};

const aspectRatio = (width, height, position = 'relative') => styles => {
  const before = {
    display: 'block',
    content: '""',
    width: '100%',
    paddingTop: `${(height / width) * 100}%`
  };
  return Object.assign(styles, {
    position,
    ':before': before,
    '.content': {
      backgroundSize: 'cover',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  });
};

export default aspectRatio;
