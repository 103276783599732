import React from 'react';
import PropTypes from 'prop-types';

import { classes as c, format as f } from 'styles';
import SmartLink from './SmartLink';

const ButtonLink = ({
  variant,
  text,
  to,
  className,
  mailto,
  disable,
  tel,
  external
}) => (
  <SmartLink
    className={f(className, c[variant])}
    mailto={mailto}
    disable={disable}
    tel={tel}
    to={to}
    external={external}
  >
    {text}
  </SmartLink>
);

ButtonLink.defaultProps = {
  className: ``,
  disable: false,
  tel: false,
  mailto: false,
  external: false,
  variant: 'goldBrick'
};

ButtonLink.propTypes = {
  className: PropTypes.string,
  disable: PropTypes.bool,
  tel: PropTypes.bool,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  mailto: PropTypes.bool,
  external: PropTypes.bool,
  variant: PropTypes.string
};

export default ButtonLink;
