import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

import get from 'utils/get';

/*
  Accepted children of the Accordion Component
*/
import AccordionItemHeader from 'components/base/AccordionItemHeader';
import AccordionItemPanel from 'components/base/AccordionItemPanel';

const renderChildWithProps = (child, label, isOpen, toggleOpen) => {
  switch (get(child, 'type')) {
    case AccordionItemHeader:
      return cloneElement(child, {
        label,
        toggleOpen,
        isOpen
      });
    case AccordionItemPanel:
      return cloneElement(child, {
        label,
        isOpen
      });
    default:
      return null;
  }
};

const AccordionItem = ({ label, children, className, isOpen, toggleOpen }) => {
  if (!children) return null;

  return (
    <div className={className}>
      {Children.map(children, child =>
        renderChildWithProps(child, label, isOpen, toggleOpen)
      )}
    </div>
  );
};

AccordionItem.defaultProps = {
  className: ''
};

AccordionItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string /* React.cloneElement Prop */,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]) /* React.cloneElement Prop */,
  isOpen: PropTypes.bool /* React.cloneElement Prop */,
  toggleOpen: PropTypes.func /* React.cloneElement Prop */
};

export default AccordionItem;
