import Vars from 'constants/Vars';

const { headerHeight } = Vars;

const mixins = {
  headerSpacing: {
    paddingTop: `${headerHeight}px`
  }
};

export default mixins;
