import {
  FETCH_ALL_LOCALES,
  SET_ACTIVE_LOCALE
} from 'state/actions/localeActions';

import getDefaultLanguage from 'utils/getDefaultLanguage';

const initialState = {
  allLocales: [],
  activeLocale: getDefaultLanguage()
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${SET_ACTIVE_LOCALE}_PENDING`:
    case `${FETCH_ALL_LOCALES}_PENDING`:
      return { ...state };
    case `${SET_ACTIVE_LOCALE}_FULFILLED`:
      return { ...state, activeLocale: payload };
    case `${FETCH_ALL_LOCALES}_FULFILLED`:
      return { ...state, allLocales: payload };
    case `${SET_ACTIVE_LOCALE}_REJECTED`:
    case `${FETCH_ALL_LOCALES}_REJECTED`:
    default:
      return state;
  }
};
