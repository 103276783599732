import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { brandibbleMiddleware } from 'brandibble-redux';
import createSagaMiddleware from 'redux-saga';
import reducers from 'state/reducers';
import sagas from 'state/sagas';
import unhandledPromisesAreOk from 'state/middlewares/unhandledPromisesAreOk';

export const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  unhandledPromisesAreOk,
  ...brandibbleMiddleware,
  sagaMiddleware,
  routerMiddleware(history)
];

const store = createStore(
  connectRouter(history)(reducers),
  composeEnhancers(applyMiddleware(...middleware))
);

// Initialize sagas
sagaMiddleware.run(sagas);

export default store;
