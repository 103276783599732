import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ className, width, height, stroke }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
  >
    <g fill="none" fillRule="evenodd" transform="translate(-333 -117)">
      <polyline
        stroke={stroke}
        strokeWidth="2"
        points="11.455 -4 11.455 5.333 2.545 5.333"
        transform="scale(1 -1) rotate(45 326.095 340.84)"
      />
    </g>
  </svg>
);

Arrow.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  stroke: PropTypes.string
};

Arrow.defaultProps = {
  className: ``,
  width: 16,
  height: 12,
  stroke: `#E19633`
};

export default Arrow;
