import { toast } from 'react-toastify';
import v from 'vudu';
import { styles as s, classes as c, colors, format as f } from 'styles';

export const WARNING = 'WARNING';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';

const classes = v({
  default: {
    backgroundColor: colors.white,
    border: `solid 1px ${colors.black}`
  },
  success: {
    backgroundColor: colors.white,
    border: `solid 1px ${colors.blue}`
  },
  warning: {
    backgroundColor: colors.white,
    border: `solid 1px ${colors.gold}`
  },
  error: {
    backgroundColor: colors.white,
    border: `solid 1px ${colors.red}`
  },
  bodyClasses: {
    '@composes': [s.paragraph, s.p2],
    whitespace: 'nowrap'
  }
});

const triggerToast = (text, type) => {
  switch (type) {
    case SUCCESS:
      return toast(text, {
        className: classes.success,
        bodyClassName: `${f(classes.bodyClasses, c.blue)}`
      });
    case WARNING:
      return toast(text, {
        className: classes.warning,
        bodyClassName: `${f(classes.bodyClasses, c.gold)}`
      });
    case ERROR:
      return toast(text, {
        className: classes.error,
        bodyClassName: `${f(classes.bodyClasses, c.red)}`
      });
    default:
      return toast(text, {
        className: classes.default,
        bodyClassName: `${f(classes.bodyClasses, c.black)}`
      });
  }
};

export default triggerToast;
