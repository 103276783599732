import { createSelector } from 'reselect';

import { allLocations } from 'state/selectors';

import get from 'utils/get';

export default createSelector(
  state => allLocations(state),
  allLocations => ({
    type: 'FeatureCollection',
    features: allLocations.map(location => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          get(location, 'geolocation.lon', 0),
          get(location, 'geolocation.lat', 0)
        ]
      },
      properties: {
        id: get(location, 'id', null),
        listIndex: get(location, 'listIndex'),
        city: get(location, 'city'),
        path: get(location, 'path')
      }
    }))
  })
);
