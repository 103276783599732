import { all, put, select } from 'redux-saga/effects';
import { fetchMenu, fetchLocation, Status } from 'brandibble-redux';
import { timezoneForCurrentLocation } from 'state/selectors';
import { ASAP } from 'constants/Brandibble';
import get from 'utils/get';
import nowInTimezone from 'utils/nowInTimezone';

const { PENDING, FULFILLED, REJECTED } = Status;

export const ON_SET_REQUESTED_AT_SAGA = 'ON_SET_REQUESTED_AT_SAGA';

function* onSetRequestAtSaga() {
  const brandibble = yield select(state => get(state, 'brandibble'));
  const timezoneForCurrentOrder = yield select(state =>
    timezoneForCurrentLocation(state)
  );

  const brandibbleRef = get(brandibble, 'ref');
  const currentOrderData = get(brandibble, 'session.order.orderData');

  const locationIdForCurrentOrder = get(currentOrderData, 'location_id');
  const serviceTypeForCurrentOrder = get(currentOrderData, 'service_type');
  const requestedAtForCurrentOrder = get(currentOrderData, 'requested_at');

  const requestedAtIsAsap = requestedAtForCurrentOrder === ASAP;
  const requestedAtAsValidISO8601Format = requestedAtIsAsap
    ? nowInTimezone(timezoneForCurrentOrder)
    : get(currentOrderData, 'requested_at');

  const hasMenuForCurrentOrder = !!get(
    brandibble,
    `session.menus.${locationIdForCurrentOrder}_${serviceTypeForCurrentOrder}_${requestedAtForCurrentOrder}`
  );

  if (!hasMenuForCurrentOrder) {
    yield put({ type: `${ON_SET_REQUESTED_AT_SAGA}_${PENDING}` });
    yield all([
      put(
        fetchMenu(
          brandibbleRef,
          {
            locationId: locationIdForCurrentOrder,
            serviceType: serviceTypeForCurrentOrder,
            requestedAt: requestedAtAsValidISO8601Format
          },
          {
            isAsap: requestedAtIsAsap
          }
        )
      ),
      put(
        fetchLocation(brandibbleRef, locationIdForCurrentOrder, {
          service_type: serviceTypeForCurrentOrder,
          requested_at: requestedAtAsValidISO8601Format,
          include_times: true
        })
      )
    ]);
  }
}

/**
 * If all dispatched actions have become FULFILLED
 * and ON_SET_REQUESTED_AT_SAGA is in a PENDING state
 * we can assume ON_SET_REQUESTED_AT_SAGA_FULFILLED
 */

function* handleOnSetRequestedAtSagaFulfilled() {
  const onSetRequestAtSagaPending = yield select(
    state => state.status.onSetRequestedAtSaga === PENDING
  );
  const fetchMenuFulfilled = yield select(
    state => state.brandibble.status.fetchMenu === FULFILLED
  );
  const fetchLocationFulfilled = yield select(
    state => state.brandibble.status.fetchLocation === FULFILLED
  );

  if (
    onSetRequestAtSagaPending &&
    fetchMenuFulfilled &&
    fetchLocationFulfilled
  ) {
    yield put({ type: `${ON_SET_REQUESTED_AT_SAGA}_${FULFILLED}` });
  }
}

/**
 * If any dispatched actions become REJECTED
 * and ON_SET_REQUESTED_AT_SAGA is in a PENDING state
 * we can assume ON_SET_REQUESTED_AT_SAGA_REJECTED
 */

function* handleOnSetRequestedAtSagaRejected() {
  const onSetRequestAtSagaPending = yield select(
    state => state.status.onSetRequestedAtSaga === PENDING
  );
  const fetchMenuRejected = yield select(
    state => state.brandibble.status.fetchMenu === REJECTED
  );
  const fetchLocationRejected = yield select(
    state => state.brandibble.status.fetchLocation === REJECTED
  );

  if (
    onSetRequestAtSagaPending &&
    (fetchMenuRejected || fetchLocationRejected)
  ) {
    yield put({ type: `${ON_SET_REQUESTED_AT_SAGA}_${REJECTED}` });
  }
}

export {
  handleOnSetRequestedAtSagaFulfilled,
  handleOnSetRequestedAtSagaRejected
};
export default onSetRequestAtSaga;
