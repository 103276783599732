const colors = {
  transparent: `transparent`,
  white: `#FFFFFF`,
  offwhite: `#FDF9F5`,
  lightGray: `#F6F4F4`,
  gray: `#D8D8D8`,
  midGray: `#C8C4CA`,
  darkGray: `#4A494A`,
  darkerGray: `#413941`,
  black: `#231F20`,
  tan: `#F1E9DA`,
  brown: `#C0AF9A`,
  blue: `#04475B`,
  gold: `#c87e1e`,
  darkGold: `#B97925`,
  goldFaded: `rgba(225, 150, 51, 0.04)`,
  red: `#973A31`,
  shadow: `rgba(147,147,147,0.12)`,
  light: `#FFFFFF`,
  dark: `#4A494A`
};

const themes = {
  light: colors.light,
  dark: colors.dark
};

export { themes };
export default colors;
