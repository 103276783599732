import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Carousel from 'nuka-carousel';
import Arrow from 'components/svg/Arrow';

import v from 'vudu';
import { breakpoints, styles as s } from 'styles';

const classes = v({
  rightArrow: {
    display: 'none',
    [breakpoints.md]: {
      '@composes': [s.pointer],
      display: 'inline-block',
      transform: `rotate(90deg)`
    }
  },
  leftArrow: {
    display: 'none',
    [breakpoints.md]: {
      '@composes': [s.pointer],
      display: 'inline-block',
      transform: `rotate(-90deg) translateY(-22px)`
    }
  }
});

class Slider extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.equalizeHeights);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.equalizeHeights);
  }

  equalizeHeights = () => {
    const slideList = this.sliderRef.frame.querySelector('.slider-list');
    const maxHeight = Array.from(slideList.childNodes).reduce(
      (maxHeight, node) => {
        return Math.max(
          node.firstChild.getBoundingClientRect().height,
          maxHeight
        );
      },
      0
    );

    slideList.style.height = `${maxHeight}px`;
  };

  renderRightArrow = ({
    currentSlide,
    slideCount,
    slidesToShow,
    nextSlide
  }) => {
    if (currentSlide + slidesToShow === slideCount) return null;

    return (
      <button
        type="button"
        className={classes.rightArrow}
        onClick={nextSlide}
        aria-label="Next Slide"
      >
        <Arrow />
      </button>
    );
  };

  renderLeftArrow = ({ currentSlide, previousSlide }) => {
    if (currentSlide === 0) return null;

    return (
      <button
        type="button"
        className={classes.leftArrow}
        onClick={previousSlide}
        aria-label="Previous Slide"
      >
        <Arrow />
      </button>
    );
  };

  render() {
    const { children, slidesToShow } = this.props;

    return (
      <Carousel
        ref={ref => (this.sliderRef = ref)}
        easing="easeQuadOut"
        edgeEasing="easeQuadOut"
        renderCenterRightControls={this.renderRightArrow}
        renderCenterLeftControls={this.renderLeftArrow}
        renderBottomCenterControls={null}
        slidesToShow={slidesToShow}
        swiping
      >
        {React.Children.map(children, child => child)}
      </Carousel>
    );
  }
}

Slider.defaultProps = {
  slidesToShow: 2
};

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  slidesToShow: PropTypes.number
};

export default Slider;
