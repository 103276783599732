import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'utils/get';

import Language from 'constants/Language';
import { InputTypes } from 'constants/Forms';

import TextArea from 'components/forms/TextArea';

import v from 'vudu';
import { styles as s } from 'styles';

const { NOTES_FOR_STORE } = InputTypes;

const classes = v({
  notesForStore: {
    '@composes': [s.label, s.bgLightGray, s.px4],
    '::placeholder': {
      '@composes': [s.black]
    }
  }
});

class AddNotesForStoreForm extends PureComponent {
  constructor(props) {
    super(...arguments);

    this.state = {
      values: {
        [NOTES_FOR_STORE]: get(props, 'notesForStore')
      }
    };
  }

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      values: { ...prevState.values, [target.name]: target.value }
    }));
  };

  handleBlur = () => {
    const { values } = this.state;

    if (values[NOTES_FOR_STORE] !== get(this, 'props.notesForStore')) {
      this.handleTriggerSetNotesForStore(values[NOTES_FOR_STORE]);
    }
  };

  handleTriggerSetNotesForStore = notesForStore => {
    const orderRef = get(this, 'props.orderRef');
    const setMiscOptions = get(this, 'props.setMiscOptions', f => f);
    return setMiscOptions(orderRef, { [NOTES_FOR_STORE]: notesForStore });
  };

  render() {
    const { values } = this.state;
    return (
      <form>
        <TextArea
          className={classes.notesForStore}
          rows={3}
          name={NOTES_FOR_STORE}
          placeholder={Language.t('cateringModal.instructionsPlaceholder')}
          value={values[NOTES_FOR_STORE]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
      </form>
    );
  }
}

AddNotesForStoreForm.defaultProps = {
  setMiscOptions: f => f,
  notesForStore: ''
};

AddNotesForStoreForm.propTypes = {
  setMiscOptions: PropTypes.func,
  notesForStore: PropTypes.string
};

export default AddNotesForStoreForm;
