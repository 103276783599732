import React from 'react';
import PropTypes from 'prop-types';

const AccordionItemPanel = ({ children, isOpen }) => {
  if (!isOpen) return null;

  return React.Children.map(children, child => React.cloneElement(child));
};

AccordionItemPanel.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool /* React.cloneElement supplied Prop */
};

export default AccordionItemPanel;
