import { createSelector } from 'reselect';
import get from 'utils/get';
import getMenuItemsForLineItems from 'utils/getMenuItemsForLineItems';
import _isEmpty from 'lodash/isEmpty';

import { menuForCurrentOrder } from 'state/selectors';

export default createSelector(
  state => menuForCurrentOrder(state),
  state => get(state, 'brandibble.session.order.lineItemsData'),
  (menuForCurrentOrder, lineItemsData) => {
    if (_isEmpty(menuForCurrentOrder) || !get(lineItemsData, 'length', [])) {
      return [];
    }

    return getMenuItemsForLineItems(
      get(menuForCurrentOrder, 'menu', []),
      lineItemsData
    );
  }
);
