import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'utils/get';
import triggerToast, { SUCCESS, ERROR } from 'utils/triggerToast';
import Language from 'constants/Language';
import Vars from 'constants/Vars';
import getInvalidItemsInCart from 'utils/getInvalidItemsInCart';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Status } from 'brandibble-redux';
import { removeInvalidItemsAndSetRequestedAt } from 'state/actions/brandibble/orderActions';
import { removeInvalidItems } from 'state/actions/brandibble/menuActions';
import { resetModal } from 'state/actions/ui/modalActions';
import { currentOrderMenuPath } from 'state/selectors';

import { Text, Button } from 'components/base';

import v from 'vudu';
import {
  breakpoints,
  styles as s,
  classes as c,
  format as f,
  size
} from 'styles';

const { IDLE, PENDING, FULFILLED, REJECTED } = Status;
const { confirmationModalMaxWidth, confirmationModalMinHeight } = Vars;

const classes = v({
  invalidItemsModal: {
    '@composes': [s.flex, s.flexColumn],
    maxWidth: `${confirmationModalMaxWidth}px`,
    minHeight: `${confirmationModalMinHeight}px`
  },
  itemsContainer: {
    '@composes': [s.relative, s.my4]
  },
  itemsListScrollContainer: {
    '@composes': [s.py4],
    maxHeight: '150px',
    overflow: 'auto'
  },
  overflowGradient: {
    '@composes': [s.overflowGradientWhite, s.absolute, s.col12, s.b0],
    height: `${size(7)}`
  },
  modalButtonsContainer: {
    '@composes': [s.flex, s.alignCenter, s.spaceBetween],

    [breakpoints.md]: {
      '@composes': [s.spaceAround]
    }
  }
});

class InvalidItemsModal extends PureComponent {
  componentDidUpdate(prevProps) {
    const resetModal = get(this, 'props.actions.resetModal', f => f);
    if (
      (get(prevProps, 'removeInvalidItemsAndSetRequestedAtStatus') ===
        PENDING &&
        get(this, 'props.removeInvalidItemsAndSetRequestedAtStatus') ===
          FULFILLED) ||
      (get(prevProps, 'removeInvalidItemsStatus') === PENDING &&
        get(this, 'props.removeInvalidItemsStatus') === FULFILLED)
    ) {
      triggerToast(
        Language.t('successMessages.toast.invalidItemsModal'),
        SUCCESS
      );
      resetModal();
    }

    if (
      (get(prevProps, 'removeInvalidItemsAndSetRequestedAtStatus') ===
        PENDING &&
        get(this, 'props.removeInvalidItemsAndSetRequestedAtStatus') ===
          REJECTED) ||
      (get(prevProps, 'removeInvalidItemsStatus') === PENDING &&
        get(this, 'props.removeInvalidItemsStatus') === REJECTED)
    ) {
      triggerToast(Language.t('errorMessages.toast.invalidItemsModal'), ERROR);
      resetModal();
    }
  }

  renderInvalidLineItemsList = invalidItemsInCart => {
    return invalidItemsInCart.map(invalidItem => (
      <Text className={c.mb2} variant="paragraph">
        {`${invalidItem.quantity} x ${invalidItem.product.name}`}
      </Text>
    ));
  };

  handleConfirmation = invalidItemsInCart => {
    const actions = get(this, 'props.actions');
    const data = get(this, 'props.data', {});

    const requestedAt = get(data, 'requestedAt');
    const locationId = get(data, 'locationId');

    // If a requested at was provided to the invalid items modals
    // we can infer that the user is attempting to set the requested at
    // and fire the correct action, in this case removeInvalidItemsAndSetRequestedAt
    if (!!requestedAt) {
      const removeInvalidItemsAndSetRequestedAt = get(
        actions,
        'removeInvalidItemsAndSetRequestedAt',
        f => f
      );

      return removeInvalidItemsAndSetRequestedAt(
        invalidItemsInCart,
        requestedAt
      );
    }

    // If a location id was provided to the invalid items modals
    // we can infer that the user is attempting to set fetch and setup a menu
    // for a different location, in this case we remove any invalid items
    if (!!locationId) {
      const removeInvalidItems = get(actions, 'removeInvalidItems', f => f);
      return removeInvalidItems(invalidItemsInCart);
    }
  };

  handleCancel = () => {
    const locationId = get(this, 'props.data.locationId');
    const resetModal = get(this, 'props.actions.resetModal');

    // If a location id is present,
    // we can assume the invalid items modal
    // is due to a newly requested location
    // not supporting one (or more) items present in the cart.
    // In this case, we redirect the user to the current order's location
    // before resetting the modal
    if (!!locationId) {
      const redirectUrl = get(this, 'props.currentOrderMenuPath');
      this.props.history.push(redirectUrl);
    }
    resetModal();
  };

  render() {
    const invalidItemsData = get(this, 'props.data.invalidItems', []);
    const cart = get(this, 'props.orderRef.cart', []);
    const invalidItemsInCart = getInvalidItemsInCart(invalidItemsData, cart);
    const isSubmitting =
      get(this, `props.removeInvalidItemsAndSetRequestedAtStatus`) ===
        PENDING || get(this, 'props.removeInvalidItemsStatus') === PENDING;

    return (
      <div className={classes.confirmationModal}>
        <div className={c.grow1}>
          <Text className={f(c.center, c.mb7)} variant="modalTitle">
            {Language.t('invalidItemsModal.title')}
          </Text>
          <Text variant="paragraph">
            {Language.t('invalidItemsModal.description')}
          </Text>
          <div className={classes.itemsContainer}>
            <div className={classes.itemsListScrollContainer}>
              {this.renderInvalidLineItemsList(invalidItemsInCart)}
              <div className={classes.overflowGradient} />
            </div>
          </div>
        </div>
        <div className={classes.modalButtonsContainer}>
          <Button
            onClick={this.handleCancel}
            variant="basicText"
            text={Language.t('confirmationModal.cancel')}
            type="button"
          />
          <Button
            onClick={() => this.handleConfirmation(invalidItemsInCart)}
            disabledClassName={c.basicTextDisabled}
            showLoading={isSubmitting}
            type="submit"
            variant="basicText"
            text={Language.t('confirmationModal.confirm')}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brandibbleRef: get(state, 'brandibble.ref'),
  orderData: get(state, 'brandibble.session.order.orderData', {}),
  orderRef: get(state, 'brandibble.session.order.ref', {}),
  currentOrderMenuPath: currentOrderMenuPath(state),
  removeInvalidItemsAndSetRequestedAtStatus: get(
    state,
    'status.removeInvalidItemsAndSetRequestedAt',
    IDLE
  ),
  removeInvalidItemsStatus: get(state, 'status.removeInvalidItems', IDLE)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetModal,
      removeInvalidItemsAndSetRequestedAt,
      removeInvalidItems
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvalidItemsModal));
