// e.g. 2020-02-25
const DATE_HYPHENATED = 'yyyy-MM-dd';
// e.g. Aug 6, 2014
const DATE_ABBREVIATED = 'LLL d, yyyy';
// e.g. 12:00 AM
const TIME_WITH_MERIDIEM = 'h:mm a';
// e.g. 8/6/2020 1:00 PM
const DATE_AND_TIME = 'M/d/yyyy h:mm a';
const DATE_AND_TIME_IN_WORDS = 'ff';

export {
  DATE_HYPHENATED,
  DATE_ABBREVIATED,
  TIME_WITH_MERIDIEM,
  DATE_AND_TIME,
  DATE_AND_TIME_IN_WORDS
};
