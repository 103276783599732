import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { contentfulMedia } from 'constants/PropTypes';
import get from 'utils/get';

import Meta from 'components/Meta';

const PageMeta = ({ share, children }) => (
  <Fragment>
    <Meta
      title={get(share, 'fields.title')}
      description={get(share, 'fields.description')}
      image={get(share, 'fields.image.fields.file.url')}
      url={get(share, 'share.fields.url')}
    />
    {children}
  </Fragment>
);

PageMeta.defaultProps = {
  share: {},
  children: null
};

PageMeta.propTypes = {
  children: PropTypes.node,
  share: PropTypes.shape({
    fields: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: contentfulMedia,
      url: PropTypes.string
    })
  })
};

export default PageMeta;
