import ContainerBase from 'lib/ContainerBase';
import get from 'utils/get';
import dehyphenate from 'utils/dehyphenate';
import getLocationDataBySlug from 'utils/getLocationDataBySlug';
import { ASAP } from 'constants/Brandibble';
import nowInTimezone from 'utils/nowInTimezone';

import withDeviceWidthContext from 'lib/withDeviceWidthContext';
import withNavThemeContext from 'lib/withNavThemeContext';
import { LIGHT } from 'constants/ColorMaps';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setOrderLocationId, fetchMenu, Status } from 'brandibble-redux';
import {
  fetchAndSetupMenu,
  validateAndRemoveInvalidItems,
  FETCH_AND_SETUP_MENU
} from 'state/actions/brandibble/menuActions';
import {
  setLocationTitle,
  unsetLocationTitle
} from 'state/actions/ui/headerActions';
import {
  allLocations,
  menuStatus,
  timezoneForCurrentLocation
} from 'state/selectors';
import { setModal } from 'state/actions/ui/modalActions';

const { PENDING, FULFILLED } = Status;

class CitiesShowLocationsShowCateringMenuContainer extends ContainerBase {
  view = import('views/CitiesShowLocationsShowCateringMenuView');

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (
      prevProps.removeInvalidItemsStatus === PENDING &&
      this.props.removeInvalidItemsStatus === FULFILLED
    ) {
      const setOrderLocationId = get(this, 'props.actions.setOrderLocationId');
      const orderRef = get(this, 'props.orderRef');
      const allLocations = get(this, 'props.allLocations', []);
      const locationSlug = get(this, 'props.match.params.locationName');
      const locationData = getLocationDataBySlug(allLocations, locationSlug);
      const requestedMenuLocationId = get(
        locationData,
        'brandibbleCateringLocationId'
      );
      setOrderLocationId(orderRef, requestedMenuLocationId);
    }

    if (
      prevProps.setRequestedAtStatus === PENDING &&
      this.props.setRequestedAtStatus === FULFILLED &&
      get(prevProps, 'orderData.requested_at') !==
        get(this, 'props.orderData.requested_at')
    ) {
      this.reloadModel();
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    return actions.unsetLocationTitle();
  }

  model = () => {
    const allLocations = get(this, 'props.allLocations', []);
    const locationSlug = get(this, 'props.match.params.locationName');
    const orderData = get(this, 'props.orderData');
    const orderRef = get(this, 'props.orderRef');

    const fetchAndSetupMenu = get(this, 'props.actions.fetchAndSetupMenu');
    const validateAndRemoveInvalidItems = get(
      this,
      'props.actions.validateAndRemoveInvalidItems'
    );
    const setOrderLocationId = get(this, 'props.actions.setOrderLocationId');

    const locationData = getLocationDataBySlug(allLocations, locationSlug);
    const requestedMenuLocationId = get(
      locationData,
      'brandibbleCateringLocationId'
    );
    const currentOrderId = get(orderData, 'location_id');
    const currentOrderRequestedAt = get(orderData, 'requested_at');
    const hasItemsInCart = get(orderData, 'cart', []).length;
    const promises = [fetchAndSetupMenu(requestedMenuLocationId)];

    /**
     * Catering orders cannot be requested for 'asap'
     * and validateCart will throw an error if attempting to
     * validate a catering location id with a requested at of asap.
     * In this case, we update the requested at to the current time
     * and pass it as a second option to the validate cart endpoint.
     */
    const nowFormatted =
      currentOrderRequestedAt === ASAP
        ? nowInTimezone(get(this, 'props.timezoneForCurrentLocation'))
        : null;
    if (requestedMenuLocationId !== currentOrderId && hasItemsInCart) {
      promises.push(
        validateAndRemoveInvalidItems(requestedMenuLocationId, nowFormatted)
      );
    } else {
      promises.push(setOrderLocationId(orderRef, requestedMenuLocationId));
    }

    return Promise.all(promises).then(res => {
      const menu = res.find(
        resolvedPromise =>
          resolvedPromise.action.type === `${FETCH_AND_SETUP_MENU}_${FULFILLED}`
      );

      return {
        locationData: locationData,
        menuData: get(menu, 'value[0].value')
      };
    });
  };

  afterModel = model => {
    const { actions, setNavTheme } = this.props;
    const locationSlug = get(this, 'props.match.params.locationName');

    setNavTheme(LIGHT);
    actions.setLocationTitle(dehyphenate(locationSlug));
  };
}

const mapStateToProps = state => ({
  allLocations: allLocations(state),
  menuStatus: menuStatus(state),
  brandibbleRef: get(state, 'brandibble.ref'),
  orderRef: get(state, 'brandibble.session.order.ref'),
  orderData: get(state, 'brandibble.session.order.orderData'),
  menus: get(state, 'brandibble.session.menus', []),
  fetchMenuStatus: get(state, 'brandibble.status.fetchMenu'),
  fetchMenuError: get(state, 'brandibble.error.fetchMenu'),
  setRequestedAtStatus: get(state, 'brandibble.status.setRequestedAt'),
  removeInvalidItemsStatus: get(state, 'status.removeInvalidItems'),
  timezoneForCurrentLocation: timezoneForCurrentLocation(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setLocationTitle,
      unsetLocationTitle,
      fetchAndSetupMenu,
      validateAndRemoveInvalidItems,
      setOrderLocationId,
      fetchMenu,
      setModal
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withDeviceWidthContext(
    withNavThemeContext(CitiesShowLocationsShowCateringMenuContainer)
  )
);
