import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  brandibbleOptionGroupMapping,
  brandibbleMenuItemOptionGroup
} from 'constants/PropTypes';
import get from 'utils/get';
import { getOptionItemMapping } from 'utils/getMenuItemsForLineItems';
import Language from 'constants/Language';
import { PRESENT } from 'constants/Brandibble';

import { RadioInput } from 'components/forms';
import SelectionHeader from './SelectionHeader';

import v from 'vudu';
import { classes as c, styles as s } from 'styles';

const classes = v({
  singleSelect: {
    '@composes': [s.mb4]
  }
});

class SingleSelect extends PureComponent {
  handleRadioSelect = (optionItem, optionIsPresent, isRequired) => {
    const optionGroupMapping = get(this, 'props.optionGroupMapping', {});
    const toggleAddOptionToLineItem = get(
      this,
      'props.toggleAddOptionToLineItem',
      f => f
    );
    const addOptionToLineItem = get(this, 'props.addOptionToLineItem', f => f);
    const removeOptionFromLineItem = get(
      this,
      'props.removeOptionFromLineItem',
      f => f
    );

    /**
     * Non required options
     * can be toggled off and on
     */
    if (!isRequired) {
      const optionGroupData = get(this, 'props.optionGroupData');
      if (optionIsPresent) {
        return removeOptionFromLineItem(optionItem);
      }

      return addOptionToLineItem(optionGroupData, optionItem);
    }

    /**
     * Required options
     * can toggle between options
     * but cannot be toggled off once selected
     */
    return toggleAddOptionToLineItem(optionGroupMapping, optionItem);
  };

  render() {
    const optionGroupMapping = get(this, 'props.optionGroupMapping');
    const optionGroupData = get(this, 'props.optionGroupData');
    const isRequired = get(this, 'props.isRequired');
    const optionItems = get(optionGroupData, 'option_items', []);

    return (
      <div className={classes.singleSelect}>
        <SelectionHeader
          title={Language.t('cateringModal.makeSelection')}
          subtitle={isRequired && Language.t('cateringModal.selectOne')}
          isRequired={isRequired}
        />
        <div className={c.my5}>
          <form>
            {optionItems.map(optionItem => {
              const optionItemMatch = getOptionItemMapping(
                get(optionGroupMapping, 'optionItems', []),
                get(optionItem, 'id')
              );

              const optionIsPresent =
                get(optionItemMatch, 'presence') === PRESENT;

              return (
                <RadioInput
                  value={get(optionItem, 'id')}
                  name={get(optionItem, 'name')}
                  checked={optionItemMatch && optionIsPresent}
                  onChange={() =>
                    this.handleRadioSelect(
                      optionItem,
                      optionIsPresent,
                      isRequired
                    )
                  }
                  label={get(optionItem, 'name')}
                />
              );
            })}
          </form>
        </div>
      </div>
    );
  }
}

SingleSelect.defaultProps = {
  optionGroupMapping: {},
  optionGroupData: {},
  toggleAddOptionToLineItem: f => f
};

SingleSelect.propTypes = {
  optionGroupMapping: brandibbleOptionGroupMapping,
  optionGroupData: brandibbleMenuItemOptionGroup,
  toggleAddOptionToLineItem: PropTypes.func
};

export default SingleSelect;
