import { createSelector } from 'reselect';

import get from 'utils/get';
import isEmptyObject from 'utils/isEmptyObject';
import { locationsIndexLink } from 'constants/DropdownLinks';

export default createSelector(
  state => get(state, 'global.data', {}),
  globalData => {
    if (isEmptyObject(globalData)) return [];

    const { fields } = globalData;

    const cities = get(fields, 'cities', []).map(city => ({
      label: get(city, 'fields.acronym', get(city, 'fields.title')),
      path: get(city, 'fields.path', '#'),
      nav: true,
      links: get(city, 'fields.locations', []).map(location => ({
        label: get(location, 'fields.title'),
        path: get(location, 'fields.path', '#'),
        nav: true
      }))
    }));

    return [locationsIndexLink, ...cities];
  }
);
