import { breakpoints } from 'styles/grid';
import Vars from 'constants/Vars';

const { paragraphLineHeight } = Vars;

const fontFamily = 'PitchSans';

const fontSizes = [
  '0.625rem', // font(1) | 10px
  '0.75rem', // font(2) | 12px
  '0.875rem', // font(3) | 14px
  '1rem', // font(4) | 16px
  '1.125rem', // font(5) | 18px
  '1.25rem', // font(6) | 20px
  '1.375rem', // font(7) | 22px
  '1.5rem', // font(8) | 24px
  '2rem', // font(9) | 32px
  '2.5rem', // font(10) | 40px
  '3rem', // font(11) | 48px
  '4rem' // font(12) | 64px
];

export const font = num => fontSizes[num - 1];

export const fontClasses = {
  monoLight: {
    fontFamily,
    fontWeight: 200
  },
  monoMedium: {
    fontFamily,
    fontWeight: 400
  },
  monoSemiBold: {
    fontFamily,
    fontWeight: 500
  },
  monoBold: {
    fontFamily,
    fontWeight: 600
  }
};

const type = {
  jumbo: {
    ...fontClasses.monoBold,
    fontSize: `${font(7)}`,
    letterSpacing: `0.0125rem`,
    [breakpoints.md]: {
      fontSize: `${font(12)}`,
      letterSpacing: `0.1875rem`
    }
  },
  headingLarge: {
    ...fontClasses.monoMedium,
    fontSize: `${font(10)}`,
    [breakpoints.md]: {
      fontSize: `${font(11)}`,
      letterSpacing: `0.140625rem`
    }
  },
  headingLargeLight: {
    ...fontClasses.monoLight,
    fontSize: `${font(9)}`,
    [breakpoints.md]: {
      fontSize: `${font(11)}`,
      letterSpacing: `0.16375rem`
    }
  },
  attribution: {
    ...fontClasses.monoMedium,
    fontSize: `${font(6)}`,
    [breakpoints.md]: {
      fontSize: `${font(7)}`
    }
  },
  pullquote: {
    ...fontClasses.monoMedium,
    fontSize: `${font(6)}`,
    letterSpacing: `0.03875rem`,
    lineHeight: `2.25rem`,
    [breakpoints.md]: {
      fontSize: `${font(9)}`,
      letterSpacing: `0.0625rem`,
      lineHeight: `3.5rem`
    }
  },
  pullquoteCompact: {
    ...fontClasses.monoLight,
    textTransform: `uppercase`,
    fontSize: `${font(5)}`,
    letterSpacing: `0.4`,
    lineHeight: `1.75rem`,
    [breakpoints.md]: {
      fontSize: `${font(7)}`,
      letterSpacing: `0.6`,
      lineHeight: `2rem`
    }
  },
  pullquoteLight: {
    ...fontClasses.monoLight,
    fontSize: `${font(6)}`,
    letterSpacing: `0.03875rem`,
    lineHeight: `2rem`,
    [breakpoints.md]: {
      fontSize: `${font(9)}`,
      letterSpacing: `0.0625rem`,
      lineHeight: `2.5rem`
    }
  },
  caption: {
    ...fontClasses.monoMedium,
    fontSize: `${font(2)}`,
    letterSpacing: `0.08rem`,
    lineHeight: `1.0625rem`,
    [breakpoints.md]: {
      fontSize: `${font(4)}`,
      letterSpacing: `0.16685rem`,
      lineHeight: `1.5rem`
    }
  },
  eyebrow: {
    ...fontClasses.monoBold,
    fontSize: `${font(5)}`,
    letterSpacing: `0.1418rem`,
    lineHeight: `1.5rem`
  },
  paragraphSmall: {
    ...fontClasses.monoMedium,
    fontSize: `${font(2)}`
  },
  paragraph: {
    ...fontClasses.monoMedium,
    fontSize: `${font(3)}`,
    letterSpacing: `0`,
    lineHeight: `${paragraphLineHeight}px`
  },
  titleSmall: {
    ...fontClasses.monoBold,
    fontSize: `${font(4)}`,
    letterSpacing: `0.07rem`,
    lineHeight: `1.875rem`
  },
  title: {
    ...fontClasses.monoBold,
    fontSize: `${font(4)}`,
    [breakpoints.md]: {
      fontSize: `${font(8)}`,
      letterSpacing: `0.07rem`,
      lineHeight: `1.875rem`
    }
  },
  titleLarge: {
    ...fontClasses.monoBold,
    fontSize: `${font(8)}`,
    letterSpacing: `0.07rem`,
    lineHeight: `1.875rem`,
    [breakpoints.md]: {
      fontSize: `${font(9)}`
    }
  },
  titleLargeLight: {
    ...fontClasses.monoLight,
    fontSize: `${font(8)}`,
    letterSpacing: `0.07rem`,
    lineHeight: `1.875rem`,
    [breakpoints.md]: {
      fontSize: `${font(9)}`,
      letterSpacing: `0.09375rem`,
      lineHeight: `2.5rem`
    }
  },
  cta: {
    ...fontClasses.monoBold,
    fontSize: `${font(4)}`,
    letterSpacing: `0.04125rem`,
    lineHeight: `1.0625rem`
  },
  accordionLabel: {
    ...fontClasses.monoBold,
    fontSize: `${font(6)}`,
    letterSpacing: `0.06875rem`,
    lineHeight: `2rem`
  },
  navItem: {
    ...fontClasses.monoBold,
    fontSize: `${font(4)}`,
    letterSpacing: `0.029375rem`,
    lineHeight: `1.5rem`,
    whiteSpace: 'nowrap'
  },
  heading: {
    ...fontClasses.monoSemiBold,
    fontSize: `${font(10)}`,
    letterSpacing: `0`,
    lineHeight: `3rem`
  },
  headingLight: {
    ...fontClasses.monoLight,
    fontSize: `${font(8)}`,
    letterSpacing: `0`,
    lineHeight: `1.5rem`,
    [breakpoints.md]: {
      fontSize: `${font(10)}`
    }
  },
  subheading: {
    ...fontClasses.monoSemiBold,
    fontSize: `${font(5)}`,
    letterSpacing: `0.09625rem`,
    lineHeight: `1.75rem`
  },
  subheadingText: {
    ...fontClasses.monoSemiBold,
    fontSize: `${font(5)}`,
    letterSpacing: `0.09625rem`,
    lineHeight: `1.75rem`
  },
  linkTitle: {
    ...fontClasses.monoSemiBold,
    fontSize: `${font(6)}`,
    letterSpacing: `0.04125rem`,
    lineHeight: `1.0625rem`
  },
  dropdownNavHeadingItem: {
    ...fontClasses.monoBold,
    fontSize: `${font(4)}`,
    letterSpacing: `0.04125rem`,
    lineHeight: `1.0625rem`,
    fontWeight: 800
  },
  dropdownNavItem: {
    ...fontClasses.monoBold,
    fontSize: `${font(3)}`,
    letterSpacing: `0.04125rem`,
    lineHeight: `1.0625rem`
  },
  footerNavItem: {
    ...fontClasses.monoMedium,
    fontSize: `${font(3)}`,
    letterSpacing: `0.04125rem`,
    lineHeight: `1.0625rem`
  },
  signature: {
    ...fontClasses.monoSemiBold,
    fontSize: `${font(5)}`,
    letterSpacing: `0.00625rem`,
    lineHeight: `1.375rem`,
    [breakpoints.md]: {
      fontSize: `${font(8)}`,
      lineHeight: `2rem`
    }
  },
  copyright: {
    ...fontClasses.monoMedium,
    fontSize: `${font(2)}`,
    letterSpacing: `0.00625rem`,
    lineHeight: `1rem`,
    [breakpoints.md]: {
      ...fontClasses.monoMedium,
      fontSize: `${font(3)}`,
      letterSpacing: `0.04125rem`,
      lineHeight: `1.0625rem`
    }
  },
  cardIndex: {
    ...fontClasses.monoLight,
    fontSize: `${font(4)}`,
    [breakpoints.md]: {
      fontSize: `${font(8)}`,
      letterSpacing: `0.07rem`,
      lineHeight: `1.875rem`
    }
  },
  cardTitle: {
    ...fontClasses.monoBold,
    fontSize: `${font(3)}`,
    letterSpacing: '0.01875rem',
    lineHeight: '0.75rem'
  },
  cardDetail: {
    ...fontClasses.monoMedium,
    fontSize: `${font(2)}`,
    letterSpacing: '0.0175rem',
    lineHeight: '1rem'
  },
  cardLink: {
    ...fontClasses.monoBold,
    fontSize: `${font(2)}`
  },
  scrollToNavItem: {
    ...fontClasses.monoSemiBold,
    fontSize: `${font(4)}`
  },
  announcement: {
    ...fontClasses.monoBold,
    fontSize: `${font(2)}`,
    letterSpacing: '0.01875rem',
    lineHeight: '1rem',
    [breakpoints.md]: {
      fontSize: `${font(3)}`
    }
  },
  textInput: {
    ...fontClasses.monoMedium,
    fontSize: `${font(4)}`,
    lineHeight: `1.5rem`
  },
  label: {
    ...fontClasses.monoSemiBold,
    fontSize: `${font(3)}`
  },
  labelSmall: {
    ...fontClasses.monoSemiBold,
    fontSize: `${font(1)}`
  },
  profileLabel: {
    fontFamily,
    fontSize: `${font(3)}`,
    [breakpoints.md]: {
      fontSize: `${font(4)}`
    }
  },
  modalTitle: {
    ...fontClasses.monoBold,
    fontSize: `${font(6)}`,
    textTransform: 'uppercase'
  },
  modalTabLabel: {
    ...fontClasses.monoBold,
    fontSize: `${font(4)}`
  },
  modalFooter: {
    ...fontClasses.monoMedium,
    fontSize: `${font(2)}`,
    lineHeight: '1rem'
  },
  menuTitle: {
    ...fontClasses.monoLight,
    fontSize: `${font(11)}`,
    [breakpoints.md]: {
      fontSize: `${font(12)}`
    }
  },
  receipt: {
    ...fontClasses.monoMedium,
    fontSize: `${font(3)}`,
    letterSpacing: `0.08rem`,
    lineHeight: `1.5rem`,
    [breakpoints.md]: {
      fontSize: `${font(5)}`
    }
  }
};

export default type;
