import ContainerBase from 'lib/ContainerBase';
import triggerToast, { SUCCESS, ERROR } from 'utils/triggerToast';
import get from 'utils/get';
import Language from 'constants/Language';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetUserPassword, Status } from 'brandibble-redux';

import withNavThemeContext from 'lib/withNavThemeContext';
import { DARK } from 'constants/ColorMaps';

class ForgotPasswordContainer extends ContainerBase {
  componentDidUpdate(prevProps) {
    if (
      prevProps.resetUserPasswordStatus === Status.PENDING &&
      this.props.resetUserPasswordStatus === Status.FULFILLED
    ) {
      triggerToast(Language.t('successMessages.toast.forgotPassword'), SUCCESS);
    }

    if (
      prevProps.resetUserPasswordStatus === Status.PENDING &&
      this.props.resetUserPasswordStatus === Status.REJECTED
    ) {
      triggerToast(Language.t('errorMessages.toast.forgotPassword'), ERROR);
    }
  }

  view = import('views/ForgotPasswordView');

  afterModel = () => {
    this.props.setNavTheme(DARK);
  };
}

const mapStateToProps = state => ({
  brandibbleRef: get(state, 'brandibble.ref'),
  resetUserPasswordStatus: get(state, 'brandibble.status.resetUserPassword'),
  resetUserPasswordError: get(state, 'brandibble.error.resetUserPassword')
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetUserPassword
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavThemeContext(ForgotPasswordContainer));
