import { put, select } from 'redux-saga/effects';
import { Status, setDefaultPayment, paymentsAsArray } from 'brandibble-redux';
import get from 'utils/get';

const { PENDING, FULFILLED, REJECTED } = Status;

export const ON_CREATE_PAYMENT_METHOD_SAGA = 'ON_CREATE_PAYMENT_METHOD_SAGA';

function* onCreatePaymentMethodSaga() {
  yield put({ type: `${ON_CREATE_PAYMENT_METHOD_SAGA}_${PENDING}` });

  const brandibble = yield select(state => state.brandibble);
  const brandibbleRef = get(brandibble, 'ref');

  const allPaymentMethods = paymentsAsArray(brandibble);
  const recentlyAddedPaymentId =
    allPaymentMethods[allPaymentMethods.length - 1].customer_card_id;

  yield put(setDefaultPayment(brandibbleRef, recentlyAddedPaymentId));
}

/**
 * If all dispatched actions become FULFILLED
 * and ON_CREATE_PAYMENT_METHOD_SAGA is in a PENDING state
 * we can assume ON_CREATE_PAYMENT_METHOD_SAGA_FULFILLED
 */

function* handleOnCreatePaymentMethodSagaFulfilled() {
  const onCreatePaymentMethodSagaPending = yield select(
    state => state.status.onCreatePaymentMethodSaga === PENDING
  );
  const setDefaultPaymentFulfilled = yield select(
    state => state.brandibble.status.setDefaultPayment === FULFILLED
  );

  if (onCreatePaymentMethodSagaPending && setDefaultPaymentFulfilled) {
    yield put({ type: `${ON_CREATE_PAYMENT_METHOD_SAGA}_${FULFILLED}` });
  }
}

/**
 * If all dispatched actions become REJECTED
 * and ON_CREATE_PAYMENT_METHOD_SAGA is in a PENDING state
 * we can assume ON_CREATE_PAYMENT_METHOD_SAGA_REJECTED
 */

function* handleOnCreatePaymentMethodSagaRejected() {
  const onCreatePaymentMethodSagaPending = yield select(
    state => state.status.onCreatePaymentMethodSaga === PENDING
  );
  const setDefaultPaymentRejected = yield select(
    state => state.brandibble.status.setDefaultPayment === REJECTED
  );

  if (onCreatePaymentMethodSagaPending && setDefaultPaymentRejected) {
    yield put({ type: `${ON_CREATE_PAYMENT_METHOD_SAGA}_${REJECTED}` });
  }
}

export {
  handleOnCreatePaymentMethodSagaFulfilled,
  handleOnCreatePaymentMethodSagaRejected
};
export default onCreatePaymentMethodSaga;
