import { themes } from 'styles/colors';

import logoBlack from 'assets/tartine-black.svg';

const DARK = 'dark';
const LIGHT = 'light';

const navTheme = {
  [DARK]: {
    name: DARK,
    hex: themes.dark
  },
  [LIGHT]: {
    name: LIGHT,
    hex: themes.light
  }
};

const logo = {
  [DARK]: logoBlack,
  [LIGHT]: logoBlack
};

export { navTheme, logo, DARK, LIGHT };
