import { all, put, select } from 'redux-saga/effects';
import {
  fetchPayments,
  fetchAllCustomerOrders,
  Status
} from 'brandibble-redux';
import get from 'utils/get';
import { userIsAuthenticated } from 'state/selectors';

const { PENDING, FULFILLED, REJECTED } = Status;

export const ON_INITIALIZE_APPLICATION_SAGA = 'ON_INITIALIZE_APPLICATION_SAGA';

function* onInitializeApplicationSaga() {
  const hasCustomer = yield select(state => userIsAuthenticated(state));

  if (hasCustomer) {
    yield put({ type: `${ON_INITIALIZE_APPLICATION_SAGA}_${PENDING}` });

    const brandibble = yield select(state => state.brandibble);

    const brandibbleRef = get(brandibble, 'ref');
    const customerId = get(brandibble, 'user.attributes.customer_id');
    const includeItemDetails = true;

    yield all([
      put(fetchPayments(brandibbleRef)),
      put(fetchAllCustomerOrders(brandibbleRef, customerId, includeItemDetails))
    ]);
  }
}

/**
 * If all dispatched actions become FULFILLED
 * and ON_INITIALIZE_APPLICATION_SAGA is in a PENDING state
 * we can assume ON_INITIALIZE_APPLICATION_SAGA_FULFILLED
 */

function* handleOnInitializeApplicationSagaFulfilled() {
  const onInitializeApplicationSagaPending = yield select(
    state => state.status.onInitializeApplicationSaga === PENDING
  );
  const fetchPaymentsFulfilled = yield select(
    state => state.brandibble.status.fetchPayments === FULFILLED
  );
  const fetchAllCustomerOrdersFulfilled = yield select(
    state => state.brandibble.status.fetchAllCustomerOrders === FULFILLED
  );

  if (
    onInitializeApplicationSagaPending &&
    fetchPaymentsFulfilled &&
    fetchAllCustomerOrdersFulfilled
  ) {
    yield put({ type: `${ON_INITIALIZE_APPLICATION_SAGA}_${FULFILLED}` });
  }
}

/**
 * If any dispatched actions become REJECTED
 * and ON_INITIALIZE_APPLICATION_SAGA is in a PENDING state
 * we can assume ON_INITIALIZE_APPLICATION_SAGA_REJECTED
 */

function* handleOnInitializeApplicationSagaRejected() {
  const onInitializeApplicationSagaPending = yield select(
    state => state.status.onInitializeApplicationSaga === PENDING
  );
  const fetchPaymentsRejected = yield select(
    state => state.brandibble.status.fetchPayments === REJECTED
  );
  const fetchAllCustomerOrdersRejected = yield select(
    state => state.brandibble.status.fetchAllCustomerOrders === REJECTED
  );

  if (
    onInitializeApplicationSagaPending &&
    (fetchPaymentsRejected || fetchAllCustomerOrdersRejected)
  ) {
    yield put({ type: `${ON_INITIALIZE_APPLICATION_SAGA}_${REJECTED}` });
  }
}

export {
  handleOnInitializeApplicationSagaFulfilled,
  handleOnInitializeApplicationSagaRejected
};
export default onInitializeApplicationSaga;
