import React from 'react';
import PropTypes from 'prop-types';

import { Text, SmartLink } from 'components/base';

import get from 'utils/get';

import v from 'vudu';
import { styles as s, classes as c } from 'styles';

const classes = v({
  dropdownSection: {
    '@composes': [s.flex, s.flexColumn, s.mt6]
  },
  link: {
    '@composes': [s.block, s.py1]
  }
});

const renderLink = ({ label, path }) => (
  <SmartLink className={classes.link} key={label} to={path}>
    <Text variant="navItem">{label}</Text>
  </SmartLink>
);

const renderSection = (subLink, index) => {
  if (get(subLink, 'links')) {
    return (
      <div className={classes.dropdownSection} key={index}>
        <SmartLink to={subLink.path}>
          <Text className={c.block} variant="navItem" color="midGray">
            {get(subLink, 'label')}
          </Text>
        </SmartLink>
        {subLink.links.map(renderLink)}
      </div>
    );
  }

  return renderLink(subLink);
};

const MobileMenuSubItem = ({ subLinks, closeMobileMenu }) => (
  <div tabIndex={-1} role="button" onClick={closeMobileMenu}>
    {subLinks.map(renderSection)}
  </div>
);

MobileMenuSubItem.propTypes = {
  subLinks: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        titleLinkLabel: PropTypes.string.isRequired,
        titleLinkPath: PropTypes.string.isRequired,
        subLinks: PropTypes.array
      }),
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired
      })
    ])
  ).isRequired,
  closeMobileMenu: PropTypes.func.isRequired
};

export default MobileMenuSubItem;
