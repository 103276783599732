import React, { PureComponent } from 'react';
import get from 'utils/get';
import triggerToast, { SUCCESS } from 'utils/triggerToast';
import Language from 'constants/Language';
import Vars from 'constants/Vars';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Status,
  actionTypes as brandibbleActionTypes,
  deletePayment,
  setOrderLocationId
} from 'brandibble-redux';
import { resetModal } from 'state/actions/ui/modalActions';
import { attemptReorder } from 'state/actions/brandibble/orderActions';

import { Text, Button } from 'components/base';

import v from 'vudu';
import { breakpoints, styles as s, classes as c, format as f } from 'styles';

const { PENDING, FULFILLED } = Status;
const { DELETE_PAYMENT, SET_ORDER_LOCATION_ID } = brandibbleActionTypes;
const { confirmationModalMaxWidth, confirmationModalMinHeight } = Vars;

const classes = v({
  confirmationModal: {
    '@composes': [s.flex, s.flexColumn],
    maxWidth: `${confirmationModalMaxWidth}px`,
    minHeight: `${confirmationModalMinHeight}px`
  },
  modalButtonsContainer: {
    '@composes': [s.flex, s.alignCenter, s.spaceBetween],

    [breakpoints.md]: {
      '@composes': [s.spaceAround]
    }
  }
});

const getStatusForActionType = function(actionType) {
  switch (actionType) {
    case DELETE_PAYMENT:
      return 'deletePaymentStatus';
    case SET_ORDER_LOCATION_ID:
      return 'setLocationIdStatus';
    default:
      return undefined;
  }
};

class ConfirmationModal extends PureComponent {
  constructor(props) {
    super(...arguments);

    this.confirmationActionStatus = getStatusForActionType(
      get(props, 'data.confirmationActionType')
    );
  }

  componentDidUpdate(prevProps) {
    const resetModal = get(this, 'props.actions.resetModal', f => f);
    const attemptReorder = get(this, 'props.actions.attemptReorder', f => f);
    const data = get(this, 'props.data');

    // Delete Payment
    if (
      get(prevProps, 'deletePaymentStatus') === PENDING &&
      get(this, 'props.deletePaymentStatus') === FULFILLED
    ) {
      triggerToast(
        Language.t('successMessages.toast.deletePaymentMethod'),
        SUCCESS
      );
      resetModal();
    }

    // Set Order Location Id
    if (
      get(prevProps, 'setOrderLocationIdStatus') === PENDING &&
      get(this, 'props.setOrderLocationIdStatus') === FULFILLED
    ) {
      attemptReorder(get(data, 'locationId'), get(data, 'reorderItems'));
      resetModal();
    }
  }

  handleConfirmation = () => {
    const data = get(this, 'props.data', {});
    const actions = get(this, 'props.actions');
    const confirmationActionType = get(data, 'confirmationActionType');
    const brandibbleRef = get(this, 'props.brandibbleRef');
    const orderRef = get(this, 'props.orderRef');

    switch (confirmationActionType) {
      case DELETE_PAYMENT:
        return get(actions, 'deletePayment', f => f)(
          brandibbleRef,
          get(data, 'paymentMethod.customer_card_id')
        );
      case SET_ORDER_LOCATION_ID:
        return get(actions, 'setOrderLocationId', f => f)(
          orderRef,
          get(data, 'locationId')
        );
      default:
        return false;
    }
  };

  render() {
    const title = get(this, 'props.data.title');
    const description = get(this, 'props.data.description');
    const resetModal = get(this, 'props.actions.resetModal');
    const isSubmitting =
      get(this, `props[${this.confirmationActionStatus}`) === PENDING;

    return (
      <div className={classes.confirmationModal}>
        <div className={c.grow1}>
          <Text className={f(c.center, c.mb7)} variant="modalTitle">
            {title}
          </Text>
          <Text variant="paragraph">{description}</Text>
        </div>
        <div className={classes.modalButtonsContainer}>
          <Button
            onClick={resetModal}
            variant="basicText"
            text={Language.t('confirmationModal.cancel')}
            type="button"
          />
          <Button
            onClick={this.handleConfirmation}
            showLoading={isSubmitting}
            disabledClassName={c.basicTextDisabled}
            type="submit"
            variant="basicText"
            text={Language.t('confirmationModal.confirm')}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brandibbleRef: get(state, 'brandibble.ref'),
  orderRef: get(state, 'brandibble.session.order.ref'),
  deletePaymentStatus: get(state, 'brandibble.status.deletePayment'),
  setOrderLocationIdStatus: get(state, 'brandibble.status.setOrderLocationId')
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetModal,
      deletePayment,
      attemptReorder,
      setOrderLocationId
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationModal);
