import { createSelector } from 'reselect';

import get from 'utils/get';

export default createSelector(
  state => get(state, 'global.data', {}),
  globalData => {
    if (!globalData) return null;

    const { fields } = globalData;

    /**
     * Filter orderable cities
     * by determining
     * which cities have at least
     * a pickup link or a delivery link
     */

    const citiesWithPickup = get(fields, 'cities', []).filter(city =>
      get(city, 'fields.locations', []).find(
        location => !!location.fields.preorderLink
      )
    );

    const citiesWithDelivery = get(fields, 'cities', []).filter(city =>
      get(city, 'fields.locations', []).find(
        location => !!location.fields.orderingLink
      )
    );

    /**
     * Map orderable cities
     * and their respective pickup and delivery locations
     * by service type
     */

    const citiesWithPickupLocations = citiesWithPickup.map(city => ({
      label: get(city, 'fields.title'),
      acronym: get(city, 'fields.acronym', get(city, 'fields.title')),
      path: get(city, 'fields.path', '#'),
      orderableLocations: get(city, 'fields.locations', []).filter(
        location => !!get(location, 'fields.preorderLink')
      )
    }));

    const citiesWithDeliveryLocations = citiesWithDelivery.map(city => ({
      label: get(city, 'fields.title'),
      acronym: get(city, 'fields.acronym', get(city, 'fields.title')),
      path: get(city, 'fields.path', '#'),
      orderableLocations: get(city, 'fields.locations', []).filter(
        location => !!get(location, 'fields.orderingLink')
      )
    }));

    const orderTypes = [];
    if (citiesWithPickupLocations.length) {
      orderTypes.push({
        label: 'Pick-up',
        slug: 'order',
        orderableCities: citiesWithPickupLocations
      });
    }

    if (citiesWithDeliveryLocations.length) {
      orderTypes.push({
        label: 'Delivery',
        slug: 'catering',
        orderableCities: citiesWithDeliveryLocations
      });
    }

    return {
      orderTypes
    };
  }
);
