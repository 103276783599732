import React, { PureComponent, Fragment } from 'react';
import { brandibbleLineItem } from 'constants/PropTypes';
import currency from 'currency.js';
import get from 'utils/get';
import calculateTotalLineItemPrice from 'utils/calculateTotalLineItemPrice';
import {
  getLineItemData,
  getOptionGroupData
} from 'utils/getMenuItemsForLineItems';
import Language from 'constants/Language';
import {
  SINGLE_SELECT,
  MULTI_SELECT
} from 'constants/LineItemConfigurationModalVariants';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { matchingMenuItemsForLineItems } from 'state/selectors';
import { resetModal } from 'state/actions/ui/modalActions';

import withLineItemActions from 'lib/withLineItemActions';

import { Text, Button } from 'components/base';
import { AddLineItemInstructionsForm } from 'components/forms';
import { SingleSelect, MultiSelect } from './ConfigurationVariants';
import QuantitySpinner from 'components/QuantitySpinner';

import v from 'vudu';
import { classes as c, styles as s, format as f, colors, size } from 'styles';

import Vars from 'constants/Vars';
const { modalHeaderHeight, modalFooterHeight, modalFooterButtonHeight } = Vars;

const classes = v({
  header: {
    '@composes': [s.p7],
    height: `${modalHeaderHeight}px`
  },
  content: {
    '@composes': [s.p7],
    overflowY: 'auto'
  },
  contentGradient: {
    '@composes': [s.overflowGradientWhite, s.col12, s.fixed, s.l0],
    bottom: `calc(${modalFooterHeight}px - 1px)`,
    height: `${size(5)}`
  },
  footer: {
    height: `${modalFooterHeight}px`,
    borderTop: `solid 1px ${colors.gray}`
  },
  section: {
    '@composes': [s.mb4]
  },
  sectionHeader: {
    '@composes': [s.py4],
    borderBottom: `solid 1px ${colors.darkGray}`
  },
  checkbox: {
    ':not(:last-of-type)': {
      '@composes': [s.mb3]
    }
  },
  note: {
    '@composes': [s.cardDetail, s.mt11, s.bgLightGray],
    border: 'none',
    '::placeholder': {
      '@composes': [s.black]
    }
  },
  footerButton: {
    '@composes': [s.col6],
    height: `${modalFooterButtonHeight}px`
  }
});

class LineItemConfigurationModal extends PureComponent {
  componentDidMount() {
    this.setDefaultQuantityAsMinQuantity();
  }

  setDefaultQuantityAsMinQuantity() {
    const lineItem = get(this, 'props.lineItemsData', []).find(
      lineItem => lineItem.uuid === get(this, 'props.lineItem.uuid')
    );
    const setLineItemQuantity = get(this, 'props.setLineItemQuantity', f => f);
    const lineItemMinQuantity = get(lineItem, 'productData.min_quantity', 0);
    setLineItemQuantity(lineItemMinQuantity);
  }

  getConfigurationVariantForOptionGroup = optionGroupData => {
    const minOptions = get(optionGroupData, 'min_options');
    const maxOptions = get(optionGroupData, 'max_options');

    const optionalSingleSelect = minOptions === 0 && maxOptions === 1;
    const requiredSingleSelect = minOptions === 1 && maxOptions === 1;

    const optionalMultiSelect = minOptions === 0 && maxOptions === 0;
    const requiredMultiSelect = minOptions > 1 && maxOptions === 0;

    /**
     * If an optionGroup has max_options of 1
     * a radio button configuration variant
     */
    if (optionalSingleSelect || requiredSingleSelect) {
      return {
        variant: SINGLE_SELECT,
        isRequired: requiredSingleSelect
      };
    }

    /**
     * If an optionGroup has no max_options (max_options: 0)
     * we want to render a multiselect with a quantity spinner
     * for each optionItem
     */
    if (optionalMultiSelect || requiredMultiSelect) {
      return {
        variant: MULTI_SELECT,
        isRequired: requiredMultiSelect
      };
    }

    return null;
  };

  renderModalHeader = lineItemData => {
    const itemName = get(lineItemData, 'name', '');
    const itemDescription = get(lineItemData, 'description', '');

    return (
      <Fragment>
        <Text variant="eyebrow" className={c.mb5}>
          {itemName}
        </Text>
        {!!itemDescription && (
          <Text variant="cardDetail" className={c.mb5}>
            {itemDescription}
          </Text>
        )}
      </Fragment>
    );
  };

  renderModalInnerContent = (lineItem, lineItemData) => {
    const optionGroupMappings = get(lineItem, 'optionGroupMappings', []);
    const toggleAddOptionToLineItem = get(
      this,
      'props.toggleAddOptionToLineItem',
      f => f
    );
    const addOptionToLineItem = get(this, 'props.addOptionToLineItem', f => f);
    const removeOptionFromLineItem = get(
      this,
      'props.removeOptionFromLineItem',
      f => f
    );
    /**
     * Here we map thorugh optionGroupMappings
     * and return the correct configuration component
     * based on the configuration schema determined
     * by the getConfigurationVariantForOptionGroup method
     */

    return optionGroupMappings.map(optionGroupMapping => {
      /**
       * optionGroupData is synced with the current menus option groups
       */
      const optionGroupData = getOptionGroupData(
        get(lineItemData, 'option_groups', []),
        get(optionGroupMapping, 'optionGroupData.id')
      );

      const configurationVariantForOptionGroup = this.getConfigurationVariantForOptionGroup(
        optionGroupData
      );

      const { variant, isRequired } = configurationVariantForOptionGroup;

      switch (variant) {
        /**
         * Option group where only one of a
         * selection of modifiers can be chosen
         */
        case SINGLE_SELECT:
          return (
            <SingleSelect
              optionGroupMapping={optionGroupMapping}
              optionGroupData={optionGroupData}
              toggleAddOptionToLineItem={toggleAddOptionToLineItem}
              addOptionToLineItem={addOptionToLineItem}
              removeOptionFromLineItem={removeOptionFromLineItem}
              isRequired={isRequired}
            />
          );

        /**
         * Option group where more than one modifier
         * can be added.
         */
        case MULTI_SELECT:
          return (
            <MultiSelect
              optionGroupMapping={optionGroupMapping}
              optionGroupData={optionGroupData}
              addOptionToLineItem={addOptionToLineItem}
              removeOptionFromLineItem={removeOptionFromLineItem}
              isRequired={isRequired}
            />
          );
        /**
         * Non-configurable item
         */
        default:
          return null;
      }
    });
  };

  renderModalFooterContent = (lineItem, lineItemIsValid) => {
    const resetModal = get(this, 'props.actions.resetModal', f => f);
    const lineItemQuantity = get(lineItem, 'quantity', 0);
    const lineItemMinQuantity = get(lineItem, 'productData.min_quantity', 0);
    const lineItemMaxQuantity = get(lineItem, 'productData.max_quantity', 0);

    const totalLineItemPrice = calculateTotalLineItemPrice(lineItem);

    return (
      <div className={c.p7}>
        <Text className={f(c.darkGray, c.mb5)} variant="modalFooter">
          {Language.t('cateringModal.quantity')}
        </Text>
        <div className={c.flex}>
          <span className={f(classes.footerButton, c.mr3)}>
            <QuantitySpinner
              className={f(c.col12)}
              quantity={lineItemQuantity}
              canDecrement={lineItemQuantity > lineItemMinQuantity}
              canIncrement={lineItemQuantity < lineItemMaxQuantity}
              handleIncrement={get(this, 'props.addOrIncrementLineItem')}
              handleDecrement={get(this, 'props.decrementOrRemoveLineItem')}
            />
          </span>
          <span className={f(classes.footerButton, c.ml3)}>
            <Button
              className={c.col12}
              isDisabled={!lineItemIsValid}
              onClick={() => resetModal()}
              variant="goldBrickSmallSolid"
              highlight={Language.t('cateringModal.addToBag')}
              text={currency(totalLineItemPrice, {
                formatWithSymbol: true
              }).format()}
            />
          </span>
        </div>
      </div>
    );
  };

  handleCancel = () => {
    const resetModal = get(this, 'props.actions.resetModal', f => f);
    const removeLineItem = get(this, 'props.removeLineItem', f => f);

    removeLineItem();
    resetModal();
  };

  render() {
    const lineItem = get(this, 'props.lineItemsData', []).find(
      lineItem => lineItem.uuid === get(this, 'props.lineItem.uuid')
    );
    const lineItemData = getLineItemData(
      get(this, 'props.matchingMenuItemsForLineItems', []),
      get(lineItem, 'productData.id')
    );

    const lineItemIsValid = get(lineItem, 'isValid', false);
    const lineItemInstructions = get(lineItem, 'instructions', '');
    const setLineItemInstructions = get(
      this,
      'props.setLineItemInstructions',
      f => f
    );

    return (
      <Fragment>
        <div className={classes.header}>
          <Button
            className={f(c.mb6, c.monoBold, c.hoverOpacity)}
            onClick={this.handleCancel}
            variant="paragraph"
            text={Language.t('cateringModal.close')}
            type="button"
          />
        </div>
        <div className={classes.content}>
          {this.renderModalHeader(lineItemData)}
          {this.renderModalInnerContent(lineItem, lineItemData)}
          <AddLineItemInstructionsForm
            instructions={lineItemInstructions}
            setLineItemInstructions={setLineItemInstructions}
          />
          <div className={classes.contentGradient} />
        </div>
        <div className={classes.footer}>
          {this.renderModalFooterContent(lineItem, lineItemIsValid)}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  brandibbleRef: get(state, 'brandibble.ref'),
  lineItemsData: get(state, 'brandibble.session.order.lineItemsData'),
  matchingMenuItemsForLineItems: matchingMenuItemsForLineItems(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetModal
    },
    dispatch
  )
});

LineItemConfigurationModal.defaultProps = {
  lineItem: {}
};

LineItemConfigurationModal.propTypes = {
  lineItem: brandibbleLineItem
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLineItemActions(LineItemConfigurationModal));
