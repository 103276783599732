export const OPEN_MOBILE_MENU = 'OPEN_MOBILE_MENU';
export const CLOSE_MOBILE_MENU = 'CLOSE_MOBILE_MENU';

export const openMobileMenu = () => dispatch =>
  dispatch({
    type: OPEN_MOBILE_MENU
  });

export const closeMobileMenu = () => dispatch =>
  dispatch({
    type: CLOSE_MOBILE_MENU
  });
