import {
  SET_LOCATION_TITLE,
  UNSET_LOCATION_TITLE
} from 'state/actions/ui/headerActions';

const initialState = {
  locationTitle: ''
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOCATION_TITLE:
      return { ...state, locationTitle: payload };
    case UNSET_LOCATION_TITLE:
      return { ...state, locationTitle: initialState.locationTitle };
    default:
      return state;
  }
};
