import React, { Component } from 'react';

import { NavThemeContext } from 'context/NavThemeContext';

const withNavThemeContext = WrappedComponent => {
  /* eslint-disable react/prefer-stateless-function */
  class WithNavThemeContextComponent extends Component {
    render() {
      return (
        <NavThemeContext.Consumer>
          {context => (
            <WrappedComponent
              {...this.props}
              navTheme={context.color}
              setNavTheme={context.setNavTheme}
            />
          )}
        </NavThemeContext.Consumer>
      );
    }
  }
  /* eslint-enable */

  return WithNavThemeContextComponent;
};

export default withNavThemeContext;
