export const generateTransition = (
  property = 'all',
  duration = `150ms`,
  timingFunction = `ease-in-out`
) => `${property} ${duration} ${timingFunction}`;

const transitions = {
  transitionTransform: {
    transition: generateTransition('transform', '250ms')
  },
  transitionBackground: {
    transition: generateTransition('background-color', '320ms')
  },
  transitionBorder: {
    transition: generateTransition('border', '320ms')
  },
  transitionOpacity: {
    transition: generateTransition('opacity', '320ms')
  },
  transitionColor: {
    transition: generateTransition('color', '250ms')
  },
  transitionAll: {
    transition: generateTransition('all', '250ms')
  }
};

export default transitions;
