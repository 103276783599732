import ContainerBase from 'lib/ContainerBase';
import ContentfulData from 'lib/ContentfulData';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import get from 'utils/get';
import withNavThemeContext from 'lib/withNavThemeContext';
import withDeviceWidthContext from 'lib/withDeviceWidthContext';

import { locationsGeoJSON, announcementData } from 'state/selectors';
import {
  setLocationTitle,
  unsetLocationTitle
} from 'state/actions/ui/headerActions';

class CitiesShowLocationsShowContainer extends ContainerBase {
  view = import('views/CitiesShowLocationsShowView');

  componentWillUnmount() {
    const { actions } = this.props;

    return actions.unsetLocationTitle();
  }

  model = () => {
    const {
      match: {
        params: { locationName }
      },
      activeLocale
    } = this.props;

    return ContentfulData.getEntries({
      content_type: 'locationPage',
      'fields.slug': locationName,
      include: 2,
      locale: activeLocale
    }).then(res => res.items[0]);
  };

  afterModel = model => {
    const { actions, setNavTheme } = this.props;
    const locationTitle = get(this, 'props.match.params.locationName');

    setNavTheme(get(model, 'fields.headerTheme'));
    actions.setLocationTitle(locationTitle);
  };
}

const mapStateToProps = state => {
  return {
    announcementData: announcementData(state),
    activeLocale: get(state, 'locale.activeLocale'),
    locationsGeoJSON: locationsGeoJSON(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setLocationTitle,
      unsetLocationTitle
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withNavThemeContext(withDeviceWidthContext(CitiesShowLocationsShowContainer))
);
