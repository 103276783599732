import { createSelector } from 'reselect';
import { customerOrdersData, recentOrder } from 'state/selectors';
import _isEmpty from 'lodash/isEmpty';

export default createSelector(
  state => customerOrdersData(state),
  state => recentOrder(state),
  (customerOrdersData, recentOrder) => {
    if (
      _isEmpty(recentOrder) ||
      customerOrdersData.find(
        order => order.orders_id === recentOrder.orders_id
      )
    ) {
      return customerOrdersData;
    }

    return [recentOrder, ...customerOrdersData];
  }
);
