import ContainerBase from 'lib/ContainerBase';
import triggerToast, { SUCCESS } from 'utils/triggerToast';
import get from 'utils/get';
import Language from 'constants/Language';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { parsedResetToken } from 'state/selectors';
import { finishResetUserPassword, Status } from 'brandibble-redux';

import withNavThemeContext from 'lib/withNavThemeContext';
import { DARK } from 'constants/ColorMaps';

class ResetPasswordContainer extends ContainerBase {
  componentDidUpdate(prevProps) {
    if (
      prevProps.finishResetUserPasswordStatus === Status.PENDING &&
      this.props.finishResetUserPasswordStatus === Status.FULFILLED
    ) {
      triggerToast(Language.t('successMessages.toast.resetPassword'), SUCCESS);
      this.props.history.push('/sign-in');
    }
  }

  view = import('views/ResetPasswordView');

  afterModel = () => {
    this.props.setNavTheme(DARK);
  };
}

const mapStateToProps = state => ({
  brandibbleRef: get(state, 'brandibble.ref'),
  finishResetUserPasswordStatus: get(
    state,
    'brandibble.status.finishResetUserPassword'
  ),
  finishResetUserPasswordError: get(
    state,
    'brandibble.error.finishResetUserPassword'
  ),
  resetToken: parsedResetToken(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      finishResetUserPassword
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavThemeContext(ResetPasswordContainer));
