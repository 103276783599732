import React from 'react';
import PropTypes from 'prop-types';
import Language from 'constants/Language';

import { Text } from 'components/base';

import { classes as c, format as f } from 'styles';

const SpecialInstructionsForLineItem = ({ instructions, label }) => {
  if (!instructions || !instructions.length) return null;

  return (
    <div className={f(c.my4, c.p4, c.bgLightGray)}>
      <Text variant="label">{label}</Text>
      <Text color="darkGray" variant="paragraph">
        {instructions}
      </Text>
    </div>
  );
};

SpecialInstructionsForLineItem.defaultProps = {
  label: Language.t('specialInstructionForLineItem.label'),
  instructions: ''
};

SpecialInstructionsForLineItem.propTypes = {
  label: PropTypes.string,
  instructions: PropTypes.string
};

export default SpecialInstructionsForLineItem;
