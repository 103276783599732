import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Mocks from 'constants/Mocks';

const { title, description, image, handle, siteName, url } = Mocks.meta;

const Meta = ({ title, description, image, handle, siteName, url }) => (
  <Helmet
    title={title}
    meta={[
      {
        name: 'description',
        content: `${description}`
      },
      /** 
        Twitter
      */
      {
        name: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        name: 'twitter:site',
        content: `${handle}`
      },
      {
        name: 'twitter:title',
        content: `${title}`
      },
      {
        name: 'twitter:description',
        content: `${description}`
      },
      {
        name: 'twitter:description',
        content: `${description}`
      },
      {
        name: 'twitter:description',
        content: `${description}`
      },
      /**
       Facebook
       */
      {
        name: 'og:title',
        content: `${title}`
      },
      {
        name: 'og:type',
        content: 'website'
      },
      {
        name: 'og:url',
        content: `${url}`
      },
      {
        name: 'og:image',
        content: `${image}`
      },
      {
        name: 'og:description',
        content: `${description}`
      },
      {
        name: 'og:site_name',
        content: `${siteName}`
      }
    ]}
  />
);

Meta.defaultProps = {
  title,
  description,
  image,
  handle,
  siteName,
  url
};

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  handle: PropTypes.string,
  siteName: PropTypes.string,
  url: PropTypes.string
};

export default Meta;
