import { createSelector } from 'reselect';

import get from 'utils/get';
import { DateTime } from 'luxon';

import { DATE_AND_TIME } from 'constants/DateTimeFormats';

export default createSelector(
  state => get(state, 'brandibble.data.customerOrders.all.data', []),
  customerOrdersData => {
    const customerOrders = customerOrdersData.map(order => {
      const submittedDatetimeString = `${get(order, 'submitted_date')} ${get(
        order,
        'submitted_time'
      )}`;

      const requestedDatetimeString = `${get(order, 'requested_date')} ${get(
        order,
        'requested_time'
      )}`;

      return {
        ...order,
        submitted_datetime: DateTime.fromFormat(
          submittedDatetimeString,
          DATE_AND_TIME
        ),
        requested_datetime: DateTime.fromFormat(
          requestedDatetimeString,
          DATE_AND_TIME
        ),
        isRecent: false
      };
    });

    // Sort by "open" then "past"
    const sortByStatus = (a, b) =>
      get(a, 'status', '').localeCompare(get(b, 'status', ''));

    // Sort newest to oldest
    const sortByDescendingPickupTime = (a, b) =>
      b.requested_datetime - a.requested_datetime;

    return customerOrders.sort(
      (a, b) => sortByStatus(a, b) || sortByDescendingPickupTime(a, b)
    );
  }
);
