import 'core-js/fn/array/find';
import 'core-js/fn/array/includes';
import 'core-js/fn/object/keys';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import whatInput from 'what-input'; // eslint-disable-line no-unused-vars
import * as Sentry from '@sentry/browser';
import 'normalize.css';
import 'index.css';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from 'state/store';

import ScrollReset from 'components/ScrollReset';
import App from 'App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://95e53b59dcb94f1c8cfc158c33c5e487@sentry.io/1766094'
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollReset>
        <App />
      </ScrollReset>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
