import ContainerBase from 'lib/ContainerBase';
import ContentfulData from 'lib/ContentfulData';

import { connect } from 'react-redux';

import get from 'utils/get';
import withNavThemeContext from 'lib/withNavThemeContext';

class StoriesShowContainer extends ContainerBase {
  view = import('views/StoriesShowView');

  model = () => {
    const {
      activeLocale,
      match: {
        params: { storySlug }
      }
    } = this.props;

    return ContentfulData.getEntries({
      content_type: 'story',
      'fields.slug': storySlug,
      include: 2,
      locale: activeLocale
    }).then(res => res.items[0]);
  };

  afterModel = model => {
    this.props.setNavTheme(get(model, 'fields.headerTheme'));
  };
}

const mapStateToProps = state => {
  return {
    activeLocale: get(state, 'locale.activeLocale')
  };
};

export default connect(mapStateToProps)(
  withNavThemeContext(StoriesShowContainer)
);
