import React, { Component } from 'react';

import { DeviceWidthContext } from 'context/DeviceWidthContext';

const withDeviceWidthContext = WrappedComponent => {
  /* eslint-disable react/prefer-stateless-function */
  class WithDeviceWidthContextComponent extends Component {
    render() {
      return (
        <DeviceWidthContext.Consumer>
          {context => (
            <WrappedComponent {...this.props} isMobile={context.isMobile} />
          )}
        </DeviceWidthContext.Consumer>
      );
    }
  }
  /* eslint-enable */

  return WithDeviceWidthContextComponent;
};

export default withDeviceWidthContext;
