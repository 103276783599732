import { put, select } from 'redux-saga/effects';
import { Status, createNewOrder, currentLocation } from 'brandibble-redux';
import { PICKUP, CREDIT } from 'constants/Brandibble';
import get from 'utils/get';

const { PENDING, FULFILLED, REJECTED } = Status;

export const ON_UNAUTHENTICATION_SAGA = 'ON_UNAUTHENTICATION_SAGA';

function* onUnauthenticationSaga() {
  yield put({ type: `${ON_UNAUTHENTICATION_SAGA}_${PENDING}` });

  const brandibble = yield select(state => state.brandibble);
  const currentOrderLocation = yield select(state =>
    currentLocation(state.brandibble)
  );

  const brandibbleRef = get(brandibble, 'ref');
  const locationId = get(currentOrderLocation, 'location_id', null);

  yield put(createNewOrder(brandibbleRef, locationId, PICKUP, CREDIT));
}

/**
 * If all dispatched actions become FULFILLED
 * and ON_UNAUTHENTICATION_SAGA is in a PENDING state
 * we can assume ON_UNAUTHENTICATION_SAGA_FULFILLED
 */

function* handleOnUnauthenticationSagaFulfilled() {
  const onUnauthenticationSagaPending = yield select(
    state => state.status.onUnauthenticationSaga === PENDING
  );
  const createNewOrderFulfilled = yield select(
    state => state.brandibble.status.createNewOrder === FULFILLED
  );

  if (onUnauthenticationSagaPending && createNewOrderFulfilled) {
    yield put({ type: `${ON_UNAUTHENTICATION_SAGA}_${FULFILLED}` });
  }
}

/**
 * If any dispatched actions become REJECTED
 * and ON_UNAUTHENTICATION_SAGA is in a PENDING state
 * we can assume ON_UNAUTHENTICATION_SAGA_REJECTED
 */

function* handleOnUnauthenticationSagaRejected() {
  const onUnauthenticationSagaPending = yield select(
    state => state.status.onUnauthenticationSaga === PENDING
  );

  const createNewOrderRejected = yield select(
    state => state.brandibble.status.createNewOrder === FULFILLED
  );

  if (onUnauthenticationSagaPending && createNewOrderRejected) {
    yield put({ type: `${ON_UNAUTHENTICATION_SAGA}_${REJECTED}` });
  }
}

export {
  handleOnUnauthenticationSagaFulfilled,
  handleOnUnauthenticationSagaRejected
};
export default onUnauthenticationSaga;
