import {
  OPEN_SIDE_CART,
  CLOSE_SIDE_CART
} from 'state/actions/ui/sideCartActions';

const initialState = {
  sideCartIsOpen: false
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case OPEN_SIDE_CART:
      return { ...state, sideCartIsOpen: true };
    case CLOSE_SIDE_CART:
      return { ...state, sideCartIsOpen: false };
    default:
      return state;
  }
};
