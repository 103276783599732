import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'utils/get';
import { getLineItemData } from 'utils/getMenuItemsForLineItems';
import { withRouter } from 'react-router-dom';

import { CATERING } from 'constants/MenuTypes';

import { Button, Text, SmartLink } from 'components/base';
import CartSummaryItemCard from 'components/CartSummaryItemCard';

import v from 'vudu';
import { classes as c, styles as s } from 'styles';

const classes = v({
  cartSummary: {
    '@composes': [s.flex, s.flexColumn, s.height100, s.pb10]
  },
  cartSummaryHeader: {
    '@composes': [s.flexQuarter]
  },
  cartSummaryBody: {
    '@composes': [s.pr5],
    flex: 10,
    overflowY: 'auto'
  },
  locationDetails: {
    '@composes': [s.mb7]
  },
  orderDetails: {
    '@composes': [s.mb7]
  },
  orderCaptcha: {
    '@composes': [s.mb7]
  },
  checkoutDetailsContainer: {
    '@composes': [s.flex, s.alignCenter, s.spaceBetween]
  },
  cartSummaryFooter: {
    '@composes': [s.flexQuarter, s.pt4, s.borderTopGray]
  }
});

class CartSummary extends PureComponent {
  handleCheckout = () => {
    get(this, 'props.closeSideCart', f => f)();
    return get(this, 'props.history.push', f => f)('/checkout');
  };

  renderCartItems = () => {
    const menuItemsForLineItems = get(
      this,
      'props.cartSummaryData.menuItemsForLineItems',
      []
    );
    return get(this, 'props.cartSummaryData.lineItems', []).map(lineItem => {
      const lineItemData = getLineItemData(
        menuItemsForLineItems,
        get(lineItem, 'productData.id')
      );

      return (
        <CartSummaryItemCard
          key={get(lineItem, 'uuid')}
          lineItem={lineItem}
          lineItemData={lineItemData}
        />
      );
    });
  };

  render() {
    const { cartSummaryData, currentOrderMenuPath } = this.props;

    const locationName = get(cartSummaryData, 'locationName');
    const locationCity = get(cartSummaryData, 'locationCity');
    const locationStateCode = get(cartSummaryData, 'locationStateCode');
    const isCateringOrder = get(cartSummaryData, 'menuType') === CATERING;

    if (!cartSummaryData || !get(cartSummaryData, 'lineItems', []).length)
      return null;

    return (
      <div className={classes.cartSummary}>
        <div className={classes.cartSummaryHeader}>
          <div className={classes.locationDetails}>
            <Text className={c.monoBold} variant="label">
              {`Pickup ${isCateringOrder ? 'Preorder' : 'Online Order'} from`}
            </Text>
            <SmartLink
              className={c.hoverOpacity}
              to={currentOrderMenuPath}
              onClick={get(this, 'props.closeSideCart')}
            >
              <Text variant="paragraph">{`${locationName} – ${locationCity}, ${locationStateCode}`}</Text>
            </SmartLink>
          </div>
        </div>
        <div className={classes.cartSummaryBody}>{this.renderCartItems()}</div>
        <div className={classes.cartSummaryFooter}>
          <div className={classes.orderDetails}>
            <Text variant="paragraph">
              A 4% surcharge is included in support of employee benefits.
            </Text>
          </div>
          <div className={classes.checkoutDetailsContainer}>
            <div>
              <Text className={c.monoBold} variant="paragraph">
                Subtotal
              </Text>
              <Text className={c.monoBold} variant="paragraph">
                {`$${get(cartSummaryData, 'cartSubtotal').format()}`}
              </Text>
            </div>
            <Button
              onClick={this.handleCheckout}
              variant="goldBrickSolid"
              text="CHECKOUT"
            />
          </div>
        </div>
      </div>
    );
  }
}

CartSummary.defaultProps = {
  closeSideCart: f => f
};

CartSummary.propTypes = {
  closeSideCart: PropTypes.func
};

export default withRouter(CartSummary);
