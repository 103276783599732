import React from 'react';
import PropTypes from 'prop-types';
import Language from 'constants/Language';

import { Text } from 'components/base';

import v from 'vudu';
import { classes as c, colors, styles as s } from 'styles';

const classes = v({
  selectionHeader: {
    '@composes': [s.flex, s.spaceBetween, s.py4],
    borderBottom: `solid 1px ${colors.darkGray}`
  }
});

const SelectionHeader = ({ title, subtitle, isRequired }) => (
  <div className={classes.selectionHeader}>
    <div>
      <Text variant="paragraph" className={c.monoBold}>
        {title}
      </Text>
      {!!subtitle && <Text variant="paragraph">{subtitle}</Text>}
    </div>
    <Text variant="paragraph" className={c.monoBold} color="gray">
      {isRequired
        ? Language.t('cateringModal.required')
        : Language.t('cateringModal.optional')}
    </Text>
  </div>
);

SelectionHeader.defaultProps = {
  title: '',
  subtitle: '',
  isRequired: false
};

SelectionHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isRequired: PropTypes.bool
};

export default SelectionHeader;
