const zIndexes = [0, 1, 2, 3, 5, 10];

export const zIndex = int => zIndexes[int - 1];

export default {
  zHeader: { zIndex: zIndex(5) },
  zSubHeader: { zIndex: zIndex(3) },
  zStickyHeader: { zIndex: zIndex(4) },
  zMobileMenu: { zIndex: zIndex(5) },
  zSideCart: { zIndex: zIndex(5) },
  zFlag: { zIndex: zIndex(5) },
  zModal: { zIndex: zIndex(6) }
};
