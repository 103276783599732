import React, { PureComponent } from 'react';
import get from 'utils/get';
import PropTypes from 'prop-types';
import { brandibbleRef, status } from 'constants/PropTypes';

import Language from 'constants/Language';
import { InputTypes, ErrorObjectKeys } from 'constants/Forms';
import { PENDING, FULFILLED } from 'constants/Status';
import {
  handleServerError,
  handleValidationErrorMessage,
  validateInput,
  validateForm
} from 'utils/formUtils';

import TextInput from './TextInput';
import TextArea from './TextArea';
import { Button } from 'components/base';

import v from 'vudu';
import { styles as s, colors } from 'styles';

const { FIRST_NAME, LAST_NAME, EMAIL, PHONE, MESSAGE } = InputTypes;
const { ERROR_MESSAGE, SHOW_ERROR_MESSAGE } = ErrorObjectKeys;

const initialErrorsState = {
  [FIRST_NAME]: {
    [ERROR_MESSAGE]: '',
    [SHOW_ERROR_MESSAGE]: false
  },
  [LAST_NAME]: {
    [ERROR_MESSAGE]: '',
    [SHOW_ERROR_MESSAGE]: false
  },
  [EMAIL]: {
    [ERROR_MESSAGE]: '',
    [SHOW_ERROR_MESSAGE]: false
  },
  [PHONE]: {
    [ERROR_MESSAGE]: '',
    [SHOW_ERROR_MESSAGE]: false
  },
  [MESSAGE]: {
    [ERROR_MESSAGE]: '',
    [SHOW_ERROR_MESSAGE]: false
  }
};

const classes = v({
  message: {
    '@composes': [s.label, s.mb9],
    '::placeholder': {
      color: colors.midGray
    }
  }
});

class SupportForm extends PureComponent {
  constructor(props) {
    super(...arguments);

    this.state = {
      values: {
        [FIRST_NAME]: get(props, 'userAttributes.first_name', ''),
        [LAST_NAME]: get(props, 'userAttributes.last_name', ''),
        [EMAIL]: get(props, 'userAttributes.email', ''),
        [PHONE]: get(props, 'userAttributes.phone', ''),
        [MESSAGE]: ''
      },
      errors: initialErrorsState,
      formIsValid: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.submitError && this.props.submitError) {
      const { values, errors } = this.state;
      handleServerError(this.props.submitError, values, errors, state =>
        this.setState(state)
      );
    }

    if (
      prevProps.submitStatus === PENDING &&
      this.props.submitStatus === FULFILLED
    ) {
      this.resetFormState();
    }
  }

  resetFormState = () => {
    return this.setState({
      values: {
        [FIRST_NAME]: get(this, 'props.userAttributes.first_name', ''),
        [LAST_NAME]: get(this, 'props.userAttributes.last_name', ''),
        [EMAIL]: get(this, 'props.userAttributes.email', ''),
        [PHONE]: get(this, 'props.userAttributes.phone', ''),
        [MESSAGE]: ''
      },
      errors: initialErrorsState,
      formIsValid: false
    });
  };

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      values: { ...prevState.values, [target.name]: target.value }
    }));
  };

  handleBlur = ({ target }) => {
    const { values, errors } = this.state;
    handleValidationErrorMessage(target.name, values, errors, state =>
      this.setState(state)
    );
  };

  handleKeyUp = ({ target }) => {
    validateInput(target.name, this.state.values, this.state.errors, state => {
      this.setState(state);
      this.checkFormIsValid(state);
    });
  };

  checkFormIsValid = nextState => {
    const formIsValid = validateForm(nextState.values, nextState.errors);

    if (formIsValid) {
      return this.setState({ formIsValid: true });
    }

    this.setState({ formIsValid: false });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { onSubmit, brandibbleRef } = this.props;
    const { values } = this.state;
    const customerId = get(this.props, 'userAttributes.customer_id', 'none');

    const footer = `
      --- Brandibble Customer Data ---
      Brandibble ID: ${customerId}
      Name: ${values[FIRST_NAME]} ${values[LAST_NAME]}
      Brandibble Email: ${values[EMAIL]}
      Phone: ${values[PHONE]}
      --- Thanks Customer Support! <3 Sanctuary Computer ---`;

    const data = {
      body: `${values[MESSAGE]} ${footer}`,
      subject: 'Tartine Support',
      email: `${values[EMAIL]}`,
      name: `${values[FIRST_NAME]} ${values[LAST_NAME]}`
    };

    return onSubmit(brandibbleRef, data);
  };

  render() {
    const { values, errors } = this.state;
    const { submitStatus } = this.props;
    const isSubmitting = submitStatus === PENDING;

    return (
      <form onSubmit={this.handleSubmit}>
        <TextInput
          filledOnMount={values[FIRST_NAME] && values[FIRST_NAME].length}
          name={FIRST_NAME}
          label={Language.t('support.firstName')}
          value={values[FIRST_NAME]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={false}
          error={
            errors[FIRST_NAME][SHOW_ERROR_MESSAGE]
              ? errors[FIRST_NAME][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          filledOnMount={values[LAST_NAME] && values[LAST_NAME].length}
          name={LAST_NAME}
          label={Language.t('support.lastName')}
          value={values[LAST_NAME]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={false}
          error={
            errors[LAST_NAME][SHOW_ERROR_MESSAGE]
              ? errors[LAST_NAME][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          filledOnMount={values[EMAIL] && values[EMAIL].length}
          name={EMAIL}
          label={Language.t('support.email')}
          value={values[EMAIL]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={false}
          error={
            errors[EMAIL][SHOW_ERROR_MESSAGE]
              ? errors[EMAIL][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          filledOnMount={values[PHONE] && values[PHONE].length}
          name={PHONE}
          label={Language.t('support.phone')}
          value={values[PHONE]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={false}
          error={
            errors[PHONE][SHOW_ERROR_MESSAGE]
              ? errors[PHONE][ERROR_MESSAGE]
              : ''
          }
        />
        <TextArea
          className={classes.message}
          rows={12}
          name={MESSAGE}
          placeholder={Language.t('support.addSupportMessage')}
          value={values[MESSAGE]}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          error={
            errors[MESSAGE][SHOW_ERROR_MESSAGE]
              ? errors[MESSAGE][ERROR_MESSAGE]
              : ''
          }
        />
        <Button
          showLoading={isSubmitting}
          isDisabled={!this.state.formIsValid}
          onClick={this.handleSubmit}
          text={Language.t('support.submit')}
        />
      </form>
    );
  }
}

SupportForm.defaultProps = {
  onSubmit: f => f
};

SupportForm.propTypes = {
  onSubmit: PropTypes.func,
  submitStatus: status,
  brandibbleRef: brandibbleRef
};

export default SupportForm;
