import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'utils/get';
import { freezeScroll, unfreezeScroll } from 'utils/manageScrollingElement';
import FocusTrap from 'focus-trap-react';

import {
  EDIT_ATTRIBUTE_MODAL,
  EDIT_PASSWORD_MODAL,
  ADD_PAYMENT_METHOD_MODAL,
  CONFIRMATION_MODAL,
  CREATE_ORDER_MODAL,
  INVALID_ITEMS_MODAL,
  LINE_ITEM_CONFIGURATION_MODAL,
  LOCATION_CLOSED_MODAL
} from 'constants/ModalVariants';

import {
  EditAttributeModal,
  EditPasswordModal,
  AddPaymentMethodModal,
  ConfirmationModal,
  CreateOrderModal,
  InvalidItemsModal,
  LineItemConfigurationModal,
  LocationClosedModal
} from 'components/modals';

import Vars from 'constants/Vars';

import cx from 'classnames';
import v from 'vudu';
import { styles as s, colors, breakpoints } from 'styles';

const { modalInnerMaxWidth, modalInnerMaxHeight } = Vars;

const classes = v({
  modal: {
    position: 'fixed',
    opacity: 0,
    pointerEvents: 'none',
    visibility: 'hidden'
  },
  visible: {
    '@composes': [s.t0, s.r0, s.b0, s.l0, s.transitionOpacity, s.zModal],
    position: 'fixed',
    opacity: 1,
    pointerEvents: 'auto',
    visibility: 'visible',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalInner: {
    '@composes': [s.col10, s.relative, s.bgWhite, s.zModal, s.p7],
    maxWidth: `${modalInnerMaxWidth}px`,
    maxHeight: `${modalInnerMaxHeight}px`
  },
  mobileFullCover: {
    '@composes': [
      s.min100vw,
      s.fixed,
      s.l0,
      s.t0,
      s.b0,
      s.r0,
      s.flex,
      s.flexColumn,
      s.p0
    ],
    minHeight: '100%',
    [breakpoints.sm]: {
      '@composes': [s.relative],
      minWidth: 'auto',
      minHeight: 'auto'
    }
  },
  overlay: {
    '@composes': [s.absolute, s.col12, s.height100],
    backgroundColor: colors.gray,
    opacity: 0.75
  }
});

class Modal extends PureComponent {
  componentDidUpdate(prevProps) {
    const wasVisible = get(prevProps, 'isVisible');
    const isVisible = get(this, 'props.isVisible');

    if (!wasVisible && isVisible) {
      freezeScroll();
    }

    if (wasVisible && !isVisible) {
      unfreezeScroll();
    }
  }

  renderModalInner = () => {
    const variant = get(this, 'props.variant');
    const data = get(this, 'props.data');

    switch (variant) {
      case EDIT_ATTRIBUTE_MODAL:
        return <EditAttributeModal data={data} />;
      case EDIT_PASSWORD_MODAL:
        return <EditPasswordModal />;
      case ADD_PAYMENT_METHOD_MODAL:
        return <AddPaymentMethodModal />;
      case CONFIRMATION_MODAL:
        return <ConfirmationModal data={data} />;
      case CREATE_ORDER_MODAL:
        return <CreateOrderModal />;
      case INVALID_ITEMS_MODAL:
        return <InvalidItemsModal data={data} />;
      case LINE_ITEM_CONFIGURATION_MODAL:
        return <LineItemConfigurationModal lineItem={get(data, 'lineItem')} />;
      case LOCATION_CLOSED_MODAL:
        return <LocationClosedModal data={data} />;
      default:
        return null;
    }
  };

  handleResetModal = () => {
    const variant = get(this, 'props.variant');
    const resetModal = get(this, 'props.resetModal');
    const removeLineItemAndCloseConfigurationModal = get(
      this,
      'props.removeLineItemAndCloseConfigurationModal'
    );

    /**
     * The majority of modals should be dismissable
     * by clicking/touching the modal overlay
     * except for:
     *
     * 1. INVALID_ITEMS_MODAL
     * which should redirect in the case that the user
     * does not choose to remove invalid items from their cart.
     * This is handled by the cancel event handler in
     * the invalid items modal component
     *
     * 2. LINE_ITEM_CONFIGURATION_MODAL
     * which should remove the configurable item
     * in the case the user chooses to cancel.
     * This is handled by the cancel event handler
     * in the line item configuration modal
     */

    switch (variant) {
      case LINE_ITEM_CONFIGURATION_MODAL:
        return removeLineItemAndCloseConfigurationModal(
          get(this, 'props.data.lineItem')
        );
      case INVALID_ITEMS_MODAL:
        return false;
      default:
        return resetModal();
    }
  };

  isMobileFullCover = () => {
    const variant = get(this, 'props.variant');

    switch (variant) {
      case LINE_ITEM_CONFIGURATION_MODAL:
        return true;
      default:
        return false;
    }
  };

  render() {
    const isVisible = get(this, 'props.isVisible');

    return (
      <FocusTrap
        active={isVisible}
        focusTrapOptions={{
          escapeDeactivates: false,
          returnFocusOnDeactivate: true
        }}
      >
        <div className={cx(classes.modal, { [classes.visible]: isVisible })}>
          <div
            className={cx(classes.modalInner, {
              [classes.mobileFullCover]: this.isMobileFullCover()
            })}
          >
            {this.renderModalInner()}
          </div>
          <div className={classes.overlay} onClick={this.handleResetModal} />
        </div>
      </FocusTrap>
    );
  }
}

Modal.defaultProps = {
  isVisible: false,
  variant: '',
  data: {},
  resetModal: f => f
};

Modal.propTypes = {
  isVisible: PropTypes.bool,
  variant: PropTypes.string,
  data: PropTypes.object,
  resetModal: PropTypes.func
};

export default Modal;
