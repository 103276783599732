import ContainerBase from 'lib/ContainerBase';
import get from 'utils/get';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userIsAuthenticated } from 'state/selectors';
import { authenticateUser } from 'brandibble-redux';

import withNavThemeContext from 'lib/withNavThemeContext';
import { DARK } from 'constants/ColorMaps';

class SignInContainer extends ContainerBase {
  view = import('views/SignInView');

  afterModel = () => {
    this.props.setNavTheme(DARK);
  };
}

const mapStateToProps = state => ({
  brandibbleRef: get(state, 'brandibble.ref'),
  authenticateUserStatus: get(state, 'brandibble.status.authenticateUser'),
  authenticateUserError: get(state, 'brandibble.error.authenticateUser'),
  userIsAuthenticated: userIsAuthenticated(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      authenticateUser
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavThemeContext(SignInContainer));
