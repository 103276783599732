import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { classes as c, format as f } from 'styles';

const Button = ({
  variant,
  highlight,
  text,
  className,
  isDisabled,
  disabledClassName,
  type,
  showLoading,
  onClick,
  onKeyDown
}) => (
  <button
    onClick={onClick}
    onKeyDown={onKeyDown}
    className={cx(className, c[variant], c.pointer, {
      [disabledClassName]: isDisabled || showLoading
    })}
    type={type}
    disabled={isDisabled}
  >
    <span>
      {showLoading ? (
        'LOADING...'
      ) : (
        <div className={f(c.flex, c.flexColumn)}>
          {!!highlight && <span className={c.bold}>{highlight}</span>}
          <span>{text}</span>
        </div>
      )}
    </span>
  </button>
);

Button.defaultProps = {
  className: '',
  isDisabled: false,
  disabledClassName: `${c.disabled}`,
  variant: 'goldBrick',
  type: 'submit',
  showLoading: false,
  onClick: f => f,
  onKeyDown: f => f
};

Button.propTypes = {
  className: PropTypes.string,
  disabledClassName: PropTypes.string,
  isDisabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  showLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func
};

export default Button;
