import { combineReducers } from 'redux';
import { reducer as brandibble } from 'brandibble-redux';

import status from 'state/reducers/status';
import locale from 'state/reducers/locale';

import reorder from 'state/reducers/reorder';

import mobileMenu from 'state/reducers/ui/mobileMenu';
import locationsFinder from 'state/reducers/ui/locationsFinder';
import header from 'state/reducers/ui/header';
import modal from 'state/reducers/ui/modal';
import sideCart from 'state/reducers/ui/sideCart';

import global from 'state/reducers/contentful/global';

const reducers = combineReducers({
  brandibble,
  status,
  locale,
  global,
  mobileMenu,
  locationsFinder,
  header,
  modal,
  sideCart,
  reorder
});

export default reducers;
