import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { link, linkWithSublinks } from 'constants/PropTypes';
import Language from 'constants/Language';
import { freezeScroll, unfreezeScroll } from 'utils/manageScrollingElement';
import FocusTrap from 'focus-trap-react';

import withDeviceWidthContext from 'lib/withDeviceWidthContext';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeader,
  AccordionItemPanel,
  Text
} from 'components/base';

import MobileMenuItem from 'components/MobileMenuItem';
import MobileMenuSubItem from 'components/MobileMenuSubItem';

import cx from 'classnames';
import v from 'vudu';
import { styles as s, colors } from 'styles';

import get from 'utils/get';

const classes = v({
  mobileMenu: {
    '@composes': [
      s.zMobileMenu,
      s.fixed,
      s.t0,
      s.r0,
      s.b0,
      s.l0,
      s.bgWhite,
      s.transitionTransform
    ],
    transform: 'translateX(-100%)',
    opacity: 1,
    visibilty: 'hidden',
    pointerEvents: 'none',
    overflow: 'auto'
  },
  mobileMenuIsOpen: {
    transform: 'translateX(0)',
    opacity: 1,
    visibilty: 'visible',
    pointerEvents: 'auto'
  },
  close: {
    '@composes': [s.col12, s.leftAlign, s.pointer, s.px7, s.py9]
  },
  navContainer: {
    '@composes': [s.px7],
    overflow: 'auto'
  },
  accordionItem: {
    '@composes': [s.block],
    ':not(:last-of-type)': {
      borderBottom: `solid 1px ${colors.gold}`
    }
  },
  accordionItemPanelInner: {
    '@composes': [s.py4]
  }
});

class MobileMenu extends Component {
  componentDidUpdate(prevProps) {
    const { mobileMenuIsOpen } = this.props;

    if (!prevProps.mobileMenuIsOpen && mobileMenuIsOpen) {
      freezeScroll();
    }

    if (prevProps.mobileMenuIsOpen && !mobileMenuIsOpen) {
      unfreezeScroll();
    }

    if (mobileMenuIsOpen) {
      if (!!prevProps.isMobile && !this.props.isMobile) {
        this.props.closeMobileMenu();
      }
    }
  }

  renderAccordionItems = mobileMenuData => {
    return mobileMenuData.map((item, index) => {
      if (!get(item, 'links', false)) {
        return (
          <AccordionItem
            className={classes.accordionItem}
            key={index}
            label={item.label}
          >
            <AccordionItemHeader tabIndex={-1}>
              <MobileMenuItem
                label={item.label}
                hasSubLinks={!!get(item, 'links', []).length}
                path={item.path}
                closeMobileMenu={this.props.closeMobileMenu}
              />
            </AccordionItemHeader>
          </AccordionItem>
        );
      }

      return (
        <AccordionItem
          className={classes.accordionItem}
          key={index}
          label={item.label}
        >
          <AccordionItemHeader>
            <MobileMenuItem
              label={item.label}
              hasSubLinks={!!get(item, 'links', []).length}
              path={item.path}
            />
          </AccordionItemHeader>
          <AccordionItemPanel>
            <div className={classes.accordionItemPanelInner}>
              <MobileMenuSubItem
                closeMobileMenu={this.props.closeMobileMenu}
                subLinks={get(item, 'links', [])}
              />
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      );
    });
  };

  render() {
    const { mobileMenuIsOpen, closeMobileMenu, mobileMenuData } = this.props;

    const mobileMenuClasses = cx(classes.mobileMenu, {
      [classes.mobileMenuIsOpen]: mobileMenuIsOpen
    });

    if (!mobileMenuData || !mobileMenuData.length) return null;

    return (
      <FocusTrap
        active={mobileMenuIsOpen}
        focusTrapOptions={{
          escapeDeactivates: false,
          returnFocusOnDeactivate: true
        }}
      >
        <div className={mobileMenuClasses}>
          <button className={classes.close} onClick={closeMobileMenu}>
            <Text variant="cta">{Language.t('mobileMenu.close')}</Text>
          </button>
          <nav className={classes.navContainer}>
            <Accordion closeAll={!mobileMenuIsOpen}>
              {this.renderAccordionItems(mobileMenuData)}
            </Accordion>
          </nav>
        </div>
      </FocusTrap>
    );
  }
}

MobileMenu.propTypes = {
  mobileMenuIsOpen: PropTypes.bool.isRequired,
  closeMobileMenu: PropTypes.func.isRequired,
  mobileMenuData: PropTypes.arrayOf(
    PropTypes.oneOfType([link, linkWithSublinks])
  ).isRequired
};

export default withDeviceWidthContext(MobileMenu);
