import ContainerBase from 'lib/ContainerBase';
import get from 'utils/get';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createAndAuthenticateUser, Status } from 'brandibble-redux';
import { userIsAuthenticated } from 'state/selectors';

import triggerToast, { SUCCESS, ERROR } from 'utils/triggerToast';

import withNavThemeContext from 'lib/withNavThemeContext';
import Language from 'constants/Language';
import { DARK } from 'constants/ColorMaps';

class SignUpContainer extends ContainerBase {
  view = import('views/SignUpView');

  componentDidUpdate(prevProps) {
    if (
      get(prevProps, 'createAndAuthenticateUserStatus') === Status.PENDING &&
      get(this, 'props.createAndAuthenticateUserStatus') === Status.FULFILLED
    ) {
      const { history } = this.props;

      triggerToast(Language.t('successMessages.toast.signUp'), SUCCESS);
      return history.push('/profile');
    }

    if (
      get(prevProps, 'createAndAuthenticateUserStatus') === Status.PENDING &&
      get(this, 'props.createAndAuthenticateUserStatus') === Status.REJECTED
    ) {
      triggerToast(Language.t('errorMessages.toast.signUp'), ERROR);
    }
  }

  redirect = () => {
    const { history } = this.props;

    if (get(this, 'props.userIsAuthenticated')) {
      return history.push('/profile');
    }
  };

  afterModel = () => {
    this.props.setNavTheme(DARK);
  };
}

const mapStateToProps = state => ({
  brandibbleRef: get(state, 'brandibble.ref'),
  userIsAuthenticated: userIsAuthenticated(state),
  createAndAuthenticateUserStatus: get(
    state,
    'brandibble.status.createAndAuthenticateUser'
  ),
  createAndAuthenticateUserError: get(
    state,
    'brandibble.error.createAndAuthenticateUser'
  )
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createAndAuthenticateUser
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavThemeContext(SignUpContainer));
