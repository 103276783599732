import { createSelector } from 'reselect';
import { allCustomerOrders } from 'state/selectors';
import { initialPageNumber, maxPerPage } from 'constants/Pagination';

export default createSelector(
  state => allCustomerOrders(state),
  allCustomerOrders => {
    const paginatedOrderData = {};

    if (!allCustomerOrders.length) return paginatedOrderData;
    paginatedOrderData[initialPageNumber] = [];

    if (allCustomerOrders.length <= maxPerPage) {
      paginatedOrderData[initialPageNumber] = [...allCustomerOrders];
      return paginatedOrderData;
    }

    let pageNumber = initialPageNumber;

    for (let i = 0; i < allCustomerOrders.length; i++) {
      if (i > 0 && i % maxPerPage === 0) {
        pageNumber += 1;
        paginatedOrderData[pageNumber] = [];
      }
      paginatedOrderData[pageNumber].push(allCustomerOrders[i]);
    }

    return paginatedOrderData;
  }
);
