import Language from 'constants/Language';

const InputTypes = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  PHONE: 'phone',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirm_password',
  CREDIT_CARD_NUMBER: 'cc_number',
  CREDIT_CARD_EXPIRATION: 'cc_expiration',
  CREDIT_CARD_CVV: 'cc_cvv',
  CREDIT_CARD_ZIP_CODE: 'cc_zip',
  MESSAGE: 'message',
  INSTRUCTIONS: 'instructions',
  NOTES_FOR_STORE: 'notes_for_store',
  TIP: 'tip'
};

const ErrorObjectKeys = {
  ERROR_MESSAGE: 'errorMessage',
  SHOW_ERROR_MESSAGE: 'showErrorMessage'
};

const ErrorMessages = {
  INVALID_FIRST_NAME: Language.t('errorMessages.forms.invalidFirstName'),
  INVALID_LAST_NAME: Language.t('errorMessages.forms.invalidLastName'),
  INVALID_EMAIL: Language.t('errorMessages.forms.invalidEmail'),
  INVALID_PHONE_NUMBER: Language.t('errorMessages.forms.invalidPhoneNumber'),
  INVALID_PASSWORD: Language.t('errorMessages.forms.invalidPassword'),
  INVALID_PASSWORD_CONFIRMATION: Language.t(
    'errorMessages.forms.invalidPasswordConfirmation'
  ),
  INVALID_CREDIT_CARD_NUMBER: Language.t(
    'errorMessages.forms.invalidCreditCardNumber'
  ),
  INVALID_CREDIT_CARD_EXPIRATION: Language.t(
    'errorMessages.forms.invalidCreditCardExpiration'
  ),
  INVALID_CREDIT_CARD_CVV: Language.t(
    'errorMessages.forms.invalidCreditCardCVV'
  ),
  INVALID_CREDIT_CARD_ZIP_CODE: Language.t(
    'errorMessages.forms.invalidCreditCardZipCode'
  ),
  INVALID_MESSAGE: Language.t('errorMessages.forms.invalidMessage')
};

export { InputTypes, ErrorObjectKeys, ErrorMessages };
