import localforage from 'localforage';

const loadState = () =>
  localforage.getItem('state').then(res => {
    if (res) return JSON.parse(res);
    return null;
  });

const saveState = state => {
  const serializedState = JSON.stringify(state);
  return localforage.setItem('state', serializedState);
};

export { loadState, saveState };
