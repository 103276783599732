export const RESET_LOCATIONS_FINDER = 'RESET_LOCATIONS_FINDER';
export const COLLAPSE_HEADER = 'COLLAPSE_HEADER';

export const SELECT_CITY = 'SELECT_CITY';
export const DESELECT_CITY = 'DESELECT_CITY';

export const SET_HOVERED_FEATURE_LIST_INDEX = 'SET_HOVERED_FEATURE_LIST_INDEX';
export const UNSET_HOVERED_FEATURE_LIST_INDEX =
  'UNSET_HOVERED_FEATURE_LIST_INDEX';

export const resetLocationsFinder = () => ({
  type: RESET_LOCATIONS_FINDER
});

export const collapseHeader = () => ({
  type: COLLAPSE_HEADER
});

export const selectCity = cityName => ({
  type: SELECT_CITY,
  payload: cityName
});

export const deselectCity = () => ({
  type: DESELECT_CITY
});

export const setHoveredFeatureListIndex = listIndex => ({
  type: SET_HOVERED_FEATURE_LIST_INDEX,
  payload: listIndex
});

export const unsetHoveredFeatureListIndex = () => ({
  type: UNSET_HOVERED_FEATURE_LIST_INDEX
});
