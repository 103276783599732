import v from 'vudu';
import colors from './colors';
import grid, { breakpoints, offsets } from './grid';
import layout from './layout';
import mixins from './mixins';
import shadows from './shadows';
import type, { fontClasses } from './type';
import utils from './utils';
import transitions from './transitions';
import aspectRatio, { ratio } from './aspectRatios';
import zIndexes, { zIndex } from './zIndexes';
import { size, spacing } from './sizing';
import buttonVariants from './buttons';
import { hover } from './animations';

/* 
  Builds @fontface
*/

const fonts = [
  {
    name: 'PitchSans',
    file: 'PitchSansWeb-Light',
    weight: 200,
    style: 'normal'
  },
  {
    name: 'PitchSans',
    file: 'PitchSansWeb-LightItalic',
    weight: 200,
    style: 'italic'
  },
  {
    name: 'PitchSans',
    file: 'PitchSansWeb-Medium',
    weight: 400,
    style: 'normal'
  },
  {
    name: 'PitchSans',
    file: 'PitchSansWeb-MediumItalic',
    weight: 400,
    style: 'italic'
  },
  {
    name: 'PitchSans',
    file: 'PitchSansWeb-Semibold',
    weight: 500,
    style: 'normal'
  },
  {
    name: 'PitchSans',
    file: 'PitchSansWeb-SemiboldItalic',
    weight: 500,
    style: 'italic'
  },
  {
    name: 'PitchSans',
    file: 'PitchSansWeb-Bold',
    weight: 600,
    style: 'normal'
  },
  {
    name: 'PitchSans',
    file: 'PitchSansWeb-BoldItalic',
    weight: 600,
    style: 'italic'
  }
];

fonts.forEach(f => {
  v.addFontFace({
    fontFamily: f.name,
    src: `url(/assets/fonts/${f.file}.eot?#iefix) format('embedded-opentype'),
          url(/assets/fonts/${f.file}.eot),
          url(/assets/fonts/${f.file}.woff) format('woff'),
          url(/assets/fonts/${f.file}.woff2) format('woff2')`,
    fontWeight: f.weight,
    fontStyle: f.style
  });
});

/* 
  Builds styles for use w/ Vudu's 
  @compose utility
*/

const buildStyles = () =>
  Object.assign(
    v.config({ colors }),
    grid,
    layout,
    offsets,
    mixins,
    shadows,
    type,
    fontClasses,
    spacing,
    utils,
    transitions,
    zIndexes,
    buttonVariants,
    hover
  );

const styles = buildStyles();
const classes = v(styles);

/* 
  Format utility
  for chaining atomic classes
*/

function format(classNames) {
  return Array.isArray(classNames)
    ? classes.join(' ')
    : Array.from(arguments).join(' ');
}

export {
  breakpoints,
  colors,
  classes,
  styles,
  format,
  grid,
  offsets,
  size,
  mixins,
  shadows,
  type,
  fontClasses,
  spacing,
  utils,
  transitions,
  aspectRatio,
  ratio,
  zIndexes,
  zIndex,
  buttonVariants,
  hover
};
