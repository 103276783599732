import ContentfulData from 'lib/ContentfulData';

export const FETCH_GLOBAL_DATA = 'FETCH_GLOBAL_DATA';

export const fetchGlobalData = (locale = 'en-US') => dispatch =>
  dispatch({
    type: FETCH_GLOBAL_DATA,
    payload: ContentfulData.getEntries({
      content_type: 'global',
      include: 4,
      locale
    }).then(res => res.items[0])
  });
