import ContainerBase from 'lib/ContainerBase';
import get from 'utils/get';
import Language from 'constants/Language';
import triggerToast, { SUCCESS, ERROR } from 'utils/triggerToast';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  authenticateUser,
  bindCustomerToOrder,
  unauthenticateUser,
  validateCurrentCart,
  validateCurrentOrder,
  setPaymentMethod,
  setDefaultPayment,
  paymentsAsArray,
  fetchPayments,
  createPayment,
  submitOrder,
  setMiscOptions,
  setTip,
  Status
} from 'brandibble-redux';
import { setModal } from 'state/actions/ui/modalActions';
import {
  checkoutSummaryData,
  userIsAuthenticated,
  canSubmitOrder,
  orderValidations,
  currentOrderMenuType
} from 'state/selectors';

import withNavThemeContext from 'lib/withNavThemeContext';
import withDeviceWidthContext from 'lib/withDeviceWidthContext';
import { DARK } from 'constants/ColorMaps';

const BASE_MODEL_COUNT = 2;

class CheckoutContainer extends ContainerBase {
  view = import('views/CheckoutView');

  redirect = () => {
    const orderData = get(this, 'props.orderData');
    const goBack = get(this, 'props.history.goBack', f => f);

    if (!orderData.cart.length) {
      return goBack();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (
      get(prevProps, 'setDefaultPaymentStatus') === Status.PENDING &&
      get(this, 'props.setDefaultPaymentStatus') === Status.FULFILLED
    ) {
      triggerToast(
        Language.t('successMessages.toast.setDefaultPaymentMethod'),
        SUCCESS
      );
    }

    if (
      get(prevProps, 'authenticateUserStatus') === Status.PENDING &&
      get(this, 'props.authenticateUserStatus') === Status.FULFILLED
    ) {
      this.reloadModel();
    }

    const push = get(this, 'props.history.push', f => f);

    // Line items data
    const prevLineItemsData = get(prevProps, 'lineItemsData');
    const lineItemsChanged = !_isEqual(
      prevLineItemsData,
      get(this, 'props.lineItemsData')
    );

    // Bound customer
    const bindCustomerToOrderChanged =
      get(prevProps, 'bindCustomerToOrderStatus') === Status.PENDING &&
      get(this, 'props.bindCustomerToOrderStatus') === Status.FULFILLED;

    // Payment method was updated
    const setPaymentMethodChanged =
      get(prevProps, 'setPaymentMethodStatus') === Status.PENDING &&
      get(this, 'props.setPaymentMethodStatus') === Status.FULFILLED;

    const shouldValidateOrder =
      (get(this, 'props.validateCurrentCartStatus') !== Status.PENDING &&
        lineItemsChanged) ||
      bindCustomerToOrderChanged ||
      setPaymentMethodChanged;

    if (shouldValidateOrder) {
      this.validateOrder();
    }

    // Order was submitted
    const submitOrderFulfilled =
      get(prevProps, 'submitOrderStatus') === Status.PENDING &&
      get(this, 'props.submitOrderStatus') === Status.FULFILLED;

    if (submitOrderFulfilled) {
      const orderId = get(this, 'props.recentOrderSubmissionId');

      return push(`/orders/${orderId}`);
    }

    // Order was rejected
    const submitOrderRejected =
      get(prevProps, 'submitOrderStatus') === Status.PENDING &&
      get(this, 'props.submitOrderStatus') === Status.REJECTED;

    if (submitOrderRejected) {
      get(this, 'props.submitOrderError.errors', []).forEach(error => {
        triggerToast(
          get(error, 'detail', 'An unexpected error occurred.'),
          ERROR
        );
      });
    }
  }

  validateOrder = () => {
    const { actions } = this.props;
    const brandibbleRef = get(this, 'props.brandibbleRef');

    return actions
      .validateCurrentOrder(brandibbleRef, null, { apiVersion: 'v2' })
      .catch(response => {
        if (
          get(get(response, 'errors', [])[0], 'code', '') ===
          'orders.create.missing_customer'
        ) {
          // This is acceptable
        } else {
          throw response;
        }
      });
  };

  validateCart = () => {
    const validateCurrentCart = get(
      this,
      'props.actions.validateCurrentCart',
      f => f
    );

    const brandibbleRef = get(this, 'props.brandibbleRef');

    return validateCurrentCart(brandibbleRef);
  };

  model = () => {
    const userIsAuthenticated = get(this, 'props.userIsAuthenticated');
    const brandibbleRef = get(this, 'props.brandibbleRef');
    const orderRef = get(this, 'props.orderRef');
    const userAttributes = get(this, 'props.userAttributes', {});

    const fetchPayments = get(this, 'props.actions.fetchPayments', f => f);
    const bindCustomerToOrder = get(
      this,
      'props.actions.bindCustomerToOrder',
      f => f
    );

    const promises = [this.validateCart(), this.validateOrder()];

    if (userIsAuthenticated) {
      promises.push(bindCustomerToOrder(orderRef, userAttributes));
      promises.push(fetchPayments(brandibbleRef));
    }
    return Promise.all(promises);
  };

  afterModel = model => {
    // If the model returns
    // more than the base model count
    // we can assume the user is authenticated
    // and can setup any necessary payment data
    // returned via the model
    if (model.length > BASE_MODEL_COUNT) {
      const payments = get(model, '[3].value');
      const orderCreditCard = get(this, 'props.orderData.credit_card', {});

      const orderHasPaymentMethod = !_isEmpty(orderCreditCard);
      if (!orderHasPaymentMethod) {
        const setPaymentMethod = get(
          this,
          'props.actions.setPaymentMethod',
          f => f
        );
        const setDefaultPayment = get(
          this,
          'props.action.setDefaultPayment',
          f => f
        );
        const brandibbleRef = get(this, 'props.brandibbleRef');
        const orderRef = get(this, 'props.orderRef');
        const paymentType = get(this, 'props.orderData.payment_type', 'credit');
        const defaultPaymentMethod = payments.find(payment =>
          get(payment, 'is_default', false)
        );

        // If the user has a default payment set
        // we'll set that on the order
        if (defaultPaymentMethod) {
          setPaymentMethod(orderRef, paymentType, defaultPaymentMethod);
        } else {
          // Otherwise we set the first payment method we find
          // to default, trigger it being set on the payment
          // via the ON_SET_DEFAULT_PAYMENT_SAGA
          const firstPaymentMethodId = get(payments, '[0].customer_card_id');
          setDefaultPayment(brandibbleRef, firstPaymentMethodId);
        }
      }
    }

    this.props.setNavTheme(DARK);
  };
}

const mapStateToProps = state => ({
  brandibbleRef: get(state, 'brandibble.ref'),
  orderRef: get(state, 'brandibble.session.order.ref'),
  orderData: get(state, 'brandibble.session.order.orderData'),
  lineItemsData: get(state, 'brandibble.session.order.lineItemsData'),
  userAttributes: get(state, 'brandibble.user.attributes'),
  validateCurrentCartStatus: get(
    state,
    'brandibble.status.validateCurrentCart'
  ),
  validateCurrentOrderStatus: get(
    state,
    'brandibble.status.validateCurrentOrder'
  ),
  bindCustomerToOrderStatus: get(
    state,
    'brandibble.status.bindCustomerToOrder'
  ),
  authenticateUserStatus: get(state, 'brandibble.status.authenticateUser'),
  authenticateUserError: get(state, 'brandibble.error.authenticateUser'),
  allPaymentMethods: paymentsAsArray(get(state, 'brandibble')),
  setPaymentMethodStatus: get(state, 'brandibble.status.setPaymentMethod'),
  setPaymentMethodError: get(state, 'brandibble.error.setPaymentMethod'),
  setDefaultPaymentStatus: get(state, 'brandibble.status.setDefaultPayment'),
  createPaymentStatus: get(state, 'brandibble.status.createPayment'),
  createPaymentError: get(state, 'brandibble.error.createPayment'),
  checkoutSummaryData: checkoutSummaryData(state),
  userIsAuthenticated: userIsAuthenticated(state),
  canSubmitOrder: canSubmitOrder(state),
  submitOrderStatus: get(state, 'brandibble.status.submitOrder'),
  submitOrderError: get(state, 'brandibble.error.submitOrder'),
  recentOrderSubmissionId: get(
    state,
    'brandibble.data.customerOrders.recentSubmission.orders_id'
  ),
  orderValidations: orderValidations(state),
  currentOrderMenuType: currentOrderMenuType(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      bindCustomerToOrder,
      validateCurrentCart,
      validateCurrentOrder,
      authenticateUser,
      unauthenticateUser,
      setPaymentMethod,
      createPayment,
      setDefaultPayment,
      fetchPayments,
      setModal,
      submitOrder,
      setMiscOptions,
      setTip
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavThemeContext(withDeviceWidthContext(CheckoutContainer)));
