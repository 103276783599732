import { createSelector } from 'reselect';
import get from 'utils/get';

import { CATERING, OLO } from 'constants/MenuTypes';

import { allLocations } from 'state/selectors';

export default createSelector(
  state => get(state, 'brandibble.session.order.orderData', []),
  state => allLocations(state),
  (orderData, allLocations) => {
    const currentOrderLocationId = get(orderData, 'location_id');

    if (!currentOrderLocationId) return null;

    const cateringLocation = allLocations.find(
      location =>
        location.brandibbleCateringLocationId === currentOrderLocationId
    );

    return cateringLocation ? CATERING : OLO;
  }
);
