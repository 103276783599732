import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { status, brandibbleRef } from 'constants/PropTypes';
import get from 'utils/get';

import Language from 'constants/Language';
import { PENDING } from 'constants/Status';
import { ErrorObjectKeys } from 'constants/Forms';
import {
  handleServerError,
  handleValidationErrorMessage,
  validateInput,
  validateForm
} from 'utils/formUtils';

import { Button } from 'components/base';
import TextInput from './TextInput';

import v from 'vudu';
import { breakpoints, classes as c, styles as s } from 'styles';

const { ERROR_MESSAGE, SHOW_ERROR_MESSAGE } = ErrorObjectKeys;

const classes = v({
  modalButtonsContainer: {
    '@composes': [s.flex, s.alignCenter, s.spaceBetween],

    [breakpoints.md]: {
      '@composes': [s.spaceAround]
    }
  }
});

class EditAttributeForm extends PureComponent {
  constructor(props) {
    super(...arguments);

    this.state = {
      values: {
        [props.attributeType]: get(props, 'attributeValue', '')
      },
      errors: {
        [props.attributeType]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        }
      },
      formIsValid: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.submitError && this.props.submitError) {
      const { values, errors } = this.state;
      return handleServerError(this.props.submitError, values, errors, state =>
        this.setState(state)
      );
    }
  }

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      values: { ...prevState.values, [target.name]: target.value }
    }));
  };

  handleBlur = ({ target }) => {
    const { values, errors } = this.state;
    handleValidationErrorMessage(target.name, values, errors, state =>
      this.setState(state)
    );
  };

  handleKeyUp = ({ target }) => {
    validateInput(target.name, this.state.values, this.state.errors, state => {
      this.setState(state);
      this.checkFormIsValid(state);
    });
  };

  checkFormIsValid = nextState => {
    const formIsValid = validateForm(nextState.values, nextState.errors);

    if (formIsValid) {
      return this.setState({ formIsValid: true });
    }

    this.setState({ formIsValid: false });
  };

  handleSubmit = event => {
    event.preventDefault();

    const attributeValue = this.state.values[this.props.attributeType];

    const { attributeType, customerId, brandibbleRef, onSubmit } = this.props;

    return onSubmit(brandibbleRef, customerId, {
      [attributeType]: attributeValue
    });
  };

  render() {
    const { values, errors } = this.state;
    const { submitStatus, attributeType, attributeLabel } = this.props;
    const isSubmitting = submitStatus === PENDING;

    return (
      <form onSubmit={this.handleSubmit}>
        <TextInput
          name={attributeType}
          label={attributeLabel}
          value={values[attributeType]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          error={
            errors[attributeType][SHOW_ERROR_MESSAGE]
              ? errors[attributeType][ERROR_MESSAGE]
              : ''
          }
        />
        <div className={classes.modalButtonsContainer}>
          <Button
            onClick={get(this, 'props.resetModal')}
            variant="basicText"
            text={Language.t('editAttributeForm.cancel')}
            type="button"
          />
          <Button
            showLoading={isSubmitting}
            isDisabled={!this.state.formIsValid}
            disabledClassName={c.basicTextDisabled}
            type="submit"
            variant="basicText"
            text={Language.t('editAttributeForm.confirm')}
          />
        </div>
      </form>
    );
  }
}

EditAttributeForm.defaultProps = {
  onSubmit: f => f,
  resetModal: f => f
};

EditAttributeForm.propTypes = {
  onSubmit: PropTypes.func,
  resetModal: PropTypes.func,
  submitStatus: status,
  brandibbleRef: brandibbleRef
};

export default EditAttributeForm;
