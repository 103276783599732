import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { locale } from 'constants/PropTypes';

import Language from 'constants/Language';
import get from 'utils/get';

import v from 'vudu';
import { colors, styles as s, format as f } from 'styles';

const classes = v({
  localeSelect: {
    '@composes': [s.footerNavItem, s.pointer]
  },
  activeClass: {
    borderBottom: `solid 1px ${colors.darkGray}`
  }
});

const setActiveLocaleAndReload = (setActiveLocale, localeCode) => {
  setActiveLocale(localeCode).then(() => {
    window.location.reload();
  });
};

const LocaleSelector = ({ locales, activeLocale, setActiveLocale }) => {
  if (!locales && !locales.length) return null;

  return locales.map((locale, index) => {
    const localeCode = get(locale, 'code');
    const localeIsActive = localeCode === activeLocale;
    const notLastLocale = index !== locales.length - 1;

    return (
      <Fragment key={get(locale, 'sys.id')}>
        <button
          type="button"
          onClick={() => setActiveLocaleAndReload(setActiveLocale, localeCode)}
          className={f(
            classes.localeSelect,
            localeIsActive && classes.activeClass
          )}
        >
          {Language.t(`locales.${localeCode}`)}
        </button>
        {notLastLocale && ' / '}
      </Fragment>
    );
  });
};

LocaleSelector.defaultProps = {
  setActiveLocale: f => f,
  activeLocale: '',
  locale: []
};

LocaleSelector.propTypes = {
  setActiveLocale: PropTypes.func,
  activeLocale: PropTypes.string,
  locales: PropTypes.arrayOf(locale)
};

export default LocaleSelector;
