/* 
  Brandibble (Open Tender ) config for production and sandbox environment
 */
const brandibbleProductionKey = process.env.REACT_APP_BRANDIBBLE_PRODUCTION_KEY;
const brandibbleSandboxKey = process.env.REACT_APP_BRANDIBBLE_SANDBOX_KEY;
const brandibbleProductionEndpoint =
  process.env.REACT_APP_BRANDIBBLE_PRODUCTION_ENDPOINT;
const brandibbleSandboxEndpoint =
  process.env.REACT_APP_BRANDIBBLE_SANDBOX_ENDPOINT;

const production = {
  CONTENTFUL_SPACE_ID: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  CONTENTFUL_HOST: process.env.REACT_APP_CONTENTFUL_HOST,
  CONTENTFUL_ACCESS_TOKEN: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  ORDERING_ENABLED: process.env.REACT_APP_ORDERING_ENABLED,
  I18N_ENABLED: process.env.REACT_APP_I18N_ENABLED,
  BRANDIBBLE_BRAND_ID: process.env.REACT_APP_BRANDIBBLE_BRAND_ID
};

/*
  Determines use of sandbox or production
*/

const USE_BRANDIBBLE_SANDBOX = process.env.REACT_APP_BRANDIBBLE_USE_SANDBOX;
const toExport = production;

toExport.BRANDIBBLE_API_KEY =
  USE_BRANDIBBLE_SANDBOX === 'true'
    ? brandibbleSandboxKey
    : brandibbleProductionKey;
toExport.BRANDIBBLE_ENDPOINT =
  USE_BRANDIBBLE_SANDBOX === 'true'
    ? brandibbleSandboxEndpoint
    : brandibbleProductionEndpoint;

export default toExport;
