import { createSelector } from 'reselect';
import get from 'utils/get';

export default createSelector(
  state => get(state, 'brandibble.session.order.orderData', {}),
  state => get(state, 'brandibble.data.locations', {}),
  (orderData, locations) => {
    const currentOrderLocationId = get(orderData, 'location_id');
    const currentOrderServiceType = get(orderData, 'service_type');
    const locationsById = get(locations, 'locationsById', {});

    if (!currentOrderLocationId) return null;

    const currentOrderLocation = get(
      locationsById,
      `${currentOrderLocationId}`
    );

    return get(currentOrderLocation, `first_times.${currentOrderServiceType}`);
  }
);
