export default {
  deliveryLink: 'https://postmates.com/brand/tartine-bakery',
  shippingLink: 'https://www.goldbelly.com/tartine-bakery',
  siteMaxWidth: 1320,
  formMaxWidth: 560,
  modalFormMaxWidth: 360,
  confirmationModalMaxWidth: 310,
  confirmationModalMinHeight: 260,
  modalInnerMaxWidth: 360,
  modalInnerMaxHeight: 600,
  modalHeaderHeight: 50,
  modalFooterHeight: 140,
  modalFooterButtonHeight: 58,
  smallCardMaxWidth: 450,
  largeCardMaxWidth: 650,
  announcementBarHeight: 62,
  scrollToHeaderHeight: 75,
  scrollToHeaderHeightMobile: 95,
  headerHeight: 72,
  mobileMenuHeaderHeight: 81,
  logoWidth: 133,
  mobileLogoMaxWidth: 113,
  sideCartWidth: 475,
  cartSummaryHeight: 250,
  orderHeaderHeight: 560,
  mobileOrderHeaderHeight: 460,
  cateringHeaderHeight: 500,
  mobileCateringHeaderHeight: 405,
  cateringCardMaxWidth: 800,
  menuStatusMaxWidth: 400,
  jsBreakpoints: {
    sm: 640,
    md: 830,
    lg: 1024
  },
  paragraphLineHeight: 25
};

/* All values in px */
