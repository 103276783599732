import {
  COLLAPSE_HEADER,
  SELECT_CITY,
  DESELECT_CITY,
  SET_HOVERED_FEATURE_LIST_INDEX,
  UNSET_HOVERED_FEATURE_LIST_INDEX,
  RESET_LOCATIONS_FINDER
} from 'state/actions/ui/locationsFinderActions';

const initialState = {
  isHeaderCollapsed: false,
  selectedCity: '',
  hoveredFeatureListIndex: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case COLLAPSE_HEADER:
      return { ...state, isHeaderCollapsed: true };
    case SELECT_CITY:
      return { ...state, selectedCity: payload };
    case DESELECT_CITY:
      return { ...state, selectedCity: initialState.selectedCity };
    case SET_HOVERED_FEATURE_LIST_INDEX:
      return { ...state, hoveredFeatureListIndex: payload };
    case UNSET_HOVERED_FEATURE_LIST_INDEX:
      return {
        ...state,
        hoveredFeatureListIndex: initialState.hoveredFeatureListIndex
      };
    case RESET_LOCATIONS_FINDER:
      return { ...initialState };
    default:
      return state;
  }
};
