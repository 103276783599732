import ContainerBase from 'lib/ContainerBase';
import get from 'utils/get';
import _isEmpty from 'lodash/isEmpty';

import triggerToast, { SUCCESS, ERROR } from 'utils/triggerToast';
import Language from 'constants/Language';
import { PENDING, FULFILLED, REJECTED } from 'constants/Status';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAllCustomerOrders } from 'brandibble-redux';
import {
  allCustomerOrders,
  recentOrder,
  userIsAuthenticated
} from 'state/selectors';

import withNavThemeContext from 'lib/withNavThemeContext';
import { DARK } from 'constants/ColorMaps';

class OrdersShowContainer extends ContainerBase {
  view = import('views/OrdersShowView');

  componentDidUpdate(prevProps) {
    // Triggered by the PastItem components
    // via the withReorderActions HOC
    if (
      get(prevProps, 'attemptReorderStatus') === PENDING &&
      get(this, 'props.attemptReorderStatus') === FULFILLED
    ) {
      triggerToast(Language.t('successMessages.toast.attemptReorder'), SUCCESS);
      this.props.history.push('/checkout');
    }

    if (
      get(prevProps, 'attemptReorderStatus') === PENDING &&
      get(this, 'props.attemptReorderStatus') === REJECTED
    ) {
      triggerToast(Language.t('errorMessages.toast.attemptReorder'), ERROR);
    }
  }

  redirectHome = () => {
    const { history } = this.props;
    return history.push('/');
  };

  model = () => {
    const brandibbleRef = get(this, 'props.brandibbleRef');
    const customerId = get(this, 'props.userAttributes.customer_id');
    const recentOrder = get(this, 'props.recentOrder', {});
    const fetchAllCustomerOrders = get(
      this,
      'props.actions.fetchAllCustomerOrders',
      f => f
    );
    const orderIdAsInt = parseInt(get(this, 'props.match.params.orderId'), 10);
    const withItemDetails = true;

    // If a user is unauthenticated
    // we attempt to find a recent order in state
    // to resolve as the model
    if (!customerId) {
      if (!_isEmpty(recentOrder)) {
        const orderMatches =
          orderIdAsInt === parseInt(get(recentOrder, 'orders_id'), 10);

        if (!orderMatches) {
          return this.redirectHome();
        }

        return Promise.resolve(recentOrder);
      }

      this.redirectHome();
    }

    // If user IS authenticated,
    // we attempt to fetch all their
    // customer orders and match against
    // the order id query param
    return fetchAllCustomerOrders(
      brandibbleRef,
      customerId,
      withItemDetails
    ).then(res => {
      const customerOrders = get(res, 'value.data', []);

      // We return the matched order
      // in the afterModel hook, we redirect
      // if no model is returned (i.e. we couldn't find a match)
      return customerOrders.find(
        customerOrder =>
          orderIdAsInt === parseInt(get(customerOrder, 'orders_id'), 10)
      );
    });
  };

  afterModel = model => {
    if (!model) {
      return this.redirectHome();
    }

    const { setNavTheme } = this.props;
    setNavTheme(DARK);
  };
}

const mapStateToProps = state => ({
  brandibbleRef: get(state, 'brandibble.ref'),
  userAttributes: get(state, 'brandibble.user.attributes'),
  attemptReorderStatus: get(state, 'status.attemptReorder'),
  allCustomerOrders: allCustomerOrders(state),
  recentOrder: recentOrder(state),
  userIsAuthenticated: userIsAuthenticated(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchAllCustomerOrders
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavThemeContext(OrdersShowContainer));
