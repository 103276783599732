import ContentfulData from 'lib/ContentfulData';
import ContentfulClient from 'lib/ContentfulClient';
import { fetchGlobalData } from 'state/actions/contentfulActions';
import { getAndSetInitialLocale } from 'state/actions/localeActions';

export const INITIALIZE_APPLICATION = 'INITIALIZE_APPLICATION';

export const initializeApplication = () => (dispatch, getState) =>
  dispatch({
    type: INITIALIZE_APPLICATION,
    payload: new Promise(resolve => {
      // Setup Contentful
      const contentful = ContentfulClient();
      ContentfulData.setRef(contentful);

      return dispatch(getAndSetInitialLocale()).then(() => {
        const locale = getState().locale.activeLocale;

        dispatch(fetchGlobalData(locale)).then(() => {
          resolve();
        });
      });
    })
  });
