import React from 'react';
import PropTypes from 'prop-types';
import { colors, format as f, styles as s } from 'styles';
import v from 'vudu';

const classes = v({
  input: {
    '@composes': [s.col12],
    ':not([type=checkbox]):not([type=radio])': {
      borderRadius: 0,
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none'
    },
    ':-webkit-autofill': {
      '-webkit-box-shadow': `inset 0 0 0px 9999px ${colors.offwhite}`
    },
    ':focus': {
      outline: 'none'
    }
  }
});

const Input = ({
  placeholder,
  handleChange,
  className,
  id,
  type,
  value,
  name,
  checked
}) => (
  <input
    id={id}
    type={type}
    className={f(classes.input, className)}
    placeholder={placeholder}
    onChange={handleChange}
    value={value}
    name={name}
    aria-label={name}
    checked={checked}
  />
);

Input.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool
};

Input.defaultProps = {
  id: '',
  placeholder: '',
  handleChange: f => f,
  className: '',
  type: '',
  value: '',
  name: '',
  checked: false
};

export default Input;
