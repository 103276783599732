import React, { Component } from 'react';
import get from 'utils/get';

import PageMeta from 'lib/PageMeta';

class ContainerBase extends Component {
  state = {
    view: null,
    model: null,
    error: null
  };

  view = 'Undefined';

  redirect = f => f;
  beforeModel = f => f;
  model = f => f;
  afterModel = f => f;
  activate = f => f;

  reloadModel = () => {
    this.runHooks().then(model => {
      this.activate(model);
      this.setState(prevState => ({ ...prevState, model }));
    });
  };

  runHooks = () => {
    return new Promise(async resolve => {
      let model;
      try {
        await this.beforeModel();
        model = await this.model();
        await this.afterModel(model);
        if (model) model.isError = false;
        else model = { isError: false };
      } catch (error) {
        model = { isError: true, error };
      }
      resolve(model);
    });
  };

  async componentDidMount() {
    this.redirect();
    const [{ default: View }, model] = await Promise.all([
      this.view,
      this.runHooks()
    ]);
    this.activate(model);
    this.setState({ view: View, model });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      get(prevProps, 'location.pathname') !==
      get(this, 'props.location.pathname')
    ) {
      this.redirect();
      const [{ default: View }, model] = await Promise.all([
        this.view,
        this.runHooks()
      ]);
      this.activate(model);
      this.setState({ view: View, model });
    }
  }

  render() {
    const { view: View, model } = this.state;
    return (
      <PageMeta share={get(model, 'fields.share')}>
        {View ? <View model={model} {...this.props} /> : null}
      </PageMeta>
    );
  }
}

export default ContainerBase;
