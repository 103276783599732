import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { brandibbleRef, status } from 'constants/PropTypes';

import Language from 'constants/Language';
import { InputTypes, ErrorObjectKeys } from 'constants/Forms';
import { PENDING } from 'constants/Status';
import {
  handleServerError,
  handleValidationErrorMessage,
  validateInput,
  validateForm
} from 'utils/formUtils';

import { SmartLink, Text, Button } from 'components/base';
import TextInput from './TextInput';

import { classes as c, format as f } from 'styles';

const { EMAIL, PASSWORD } = InputTypes;
const { ERROR_MESSAGE, SHOW_ERROR_MESSAGE } = ErrorObjectKeys;

class SignInForm extends PureComponent {
  constructor(props) {
    super(...arguments);

    this.state = {
      values: {
        [EMAIL]: '',
        [PASSWORD]: ''
      },
      errors: {
        [EMAIL]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        },
        [PASSWORD]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        }
      },
      formIsValid: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.submitError && this.props.submitError) {
      const { values, errors } = this.state;
      return handleServerError(this.props.submitError, values, errors, state =>
        this.setState(state)
      );
    }
  }

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      values: { ...prevState.values, [target.name]: target.value }
    }));
  };

  handleBlur = ({ target }) => {
    const { values, errors } = this.state;
    handleValidationErrorMessage(target.name, values, errors, state =>
      this.setState(state)
    );
  };

  handleKeyUp = ({ target }) => {
    validateInput(target.name, this.state.values, this.state.errors, state => {
      this.setState(state);
      this.checkFormIsValid(state);
    });
  };

  checkFormIsValid = nextState => {
    const formIsValid = validateForm(nextState.values, nextState.errors);

    if (formIsValid) {
      return this.setState({ formIsValid: true });
    }

    this.setState({ formIsValid: false });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { values } = this.state;
    const { onSubmit, brandibbleRef } = this.props;

    return onSubmit(brandibbleRef, values);
  };

  render() {
    const { values, errors } = this.state;
    const { submitStatus, autoComplete } = this.props;
    const isSubmitting = submitStatus === PENDING;

    return (
      <form onSubmit={this.handleSubmit}>
        <TextInput
          name={EMAIL}
          value={values.email}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={autoComplete}
          error={
            errors[EMAIL][SHOW_ERROR_MESSAGE]
              ? errors[EMAIL][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          name={PASSWORD}
          type="password"
          value={values.password}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={autoComplete}
          error={
            errors[PASSWORD][SHOW_ERROR_MESSAGE]
              ? errors[PASSWORD][ERROR_MESSAGE]
              : ''
          }
        />
        <div className={f(c.flex, c.alignCenter, c.spaceBetween)}>
          <SmartLink to="/forgot-password">
            <Text variant="paragraph">
              {Language.t('signInForm.forgotPassword')}
            </Text>
          </SmartLink>
          <Button
            showLoading={isSubmitting}
            isDisabled={!this.state.formIsValid}
            type="submit"
            variant="goldBrick"
            text={Language.t('signInForm.signIn')}
          />
        </div>
      </form>
    );
  }
}

SignInForm.defaultProps = {
  onSubmit: f => f,
  autoComplete: true
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func,
  submitStatus: status,
  brandibbleRef: brandibbleRef,
  autoComplete: PropTypes.bool
};

export default SignInForm;
