import React, { PureComponent } from 'react';
import get from 'utils/get';
import { getOptionItemMapping } from 'utils/getMenuItemsForLineItems';
import Language from 'constants/Language';

import { Text } from 'components/base';
import QuantitySpinner from 'components/QuantitySpinner';
import SelectionHeader from './SelectionHeader';

import v from 'vudu';
import { classes as c, styles as s } from 'styles';

const classes = v({
  multiSelect: {
    '@composes': [s.mb4]
  },
  multiSelectItem: {
    '@composes': [s.flex, s.spaceBetween, s.py4]
  }
});

class MultiSelect extends PureComponent {
  handleAddOptionToLineItem = (optionGroupData, optionItem) => {
    const addOptionToLineItem = get(this, 'props.addOptionToLineItem', f => f);

    return addOptionToLineItem(optionGroupData, optionItem);
  };

  handleRemoveOptionFromLineItem = optionItem => {
    const removeOptionFromLineItem = get(
      this,
      'props.removeOptionFromLineItem',
      f => f
    );

    return removeOptionFromLineItem(optionItem);
  };

  render() {
    const optionGroupMapping = get(this, 'props.optionGroupMapping');
    const optionGroupData = get(this, 'props.optionGroupData');
    const isRequired = get(this, 'props.isRequired');
    const currentlySelectedCount = get(
      optionGroupMapping,
      'currentlySelectedCount'
    );
    const optionItems = get(optionGroupData, 'option_items', []);
    const minOptionsRequired = get(optionGroupData, 'min_options', 0);

    const subtitleText =
      currentlySelectedCount >= 1
        ? `${currentlySelectedCount} selected (Min: ${minOptionsRequired})`
        : `Select at least ${minOptionsRequired}`;

    return (
      <div className={classes.multiSelect}>
        <SelectionHeader
          title={Language.t('cateringModal.makeSelection')}
          subtitle={isRequired && subtitleText}
          isRequired={isRequired}
        />
        <div className={c.my5}>
          {optionItems.map(optionItem => {
            const optionItemMatch = getOptionItemMapping(
              get(optionGroupMapping, 'optionItems', []),
              get(optionItem, 'id')
            );

            return (
              <div className={classes.multiSelectItem}>
                <div>
                  <Text variant="paragraph">{get(optionItem, 'name')}</Text>
                  <Text variant="paragraph">${get(optionItem, 'price')}</Text>
                </div>
                <QuantitySpinner
                  quantity={get(optionItemMatch, 'quantity')}
                  handleIncrement={() =>
                    this.handleAddOptionToLineItem(optionGroupData, optionItem)
                  }
                  handleDecrement={() =>
                    this.handleRemoveOptionFromLineItem(optionItem)
                  }
                  canIncrement={get(
                    optionGroupMapping,
                    'canAddMoreToThisGroup',
                    true
                  )}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default MultiSelect;
