import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import withNavThemeContext from 'lib/withNavThemeContext';

import get from 'utils/get';
import dehyphenate from 'utils/dehyphenate';

import cx from 'classnames';
import v from 'vudu';
import { breakpoints, classes as c, format as f, styles as s } from 'styles';

import {
  DropdownLink,
  SmartLink,
  Text,
  ButtonLink
} from 'components/base/index';
import Hamburger from 'components/svg/Hamburger';
import OrderButton from 'components/OrderButton';

import Vars from 'constants/Vars';
import isOrderingEnabled from 'utils/isOrderingEnabled';

import Language from 'constants/Language';

import {
  DARK,
  logo as logoColorMap,
  navTheme as navThemeColorMap
} from 'constants/ColorMaps';

const { logoWidth, mobileLogoMaxWidth, headerHeight } = Vars;

const DeliveryLink = ({ navColor }) => (
  <a
    className={f(c.ml7)}
    href={Vars.shippingLink}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Text color={get(navColor, 'name')} variant={`navItem`}>
      <span className={classes.hideMdDown}>NATIONAL</span> SHIPPING
    </Text>
  </a>
);

const classes = v({
  header: {
    '@composes': [
      s.t0,
      s.r0,
      s.l0,
      s.px7,
      s.flex,
      s.alignCenter,
      s.zHeader,
      s.transitionBackground,
      s.bgWhite,
      s.headerShadow,
      s.noShadow
    ],
    backgroundColor: `#ffffff`,
    height: `${headerHeight}px`
  },
  headerScrolled: {
    '@composes': [s.bgWhite, s.headerShadow, s.fixed]
  },
  navContent: {
    display: `none`,

    [breakpoints.md]: {
      '@composes': [s.relative, s.flex, s.spaceBetween, s.alignCenter]
    }
  },
  logoDesktop: {
    '@composes': [s.absolute, s.hoverOpacity, s.center],
    left: '50%',
    transform: 'translate(-50%)'
  },
  navContentNotOrderable: {
    [breakpoints.md]: {
      justifyContent: `flex-start`
    }
  },
  navLeftNotOrderable: {
    width: `calc(50% - ${logoWidth / 2}px)`
  },
  mobileNavContent: {
    '@composes': [s.flex, s.alignCenter, s.spaceBetween],

    [breakpoints.md]: {
      display: `none`
    }
  },
  logoMobile: {
    '@composes': [s.flex, s.justifyCenter, s.alignCenter],
    img: {
      maxWidth: `${mobileLogoMaxWidth}px`
    }
  },
  simpleHeaderLogo: {
    '@composes': [s.absolute, s.hoverOpacity],
    left: '50%',
    transform: 'translate(-50%)',
    img: {
      maxWidth: `${mobileLogoMaxWidth}px`
    },
    [breakpoints.md]: {
      img: {
        maxWidth: 'none'
      }
    }
  },
  hideMdDown: {
    display: `none`,
    [breakpoints.md]: {
      display: `inline-block`
    }
  }
});

let lastScrollY = 0;

class Header extends Component {
  state = {
    isPageTop: true
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  toggleMobileMenu = () => {
    const { openMobileMenu, closeMobileMenu, mobileMenuIsOpen } = this.props;

    if (mobileMenuIsOpen) {
      return closeMobileMenu();
    }
    return openMobileMenu();
  };

  handleScroll = () => {
    const { isPageTop } = this.state;

    lastScrollY = window.scrollY;

    if (lastScrollY > 0) {
      if (isPageTop) {
        this.setState({ isPageTop: false });
      }
    } else {
      if (!isPageTop) {
        this.setState({ isPageTop: true });
      }
    }
  };

  renderNavContent = isPageTop => {
    const {
      locationsDropdownData,
      storiesDropdownData,
      navTheme,
      locationTitle,
      renderSimpleHeader,
      currentOrderMenuPath
    } = this.props;

    const orderingDisabled = !isOrderingEnabled();

    const navContentClasses = cx(classes.navContent, {
      [classes.navContentNotOrderable]: orderingDisabled
    });

    const navLeftClasses = cx({
      [classes.navLeftNotOrderable]: orderingDisabled
    });

    const navColor = isPageTop
      ? navThemeColorMap[navTheme.name]
      : navThemeColorMap[DARK];

    return renderSimpleHeader ? (
      <div className={f(c.flex, c.alignCenter)}>
        <ButtonLink
          to={currentOrderMenuPath}
          variant="blackWithArrow"
          text="MENU"
          className={f(c.flex, c.hoverOpacity)}
        />
        <div className={classes.simpleHeaderLogo}>
          <SmartLink to={'/'}>
            <img alt="tartine logo" src={logoColorMap[get(navColor, 'name')]} />
          </SmartLink>
        </div>
      </div>
    ) : (
      <div className={navContentClasses}>
        <div className={navLeftClasses}>
          <DropdownLink
            color={get(navColor, 'name')}
            className={c.mr7}
            label={Language.t('header.locations')}
            path={'/cities'}
            links={locationsDropdownData}
          />
          <DropdownLink
            color={get(navColor, 'name')}
            label={Language.t('header.aboutUs')}
            path={'/about'}
            links={storiesDropdownData}
          />
        </div>
        <div className={classes.logoDesktop}>
          <SmartLink to={'/'}>
            <img alt="tartine logo" src={logoColorMap[get(navColor, 'name')]} />
          </SmartLink>
          {!!locationTitle && (
            <Text
              className={f(c.center, c.uppercase, c.mt1)}
              color={get(navColor, 'name')}
              variant="cardLink"
            >
              {dehyphenate(locationTitle)}
            </Text>
          )}
        </div>
        {isOrderingEnabled() && (
          <div className={f(c.flex, c.alignCenter)}>
            <DeliveryLink navColor={navColor} />
            <OrderButton className={c.ml7} />
          </div>
        )}
      </div>
    );
  };

  renderMobileNavContent = isPageTop => {
    const { navTheme } = this.props;

    const navColor = isPageTop
      ? navThemeColorMap[navTheme.name]
      : navThemeColorMap[DARK];

    return (
      <div className={classes.mobileNavContent}>
        <div className={f(c.flex, c.alignCenter, c.justifyCenter)}>
          <button
            className={f(c.flex, c.pointer)}
            onClick={this.toggleMobileMenu}
            aria-label="Open Menu"
          >
            <Hamburger fill={get(navColor, 'hex')} className={c.mr4} />
          </button>
          <SmartLink className={classes.logoMobile} to={'/'}>
            <img alt="tartine logo" src={logoColorMap[get(navColor, 'name')]} />
          </SmartLink>
        </div>
        <div>
          <DeliveryLink navColor={navColor} />
          <div className={c.inlineBlock}>
            {isOrderingEnabled() && <OrderButton className={c.ml7} />}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isPageTop } = this.state;
    const {
      announcementBarIsShowing,
      renderSimpleHeader,
      noHeader
    } = this.props;

    const classNames = cx(classes.header, {
      [c.absolute]: announcementBarIsShowing,
      [c.fixed]: !announcementBarIsShowing,
      [classes.headerScrolled]: !this.state.isPageTop
    });

    return (
      <header className={classNames}>
        <nav className={c.col12}>
          {!noHeader && this.renderNavContent(isPageTop)}
          {!noHeader &&
            !renderSimpleHeader &&
            this.renderMobileNavContent(isPageTop)}
        </nav>
      </header>
    );
  }
}

Header.propTypes = {
  announcementBarIsShowing: PropTypes.bool.isRequired,
  renderSimpleHeader: PropTypes.bool.isRequired,
  mobileMenuIsOpen: PropTypes.bool.isRequired,
  openMobileMenu: PropTypes.func.isRequired,
  closeMobileMenu: PropTypes.func.isRequired,
  locationsDropdownData: PropTypes.array.isRequired,
  storiesDropdownData: PropTypes.array.isRequired,
  navTheme: PropTypes.shape({
    name: PropTypes.string,
    hex: PropTypes.string
  })
};

export { Header };
export default withNavThemeContext(withRouter(Header));
