import React, { PureComponent } from 'react';
import get from 'utils/get';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createPayment, Status } from 'brandibble-redux';
import { resetModal } from 'state/actions/ui/modalActions';

import { Text } from 'components/base';
import { AddPaymentMethodForm } from 'components/forms';

import { classes as c, format as f } from 'styles';

const { PENDING, FULFILLED } = Status;

class AddPaymentMethodModal extends PureComponent {
  componentDidUpdate(prevProps) {
    if (
      get(prevProps, 'createPaymentStatus') === PENDING &&
      get(this, 'props.createPaymentStatus') === FULFILLED
    ) {
      get(this, 'props.actions.resetModal', f => f)();
    }
  }

  render() {
    const resetModal = get(this, 'props.actions.resetModal');
    const createPayment = get(this, 'props.actions.createPayment');
    const createPaymentStatus = get(this, 'props.createPaymentStatus');
    const createPaymentError = get(this, 'props.createPaymentError');
    const brandibbleRef = get(this, 'props.brandibbleRef');

    return (
      <div className={c.modalFormMaxWidth}>
        <div className={f(c.center, c.mb9)}>
          <Text variant="modalTitle">Add Payment Method</Text>
        </div>
        <AddPaymentMethodForm
          brandibbleRef={brandibbleRef}
          onSubmit={createPayment}
          onCancel={resetModal}
          submitStatus={createPaymentStatus}
          submitError={createPaymentError}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brandibbleRef: get(state, 'brandibble.ref'),
  createPaymentStatus: get(state, 'brandibble.status.createPayment'),
  createPaymentError: get(state, 'brandibble.error.createPayment')
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createPayment,
      resetModal
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPaymentMethodModal);
