import { createSelector } from 'reselect';

import get from 'utils/get';

export default createSelector(
  state => get(state, 'global.data', {}),
  globalData => {
    const announcement = get(globalData, 'fields.announcement');

    return {
      showAnnouncement: get(announcement, 'fields.showAnnouncement', false),
      content: get(announcement, 'fields.content', ''),
      linkCta: get(announcement, 'fields.linkCta', ''),
      linkUrl: get(announcement, 'fields.linkUrl', '')
    };
  }
);
