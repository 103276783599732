import React, { Component } from 'react';
import PropTypes from 'prop-types';
import v from 'vudu';

const classes = v({
  fadeImage: { transition: 'opacity 500ms ease' },
  preLoad: { opacity: 0 }
});

class FadeImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      combinedClasses: `${classes.fadeImage} ${classes.preLoad} ${
        props.className
      }`
    };
  }

  componentWillMount() {
    const { bg, src } = this.props;
    if (bg) {
      const loader = new Image();
      loader.src = '';
      loader.onload = this.didLoad;
      loader.src = src;
    }
  }

  componentWillUnmount() {
    this.outOfView = true;
  }

  didLoad = () => {
    if (this.outOfView) return;
    const { className } = this.props;
    const combinedClasses = `${classes.fadeImage} ${className}`;
    this.setState({ combinedClasses });
  };

  render() {
    const { src, alt, style, bg, children } = this.props;
    const { combinedClasses } = this.state;
    const bgStyle = { ...style, backgroundImage: `url(${src})` };

    if (!bg) {
      return (
        <img
          style={style}
          onLoad={this.didLoad}
          className={combinedClasses}
          src={src}
          alt={alt}
        />
      );
    }
    return (
      <div className={combinedClasses} style={bgStyle}>
        {children}
      </div>
    );
  }
}

FadeImage.defaultProps = {
  bg: false,
  style: {},
  children: null,
  className: '',
  alt: ''
};

FadeImage.propTypes = {
  bg: PropTypes.bool,
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  alt: PropTypes.string
};

export default FadeImage;
