import { createSelector } from 'reselect';

import get from 'utils/get';

export default createSelector(
  state => get(state, 'global.data', {}),
  globalData => {
    if (!globalData) return null;

    const { fields } = globalData;

    return get(fields, 'cities', []).map(city => ({
      label: get(city, 'fields.title'),
      acronym: get(city, 'fields.acronym', get(city, 'fields.title')),
      path: get(city, 'fields.path', '#'),
      locationsCount: get(city, 'fields.locations', []).length,
      locations: get(city, 'fields.locations', [])
    }));
  }
);
