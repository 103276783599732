import get from 'utils/get';
import { PRESENT } from 'constants/Brandibble';

export const getLineItemData = (menuItems, lineItemId) =>
  menuItems.find(
    matchingMenuItem => get(matchingMenuItem, 'id') === lineItemId
  );

export const getOptionGroupData = (optionGroups, groupId) =>
  optionGroups.find(optionGroup => get(optionGroup, 'id') === groupId);

export const getOptionItemData = (optionItems, itemId) =>
  optionItems.find(optionItem => get(optionItem, 'id') === itemId);

export const getOptionItemMapping = (optionItemMappings, optionId) =>
  optionItemMappings.find(
    mappedOptionItem =>
      parseInt(get(mappedOptionItem, 'optionId'), 10) === parseInt(optionId, 10)
  );

export const getPresentOptionItems = optionGroupMappings =>
  optionGroupMappings.reduce(
    (presentOptionGroupItems, currentOptionGroupMapping) => {
      const presentOptionItems = currentOptionGroupMapping.optionItems.filter(
        optionItem => get(optionItem, 'presence') === PRESENT
      );

      return [...presentOptionGroupItems, ...presentOptionItems];
    },
    []
  );

export default (menu, lineItems) => {
  if (!get(menu, 'length', []) || !get(lineItems, 'length', [])) {
    return [];
  }

  return menu
    .filter(menuCategory =>
      lineItems.some(
        lineItem =>
          get(lineItem, 'productData.category_id') === get(menuCategory, 'id')
      )
    )
    .reduce((matchingMenuItems, currentMenuCategory) => {
      const itemsForCurrentMenuCategory = get(currentMenuCategory, 'items', []);

      const matchingItemsForCurrentMenuCategory = itemsForCurrentMenuCategory.filter(
        item =>
          lineItems.some(
            lineItem => get(lineItem, 'productData.id') === get(item, 'id')
          )
      );

      return [...matchingMenuItems, ...matchingItemsForCurrentMenuCategory];
    }, []);
};
