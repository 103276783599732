const sizes = [
  '0.125rem', // size(1) | 2px
  '0.25rem', // size(2) | 4px
  '0.5rem', // size(3) | 8px
  '0.75rem', // size(4) | 12px
  '1rem', // size(5) | 16px
  '1.25rem', // size(6) | 20px
  '1.5rem', // size(7) | 24px
  '1.75rem', // size(8) | 28px
  '2rem', // size(9) | 32px
  '2.5rem', // size(10) | 40px
  '3rem' // size(11) | 48px
];

function buildSpacingClasses() {
  const spacing = {};

  sizes.forEach((size, index) => {
    /* Margins */
    spacing[`m${index + 1}`] = { margin: `${sizes[index]}` };
    spacing[`mt${index + 1}`] = { marginTop: `${sizes[index]}` };
    spacing[`mr${index + 1}`] = { marginRight: `${sizes[index]}` };
    spacing[`mb${index + 1}`] = { marginBottom: `${sizes[index]}` };
    spacing[`ml${index + 1}`] = { marginLeft: `${sizes[index]}` };
    spacing[`my${index + 1}`] = {
      marginTop: `${sizes[index]}`,
      marginBottom: `${sizes[index]}`
    };
    spacing[`mx${index + 1}`] = {
      marginLeft: `${sizes[index]}`,
      marginRight: `${sizes[index]}`
    };

    /* Padding */
    spacing[`p${index + 1}`] = { padding: `${sizes[index]}` };
    spacing[`pt${index + 1}`] = { paddingTop: `${sizes[index]}` };
    spacing[`pr${index + 1}`] = { paddingRight: `${sizes[index]}` };
    spacing[`pb${index + 1}`] = { paddingBottom: `${sizes[index]}` };
    spacing[`pl${index + 1}`] = { paddingLeft: `${sizes[index]}` };
    spacing[`py${index + 1}`] = {
      paddingTop: `${sizes[index]}`,
      paddingBottom: `${sizes[index]}`
    };
    spacing[`px${index + 1}`] = {
      paddingLeft: `${sizes[index]}`,
      paddingRight: `${sizes[index]}`
    };
  });

  return spacing;
}

export const size = num => {
  if (num === `gutter`) {
    return sizes[6];
  }
  return sizes[num - 1];
};

export const spacing = buildSpacingClasses();
