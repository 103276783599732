import ContentfulData from 'lib/ContentfulData';
import { loadState, saveState } from 'lib/localforage';

import get from 'utils/get';
import { EN_US } from 'constants/LocaleCodes';
import Locales from 'constants/Language/Locales';
import Language from 'constants/Language';

export const GET_AND_SET_INITIAL_LOCALE = 'GET_AND_SET_INITIAL_LOCALE';
export const FETCH_ALL_LOCALES = 'FETCH_ALL_LOCALES';
export const SET_ACTIVE_LOCALE = 'SET_ACTIVE_LOCALE';

export const fetchAllLocales = () => dispatch =>
  dispatch({
    type: FETCH_ALL_LOCALES,
    payload: ContentfulData.getLocales().then(res => res.items)
  });

/*
 * Persist new active locale to localforage
 * Return new active locale
 */
export const setActiveLocale = locale => dispatch =>
  dispatch({
    type: SET_ACTIVE_LOCALE,
    payload: saveState({ locale: { activeLocale: locale } }).then(() => locale)
  });

/*
  * Fetch active locale from localforage (default to EN_US)
  * Set Polyglot to use active locale
  * Resolve once all locales have been fetched from contentful
    and active locale has been set
*/
export const getAndSetInitialLocale = () => dispatch =>
  dispatch({
    type: GET_AND_SET_INITIAL_LOCALE,
    payload: new Promise(resolve =>
      loadState().then(res => {
        const locale = get(res, 'locale.activeLocale', EN_US);
        Language.extend(Locales[locale]);

        return Promise.all([
          dispatch(fetchAllLocales()),
          dispatch(setActiveLocale(locale))
        ]).then(resolve);
      })
    )
  });
