import React, { PureComponent } from 'react';
import get from 'utils/get';
import Language from 'constants/Language';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetModal } from 'state/actions/ui/modalActions';
import { createOrderData } from 'state/selectors';

import { Text, Button, SmartLink } from 'components/base';

import cx from 'classnames';
import v from 'vudu';
import {
  breakpoints,
  colors,
  classes as c,
  format as f,
  styles as s,
  size
} from 'styles';

const classes = v({
  createOrderModal: {
    '@composes': [s.col12, s.flex, s.flexColumn]
  },
  fullWidthRow: {
    marginLeft: `-${size(7)}`,
    marginRight: `-${size(7)}`
  },
  orderableLocationsContainer: {
    '@composes': [s.py7, s.flexColumn, s.spaceAround],
    minHeight: `200px`,
    overflow: 'auto'
  },
  orderTypeTab: {
    '@composes': [
      s.bgWhite,
      s.midGray,
      s.modalTabLabel,
      s.py5,
      s.monoBold,
      s.uppercase,
      s.col6,
      s.pointer,
      s.hoverOpacity,
      s.borderBottomGray
    ],
    borderTop: `solid 1px ${colors.gray}`,
    flexGrow: 1
  },
  orderTypeTabActive: {
    '@composes': [s.bgDarkGray, s.white]
  },
  orderableCityTab: {
    '@composes': [
      s.modalTabLabel,
      s.monoBold,
      s.grow1,
      s.pointer,
      s.hoverOpacity
    ],
    borderBottom: `solid 2px ${colors.transparent}`
  },
  orderableCityTabText: {
    '@composes': [s.inlineBlock, s.midGray, s.py5],
    borderBottom: `solid 2px ${colors.transparent}`
  },
  orderableCityTabTextActive: {
    '@composes': [s.darkGray],
    borderBottom: `solid 2px ${colors.gold}`
  },
  orderableLocationTab: {
    '@composes': [
      s.p6,
      s.modalTabLabel,
      s.monoMedium,
      s.nowrap,
      s.pointer,
      s.hoverOpacity,
      s.borderGray,
      s.textLeft
    ],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ':not(:last-of-type)': {
      '@composes': [s.mb7]
    }
  },
  orderableLocationTabActive: {
    borderColor: colors.gold
  },
  modalButtonsContainer: {
    '@composes': [s.flex, s.alignCenter, s.spaceBetween],

    [breakpoints.md]: {
      '@composes': [s.spaceAround]
    }
  }
});

class CreateOrderModal extends PureComponent {
  constructor(props) {
    super(...arguments);

    const initialActiveOrderType = get(
      props,
      'createOrderData.orderTypes',
      []
    )[0];
    const initialActiveOrderableCity = get(
      initialActiveOrderType,
      'orderableCities',
      []
    )[0];

    this.state = {
      activeOrderType: initialActiveOrderType,
      activeOrderableCity: initialActiveOrderableCity
    };
  }

  handleCreateOrder = () => {
    const locationPath = get(this, 'state.activeOrderableLocation.fields.path');
    const orderTypeSlug = get(this, 'state.activeOrderType.slug');

    get(this, 'props.actions.resetModal', f => f)();
    return get(this, 'props.history.push', f => f)(
      `${locationPath}/${orderTypeSlug}`
    );
  };

  setActiveOrderType = orderType => {
    const activeOrderableCityForOrderType = get(
      orderType,
      'orderableCities',
      []
    )[0];

    this.setState({
      activeOrderType: orderType,
      activeOrderableCity: activeOrderableCityForOrderType,
      activeOrderableLocation: {}
    });
  };

  setActiveOrderableCity = orderableCity => {
    this.setState({
      ...this.state,
      activeOrderableCity: orderableCity,
      activeOrderableLocation: {}
    });
  };

  setActiveOrderableLocation = orderableLocation => {
    this.setState({
      ...this.state,
      activeOrderableLocation: orderableLocation
    });
  };

  renderOrderTypes = (orderTypes, activeOrderType) => {
    return (
      <div className={f(c.flex)}>
        {orderTypes.map(orderType => (
          <button
            key={get(orderType, 'slug')}
            onClick={() => this.setActiveOrderType(orderType)}
            className={cx(classes.orderTypeTab, {
              [classes.orderTypeTabActive]: orderType === activeOrderType
            })}
          >
            {get(orderType, 'label')}
          </button>
        ))}
      </div>
    );
  };

  renderOrderableCities = (orderableCities, activeOrderableCity) => {
    return (
      <div className={f(c.flex)}>
        {orderableCities.map(orderableCity => (
          <button
            key={get(orderableCity, 'label')}
            onClick={() => this.setActiveOrderableCity(orderableCity)}
            className={cx(classes.orderableCityTab)}
          >
            <span
              className={cx(classes.orderableCityTabText, {
                [classes.orderableCityTabTextActive]:
                  orderableCity === activeOrderableCity
              })}
            >
              {get(orderableCity, 'acronym')}
            </span>
          </button>
        ))}
      </div>
    );
  };
  renderOrderableLocations = (orderableLocations, activeOrderType) => {
    return (
      <div className={f(c.flex, c.flexColumn)}>
        {orderableLocations.map(orderableLocation => (
          <SmartLink
            className={cx(classes.orderableLocationTab)}
            to={
              activeOrderType.label === 'Delivery'
                ? get(orderableLocation, 'fields.orderingLink')
                : get(orderableLocation, 'fields.preorderLink')
            }
            external
          >
            <Text>{get(orderableLocation, 'fields.title')}</Text>
          </SmartLink>
        ))}
      </div>
    );
  };

  render() {
    const resetModal = get(this, 'props.actions.resetModal');

    const { activeOrderType, activeOrderableCity } = this.state;

    return (
      <div className={classes.createOrderModal}>
        <div className={c.grow1}>
          <Text className={f(c.center, c.mb7)} variant="modalTitle">
            Ordering For
          </Text>
          <div>
            <div className={classes.fullWidthRow}>
              {this.renderOrderableCities(
                get(activeOrderType, 'orderableCities', []),
                activeOrderableCity
              )}
            </div>
            <div className={classes.orderableLocationsContainer}>
              {this.renderOrderableLocations(
                get(activeOrderableCity, 'orderableLocations', []),
                activeOrderType
              )}
            </div>
          </div>
        </div>
        <div className={classes.modalButtonsContainer}>
          <Button
            onClick={resetModal}
            variant="basicText"
            text={Language.t('confirmationModal.cancel')}
            type="button"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  createOrderData: createOrderData(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetModal
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateOrderModal));
