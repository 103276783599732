import React, { PureComponent } from 'react';
import get from 'utils/get';
import PropTypes from 'prop-types';

import Language from 'constants/Language';
import { InputTypes } from 'constants/Forms';

import TextArea from 'components/forms/TextArea';

import v from 'vudu';
import { styles as s } from 'styles';

const { INSTRUCTIONS } = InputTypes;

const classes = v({
  lineItemInstructions: {
    '@composes': [s.label, s.bgLightGray, s.px4],
    '::placeholder': {
      '@composes': [s.center, s.black]
    }
  }
});

class AddLineItemInstructionsForm extends PureComponent {
  constructor(props) {
    super(...arguments);

    this.state = {
      values: {
        [INSTRUCTIONS]: get(props, 'instructions')
      }
    };
  }

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      values: { ...prevState.values, [target.name]: target.value }
    }));
  };

  handleBlur = () => {
    const { values } = this.state;

    if (values[INSTRUCTIONS] !== get(this, 'props.instructions')) {
      this.handleTriggerSetLineItemInstructions(values[INSTRUCTIONS]);
    }
  };

  handleTriggerSetLineItemInstructions = instructions => {
    const setLineItemInstructions = get(
      this,
      'props.setLineItemInstructions',
      f => f
    );
    setLineItemInstructions(instructions);
  };

  render() {
    const { values } = this.state;
    return (
      <form>
        <TextArea
          className={classes.lineItemInstructions}
          rows={3}
          name={INSTRUCTIONS}
          placeholder={Language.t('cateringModal.instructionsPlaceholder')}
          value={values[INSTRUCTIONS]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
      </form>
    );
  }
}

AddLineItemInstructionsForm.defaultProps = {
  setLineItemInstructions: f => f,
  instructions: ''
};

AddLineItemInstructionsForm.propTypes = {
  setLineItemInstructions: PropTypes.func,
  instructions: PropTypes.string
};

export default AddLineItemInstructionsForm;
