import ContainerBase from 'lib/ContainerBase';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { LIGHT } from 'constants/ColorMaps';
import get from 'utils/get';
import withNavThemeContext from 'lib/withNavThemeContext';

import {
  citiesAndLocationsCount,
  locationsGeoJSON,
  selectedCityLocations
} from 'state/selectors';

import {
  resetLocationsFinder,
  collapseHeader,
  selectCity,
  deselectCity,
  setHoveredFeatureListIndex,
  unsetHoveredFeatureListIndex
} from 'state/actions/ui/locationsFinderActions';

class CitiesContainer extends ContainerBase {
  view = import('views/CitiesView');

  afterModel = () => {
    this.props.setNavTheme(LIGHT);
  };
}

const mapStateToProps = state => {
  return {
    isHeaderCollapsed: get(state, 'locationsFinder.isHeaderCollapsed'),
    selectedCity: get(state, 'locationsFinder.selectedCity'),
    hoveredFeatureListIndex: get(
      state,
      'locationsFinder.hoveredFeatureListIndex'
    ),
    selectedCityLocations: selectedCityLocations(state),
    citiesAndLocationsCount: citiesAndLocationsCount(state),
    locationsGeoJSON: locationsGeoJSON(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      collapseHeader,
      selectCity,
      deselectCity,
      setHoveredFeatureListIndex,
      unsetHoveredFeatureListIndex,
      resetLocationsFinder
    },
    dispatch
  )
});

export { CitiesContainer };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavThemeContext(CitiesContainer));
