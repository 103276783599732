import React, { PureComponent } from 'react';

import { announcementBarData } from 'constants/PropTypes';

import get from 'utils/get';
import isExternalUrl from 'utils/isExternalUrl';

import { ButtonLink, Text } from 'components/base';

import v from 'vudu';
import { breakpoints, styles as s } from 'styles';

import Vars from 'constants/Vars';

const { announcementBarHeight } = Vars;

const classes = v({
  announcementBar: {
    '@composes': [s.bgOffwhite, s.px7, s.flex, s.justifyCenter, s.alignCenter],
    height: `${announcementBarHeight}px`
  },
  content: {
    '@composes': [s.pr3],
    [breakpoints.md]: {
      '@composes': [s.pr7]
    }
  },
  cta: {
    '@composes': [s.nowrap]
  }
});

class AnnouncementBar extends PureComponent {
  render() {
    const { data } = this.props;
    if (!get(data, 'showAnnouncement')) return null;

    return (
      <div className={classes.announcementBar}>
        <Text
          className={classes.content}
          color="darkGray"
          variant="announcement"
        >
          {get(data, 'content')}
        </Text>
        {!!get(data, 'linkCta') && (
          <ButtonLink
            external={isExternalUrl(get(data, 'linkUrl'))}
            to={get(data, 'linkUrl')}
            className={classes.cta}
            variant="blackUnderlineWithArrow"
            text={get(data, 'linkCta')}
          />
        )}
      </div>
    );
  }
}

AnnouncementBar.propTypes = {
  data: announcementBarData.isRequired
};

export default AnnouncementBar;
