import React from 'react';
import PropTypes from 'prop-types';

import Remarkable from 'react-remarkable';

import v from 'vudu';
import { format as f, styles as s, breakpoints, colors } from 'styles';

const locals = v({
  markdown: {
    '@composes': [s.paragraph, s.black],
    span: {
      '>:last-child': { marginBottom: 0 }
    },
    blockquote: {
      '@composes': [s.pullquoteLight, s.ml0, s.my9],
      [breakpoints.md]: {
        '@composes': [s.my11]
      },
      /* Double nested pullquote is >> in markdown */
      blockquote: {
        '@composes': [s.pullquoteCompact, s.ml0, s.my9],
        [breakpoints.md]: {
          '@composes': [s.my11]
        }
      }
    },
    a: {
      '@composes': [s.black, s.underline, s.transitionOpacity],
      ':hover': {
        opacity: '0.7'
      }
    },
    ul: {
      '@composes': [s.col12, s.mb11],
      listStyle: 'none'
    },
    ol: {
      '@composes': [s.col12, s.mb11, s.pl7]
    },
    li: {
      ':not(:last-of-type)': {
        '@composes': [s.mb2]
      }
    },
    h1: {
      '@composes': [s.heading, s.mb7]
    },
    h2: {
      '@composes': [s.title, s.mb7]
    },
    h3: {
      '@composes': [s.cta, s.mb7]
    },
    p: {
      ':not(:last-of-type)': {
        '@composes': [s.mb9],

        [breakpoints.md]: {
          '@composes': [s.mb11]
        }
      }
    },
    img: {
      '@composes': [s.col12],
      maxWidth: '100%'
    },
    hr: {
      border: 0,
      borderBottom: `solid 1px ${colors.gray}`
    }
  }
});

const Markdown = ({ className, src }) => (
  <div className={f(locals.markdown, className)}>
    <Remarkable source={src} options={{ html: true }} />
  </div>
);

Markdown.defaultProps = {
  className: '',
  src: ''
};

Markdown.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string
};

export default Markdown;
