import { FULFILLED, IDLE, PENDING, REJECTED } from 'constants/Status';

/**
 * Actions
 */

import { INITIALIZE_APPLICATION } from 'state/actions/applicationActions';
import { FETCH_GLOBAL_DATA } from 'state/actions/contentfulActions';
import {
  GET_AND_SET_INITIAL_LOCALE,
  SET_ACTIVE_LOCALE,
  FETCH_ALL_LOCALES
} from 'state/actions/localeActions';
import {
  REMOVE_INVALID_ITEMS_AND_SET_REQUESTED_AT,
  ATTEMPT_REORDER
} from 'state/actions/brandibble/orderActions';
import {
  VALIDATE_AND_REMOVE_INVALID_ITEMS,
  REMOVE_INVALID_ITEMS
} from 'state/actions/brandibble/menuActions';
import { TOGGLE_ADD_OPTION_TO_LINE_ITEM } from 'state/actions/brandibble/lineItemActions';

/**
 * Saga Actions
 */

import { ON_AUTHENTICATION_SAGA } from 'state/sagas/onAuthenticationSaga';
import { ON_UNAUTHENTICATION_SAGA } from 'state/sagas/onUnauthenticationSaga';
import { ON_INITIALIZE_APPLICATION_SAGA } from 'state/sagas/onInitializeApplicationSaga';
import { ON_SET_DEFAULT_PAYMENT_SAGA } from 'state/sagas/onSetDefaultPaymentSaga';
import { ON_CREATE_PAYMENT_METHOD_SAGA } from 'state/sagas/onCreatePaymentMethodSaga';
import { ON_SUBMIT_ORDER_SAGA } from 'state/sagas/onSubmitOrderSaga';
import { ON_SET_REQUESTED_AT_SAGA } from 'state/sagas/onSetRequestedAtSaga';

const initialState = {
  initializeApplication: IDLE,
  fetchGlobalData: IDLE,
  fetchAllLocales: IDLE,
  getAndSetInitialLocale: IDLE,
  setActiveLocale: IDLE,
  removeInvalidItemsAndSetRequestedAt: IDLE,
  onAuthenticationSaga: IDLE,
  onUnauthenticationSaga: IDLE,
  onInitializeApplicationSaga: IDLE,
  onSetDefaultPaymentSaga: IDLE,
  onCreatePaymentMethodSaga: IDLE,
  onSubmitOrderSaga: IDLE,
  onSetRequestedAtSaga: IDLE,
  validateAndRemoveInvalidItems: IDLE,
  removeInvalidItems: IDLE,
  attemptReorder: IDLE,
  toggleAddOptionToLineItem: IDLE
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    /* Initialize Application */
    case `${INITIALIZE_APPLICATION}_PENDING`:
      return { ...state, initializeApplication: PENDING };
    case `${INITIALIZE_APPLICATION}_FULFILLED`:
      return { ...state, initializeApplication: FULFILLED };
    case `${INITIALIZE_APPLICATION}_REJECTED`:
      return { ...state, initializeApplication: REJECTED };

    /* Fetches Global Contentful Data */
    case `${FETCH_GLOBAL_DATA}_PENDING`:
      return { ...state, fetchGlobalData: PENDING };
    case `${FETCH_GLOBAL_DATA}_FULFILLED`:
      return { ...state, fetchGlobalData: FULFILLED };
    case `${FETCH_GLOBAL_DATA}_REJECTED`:
      return { ...state, fetchGlobalData: REJECTED };

    /* Fetches all locales enabled in contentful */
    case `${FETCH_ALL_LOCALES}_PENDING`:
      return { ...state, fetchAllLocales: PENDING };
    case `${FETCH_ALL_LOCALES}_FULFILLED`:
      return { ...state, fetchAllLocales: FULFILLED };
    case `${FETCH_ALL_LOCALES}_REJECTED`:
      return { ...state, fetchAllLocales: REJECTED };

    /* Gets initial locale from localeforage and sets active locale */
    case `${GET_AND_SET_INITIAL_LOCALE}_PENDING`:
      return { ...state, getAndSetInitialLocale: PENDING };
    case `${GET_AND_SET_INITIAL_LOCALE}_FULFILLED`:
      return { ...state, getAndSetInitialLocale: FULFILLED };
    case `${GET_AND_SET_INITIAL_LOCALE}_REJECTED`:
      return { ...state, getAndSetInitialLocale: REJECTED };

    /* Saves locale to localforage and sets in redux */
    case `${SET_ACTIVE_LOCALE}_PENDING`:
      return { ...state, setActiveLocale: PENDING };
    case `${SET_ACTIVE_LOCALE}_FULFILLED`:
      return { ...state, setActiveLocale: FULFILLED };
    case `${SET_ACTIVE_LOCALE}_REJECTED`:
      return { ...state, setActiveLocale: REJECTED };

    case `${REMOVE_INVALID_ITEMS}_PENDING`:
      return { ...state, removeInvalidItems: PENDING };
    case `${REMOVE_INVALID_ITEMS}_FULFILLED`:
      return { ...state, removeInvalidItems: FULFILLED };
    case `${REMOVE_INVALID_ITEMS}_REJECTED`:
      return { ...state, removeInvalidItems: REJECTED };

    case `${TOGGLE_ADD_OPTION_TO_LINE_ITEM}_PENDING`:
      return { ...state, toggleAddOptionToLineItem: PENDING };
    case `${TOGGLE_ADD_OPTION_TO_LINE_ITEM}_FULFILLED`:
      return { ...state, toggleAddOptionToLineItem: FULFILLED };
    case `${TOGGLE_ADD_OPTION_TO_LINE_ITEM}_REJECTED`:
      return { ...state, toggleAddOptionToLineItem: REJECTED };

    /* clears invalid items from cart and set new requested at */
    case `${REMOVE_INVALID_ITEMS_AND_SET_REQUESTED_AT}_PENDING`:
      return { ...state, removeInvalidItemsAndSetRequestedAt: PENDING };
    case `${REMOVE_INVALID_ITEMS_AND_SET_REQUESTED_AT}_FULFILLED`:
      return { ...state, removeInvalidItemsAndSetRequestedAt: FULFILLED };
    case `${REMOVE_INVALID_ITEMS_AND_SET_REQUESTED_AT}_REJECTED`:
      return { ...state, removeInvalidItemsAndSetRequestedAt: REJECTED };

    case `${VALIDATE_AND_REMOVE_INVALID_ITEMS}_PENDING`:
      return { ...state, validateAndRemoveInvalidItems: PENDING };
    case `${VALIDATE_AND_REMOVE_INVALID_ITEMS}_FULFILLED`:
      return { ...state, validateAndRemoveInvalidItems: FULFILLED };
    case `${VALIDATE_AND_REMOVE_INVALID_ITEMS}_REJECTED`:
      return { ...state, validateAndRemoveInvalidItems: REJECTED };

    case `${ATTEMPT_REORDER}_PENDING`:
      return { ...state, attemptReorder: PENDING };
    case `${ATTEMPT_REORDER}_FULFILLED`:
      return { ...state, attemptReorder: FULFILLED };
    case `${ATTEMPT_REORDER}_REJECTED`:
      return { ...state, attemptReorder: REJECTED };

    /* Saga runs setup after user authenticates */
    case `${ON_AUTHENTICATION_SAGA}_PENDING`:
      return { ...state, onAuthenticationSaga: PENDING };
    case `${ON_AUTHENTICATION_SAGA}_FULFILLED`:
      return { ...state, onAuthenticationSaga: FULFILLED };
    case `${ON_AUTHENTICATION_SAGA}_REJECTED`:
      return { ...state, onAuthenticationSaga: REJECTED };

    /* Saga cleans up after user unauthenticates */
    case `${ON_UNAUTHENTICATION_SAGA}_PENDING`:
      return { ...state, onUnauthenticationSaga: PENDING };
    case `${ON_UNAUTHENTICATION_SAGA}_FULFILLED`:
      return { ...state, onUnauthenticationSaga: FULFILLED };
    case `${ON_UNAUTHENTICATION_SAGA}_REJECTED`:
      return { ...state, onUnauthenticationSaga: REJECTED };

    /* Saga runs setup after application initializes */
    case `${ON_INITIALIZE_APPLICATION_SAGA}_PENDING`:
      return { ...state, onInitializeApplicationSaga: PENDING };
    case `${ON_INITIALIZE_APPLICATION_SAGA}_FULFILLED`:
      return { ...state, onInitializeApplicationSaga: FULFILLED };
    case `${ON_INITIALIZE_APPLICATION_SAGA}_REJECTED`:
      return { ...state, onInitializeApplicationSaga: REJECTED };

    /* Saga sets payment on order when payment method is set to default */
    case `${ON_SET_DEFAULT_PAYMENT_SAGA}_PENDING`:
      return { ...state, onSetDefaultPaymentSaga: PENDING };
    case `${ON_SET_DEFAULT_PAYMENT_SAGA}_FULFILLED`:
      return { ...state, onSetDefaultPaymentSaga: FULFILLED };
    case `${ON_SET_DEFAULT_PAYMENT_SAGA}_REJECTED`:
      return { ...state, onSetDefaultPaymentSaga: REJECTED };

    /* Saga sets most recently created payment method as default */
    case `${ON_CREATE_PAYMENT_METHOD_SAGA}_PENDING`:
      return { ...state, onCreatePaymentMethodSaga: PENDING };
    case `${ON_CREATE_PAYMENT_METHOD_SAGA}_FULFILLED`:
      return { ...state, onCreatePaymentMethodSaga: FULFILLED };
    case `${ON_CREATE_PAYMENT_METHOD_SAGA}_REJECTED`:
      return { ...state, onCreatePaymentMethodSaga: REJECTED };

    /* Saga creates new order when order is successfully submitted */
    case `${ON_SUBMIT_ORDER_SAGA}_PENDING`:
      return { ...state, onSubmitOrderSaga: PENDING };
    case `${ON_SUBMIT_ORDER_SAGA}_FULFILLED`:
      return { ...state, onSubmitOrderSaga: FULFILLED };
    case `${ON_SUBMIT_ORDER_SAGA}_REJECTED`:
      return { ...state, onSubmitOrderSaga: REJECTED };

    /* Saga ensures menus and locations are in sync with current order's requested at */
    case `${ON_SET_REQUESTED_AT_SAGA}_PENDING`:
      return { ...state, onSetRequestedAtSaga: PENDING };
    case `${ON_SET_REQUESTED_AT_SAGA}_FULFILLED`:
      return { ...state, onSetRequestedAtSaga: FULFILLED };
    case `${ON_SET_REQUESTED_AT_SAGA}_REJECTED`:
      return { ...state, onSetRequestedAtSaga: REJECTED };

    default:
      return state;
  }
};
