import React from 'react';

import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';

import { Text } from 'components/base';

const Notifier = () => (
  <ToastContainer
    hideProgressBar
    draggable={false}
    closeButton={<Text variant="cardDetail">CLOSE</Text>}
  />
);

export default Notifier;
