import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'utils/get';

import {
  announcementBarData,
  link,
  linkWithSublinks,
  locale
} from 'constants/PropTypes';

import {
  hideFooterPaths,
  hideAnnouncementBarPaths,
  hideNavPaths,
  menuNavPaths
} from 'constants/HideComponentPaths';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { initializeApplication } from 'state/actions/applicationActions';
import { removeLineItemAndCloseConfigurationModal } from 'state/actions/brandibble/lineItemActions';
import { setActiveLocale } from 'state/actions/localeActions';
import {
  openMobileMenu,
  closeMobileMenu
} from 'state/actions/ui/mobileMenuActions';
import { setModal, resetModal } from 'state/actions/ui/modalActions';

import { FULFILLED } from 'constants/Status';

import {
  locationsDropdownData,
  storiesDropdownData,
  mobileMenuData,
  announcementData,
  userIsAuthenticated,
  currentOrderMenuPath
} from 'state/selectors';

import Routes from 'routes';

import Meta from 'components/Meta';
import AnnouncementBar from 'components/AnnouncementBar';
import Header from 'components/Header';
import Main from 'components/Main';
import Footer from 'components/Footer';
import MobileMenu from 'components/MobileMenu';
import AppLoader from 'components/AppLoader';
import Notifier from 'components/Notifier';
import ModalPortal from 'components/ModalPortal';
import Modal from 'components/Modal';
import SideCart from 'components/SideCart';

import DeviceWidthProvider, {
  DeviceWidthContext
} from 'context/DeviceWidthContext';
import NavThemeProvider from 'context/NavThemeContext';

import { classes as c } from 'styles';

class App extends Component {
  componentDidMount() {
    const { actions, applicationStatus } = this.props;

    if (applicationStatus !== FULFILLED) {
      actions.initializeApplication();
    }
  }

  render() {
    const {
      announcementData,
      applicationStatus,
      mobileMenuIsOpen,
      actions,
      locationsDropdownData,
      storiesDropdownData,
      mobileMenuData,
      locationTitle,
      allLocales,
      activeLocale,
      currentOrderMenuPath
    } = this.props;

    const isLoading = !!(applicationStatus !== FULFILLED);

    /*
      Check (by path) which elements should be hidden
      at specified routes
    */

    const hideAnnouncementBar = hideAnnouncementBarPaths.includes(
      get(this, 'props.location.pathname')
    );

    const hideFooter = hideFooterPaths.includes(
      get(this, 'props.location.pathname')
    );

    const renderSimpleHeader = hideNavPaths.includes(
      get(this, 'props.location.pathname')
    );

    const renderMenuOnlyHeader = menuNavPaths.includes(
      get(this, 'props.location.pathname').split('/')[3]
    );

    return (
      <DeviceWidthProvider>
        <NavThemeProvider>
          {!isLoading && (
            <Fragment>
              <Meta />
              {hideAnnouncementBar || renderMenuOnlyHeader ? null : (
                <AnnouncementBar data={announcementData} />
              )}
              <div className={c.relative}>
                {!renderMenuOnlyHeader && (
                  <Header
                    currentOrderMenuPath={currentOrderMenuPath}
                    locationTitle={locationTitle}
                    locationsDropdownData={locationsDropdownData}
                    storiesDropdownData={storiesDropdownData}
                    closeMobileMenu={actions.closeMobileMenu}
                    openMobileMenu={actions.openMobileMenu}
                    mobileMenuIsOpen={mobileMenuIsOpen}
                    announcementBarIsShowing={
                      !!get(announcementData, 'showAnnouncement', false) &&
                      !hideAnnouncementBar
                    }
                    renderSimpleHeader={renderSimpleHeader}
                    noHeader={renderMenuOnlyHeader}
                  />
                )}
                <Main>
                  <Routes
                    userIsAuthenticated={get(this, 'props.userIsAuthenticated')}
                    location={get(this, 'props.location')}
                  />
                </Main>
                <DeviceWidthContext.Consumer>
                  {context =>
                    renderMenuOnlyHeader ||
                    (hideFooter && !context.isMobile) ? null : (
                      <Footer
                        setActiveLocale={actions.setActiveLocale}
                        allLocales={allLocales}
                        activeLocale={activeLocale}
                      />
                    )
                  }
                </DeviceWidthContext.Consumer>
                <MobileMenu
                  mobileMenuData={mobileMenuData}
                  locationsDropdownData={locationsDropdownData}
                  closeMobileMenu={actions.closeMobileMenu}
                  mobileMenuIsOpen={mobileMenuIsOpen}
                />
                <SideCart />
              </div>
              <Notifier />
            </Fragment>
          )}
          <AppLoader loading={isLoading} />
          <ModalPortal>
            <Modal
              isVisible={get(this, 'props.modal.isVisible')}
              variant={get(this, 'props.modal.variant')}
              data={get(this, 'props.modal.data')}
              resetModal={get(this, 'props.actions.resetModal')}
              removeLineItemAndCloseConfigurationModal={get(
                this,
                'props.actions.removeLineItemAndCloseConfigurationModal'
              )}
            />
          </ModalPortal>
        </NavThemeProvider>
      </DeviceWidthProvider>
    );
  }
}

const mapStateToProps = state => ({
  announcementData: announcementData(state),
  applicationStatus: get(state, 'status.initializeApplication'),
  globalContentfulData: get(state, 'global.data'),
  mobileMenuIsOpen: get(state, 'mobileMenu.mobileMenuIsOpen', false),
  location: get(state, 'router.location'),
  locationTitle: get(state, 'header.locationTitle'),
  locationsDropdownData: locationsDropdownData(state),
  storiesDropdownData: storiesDropdownData(state),
  mobileMenuData: mobileMenuData(state),
  allLocales: get(state, 'locale.allLocales'),
  activeLocale: get(state, 'locale.activeLocale'),
  userIsAuthenticated: userIsAuthenticated(state),
  currentOrderMenuPath: currentOrderMenuPath(state),
  modal: get(state, 'modal')
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      initializeApplication,
      openMobileMenu,
      closeMobileMenu,
      setActiveLocale,
      setModal,
      resetModal,
      removeLineItemAndCloseConfigurationModal
    },
    dispatch
  )
});

App.defaultProps = {
  locationsDropdownData: [],
  storiesDropdownData: [],
  mobileMenuData: [],
  locationTitle: '',
  allLocales: [],
  activeLocale: ''
};

App.propTypes = {
  applicationStatus: PropTypes.string.isRequired,
  mobileMenuIsOpen: PropTypes.bool.isRequired,
  locationsDropdownData: PropTypes.arrayOf(
    PropTypes.oneOfType([link, linkWithSublinks])
  ),
  storiesDropdownData: PropTypes.arrayOf(link),
  announcementData: announcementBarData.isRequired,
  mobileMenuData: PropTypes.arrayOf(
    PropTypes.oneOfType([link, linkWithSublinks])
  ),
  allLocales: PropTypes.arrayOf(locale),
  activeLocale: PropTypes.string,
  locationTitle: PropTypes.string,
  actions: PropTypes.shape({
    initializeApplication: PropTypes.func.isRequired,
    openMobileMenu: PropTypes.func.isRequired,
    closeMobileMenu: PropTypes.func.isRequired
  }).isRequired
};

export { App };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
