import { EN_US, KO_KR } from 'constants/LocaleCodes';

// Translation JSON files
import en from 'constants/Language/Locales/en-US.json';
import kr from 'constants/Language/Locales/ko-KR.json';

// Available Locales map
const locales = {};
locales[EN_US] = en;
locales[KO_KR] = kr;

export default locales;
