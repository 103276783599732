import { createSelector } from 'reselect';
import _isEmpty from 'lodash/isEmpty';

import get from 'utils/get';

export default createSelector(
  state => get(state, 'brandibble.user'),
  brandibbleUser => {
    if (!brandibbleUser) return false;

    return (
      !_isEmpty(get(brandibbleUser, 'attributes', {})) &&
      !!get(brandibbleUser, 'attributes.customer_id')
    );
  }
);
