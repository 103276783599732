import { put, select } from 'redux-saga/effects';
import { paymentsAsArray, setPaymentMethod, Status } from 'brandibble-redux';
import get from 'utils/get';

const { PENDING, FULFILLED, REJECTED } = Status;

export const ON_SET_DEFAULT_PAYMENT_SAGA = 'ON_SET_DEFAULT_PAYMENT_SAGA';

function* onSetDefaultPaymentSaga() {
  const allPaymentMethods = yield select(state =>
    paymentsAsArray(get(state, 'brandibble'))
  );
  const defaultPaymentMethod = allPaymentMethods.find(paymentMethod =>
    get(paymentMethod, 'is_default', false)
  );

  if (defaultPaymentMethod) {
    yield put({ type: `${ON_SET_DEFAULT_PAYMENT_SAGA}_${PENDING}` });

    const orderRef = yield select(state =>
      get(state, 'brandibble.session.order.ref', {})
    );
    const paymentType = yield select(state =>
      get(state, 'brandibble.session.order.orderData.payment_type', 'credit')
    );

    yield put(setPaymentMethod(orderRef, paymentType, defaultPaymentMethod));
  }
}

/**
 * If all dispatched actions become FULFILLED
 * and ON_SET_DEFAULT_PAYMENT_SAGA is in a PENDING state
 * we can assume ON_SET_DEFAULT_PAYMENT_SAGA_FULFILLED
 */

function* handleOnSetDefaultPaymentSagaFulfilled() {
  const onSetDefaultPaymentSagaPending = yield select(
    state => state.status.onSetDefaultPaymentSaga === PENDING
  );
  const setPaymentMethodFulfilled = yield select(
    state => state.brandibble.status.setPaymentMethod === FULFILLED
  );

  if (onSetDefaultPaymentSagaPending && setPaymentMethodFulfilled) {
    yield put({ type: `${ON_SET_DEFAULT_PAYMENT_SAGA}_${FULFILLED}` });
  }
}

/**
 * If any dispatched actions become REJECTED
 * and ON_SET_DEFAULT_PAYMENT_SAGA is in a PENDING state
 * we can assume ON_SET_DEFAULT_PAYMENT_SAGA_REJECTED
 */

function* handleOnSetDefaultPaymentSagaRejected() {
  const onSetDefaultPaymentSagaPending = yield select(
    state => state.status.onSetDefaultPaymentSaga === PENDING
  );
  const setPaymentMethodRejected = yield select(
    state => state.brandibble.status.setPaymentMethod === REJECTED
  );

  if (onSetDefaultPaymentSagaPending && setPaymentMethodRejected) {
    yield put({ type: `${ON_SET_DEFAULT_PAYMENT_SAGA}_${REJECTED}` });
  }
}

export {
  handleOnSetDefaultPaymentSagaFulfilled,
  handleOnSetDefaultPaymentSagaRejected
};
export default onSetDefaultPaymentSaga;
