import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/base';

import cx from 'classnames';
import v from 'vudu';
import { classes as c, format as f, styles as s } from 'styles';

const classes = v({
  checkboxContainer: {
    '@composes': [s.relative],
    width: '24px',
    height: '24px'
  },
  checkbox: {
    '@composes': [s.absolute, s.pointer, s.z1],
    width: '100%',
    height: '100%',
    opacity: 0
  },
  customInput: {
    '@composes': [
      s.absolute,
      s.borderGray,
      s.flex,
      s.alignCenter,
      s.justifyCenter
    ],
    width: '100%',
    height: '100%',
    borderRadius: '50%'
  },
  customInputChecked: {
    '::after': {
      '@composes': [s.block, s.bgBlack],
      content: "''",
      width: '10px',
      height: '10px',
      borderRadius: '50%'
    }
  }
});

const CheckboxInput = ({ className, name, label, checked, onChange }) => (
  <div className={className}>
    <label
      className={f(c.flex, c.alignCenter)}
      htmlFor={name}
      onClick={onChange}
    >
      <span className={classes.checkboxContainer}>
        <input
          className={classes.checkbox}
          name={name}
          type="checkbox"
          defaultChecked={checked}
        />
        <span
          className={cx(classes.customInput, {
            [classes.customInputChecked]: checked
          })}
        />
      </span>
      <Text className={c.ml4} variant="paragraph">
        {label}
      </Text>
    </label>
  </div>
);

CheckboxInput.defaultProps = {
  className: '',
  name: '',
  label: '',
  checked: false,
  onChange: f => f
};

CheckboxInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

export default CheckboxInput;
