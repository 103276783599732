export const OPEN_SIDE_CART = 'OPEN_SIDE_CART';
export const CLOSE_SIDE_CART = 'CLOSE_SIDE_CART';

export const openSideCart = () => ({
  type: OPEN_SIDE_CART
});

export const closeSideCart = () => ({
  type: CLOSE_SIDE_CART
});
