import { FETCH_GLOBAL_DATA } from 'state/actions/contentfulActions';

const initialState = {
  data: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${FETCH_GLOBAL_DATA}_PENDING`:
      return { ...state };
    case `${FETCH_GLOBAL_DATA}_FULFILLED`:
      return { ...state, data: payload };

    case `${FETCH_GLOBAL_DATA}_REJECTED`:
    default:
      return state;
  }
};
