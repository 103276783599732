import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'utils/get';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setModal } from 'state/actions/ui/modalActions';
import { openSideCart } from 'state/actions/ui/sideCartActions';
import { CREATE_ORDER_MODAL } from 'constants/ModalVariants';

import { Text } from 'components/base';

import cx from 'classnames';
import v from 'vudu';
import { breakpoints, styles as s } from 'styles';

const classes = v({
  OrderButtonMobile: {
    '@composes': [s.flex, s.alignCenter, s.pointer],
    width: '100%',
    borderRadius: '1px',
    transition: '250ms ease-in-out',
    ':hover': {
      span: {
        opacity: 0.8
      }
    }
  },
  textWrapper: {
    '@composes': [s.transitionOpacity],

    [breakpoints.md]: {
      '@composes': [s.px6]
    }
  },
  quantWrapper: {
    '@composes': [s.py2, s.px4, s.transitionAll],

    [breakpoints.md]: {
      '@composes': [s.px5]
    }
  }
});

class OrderButtonMobile extends PureComponent {
  handleClick = () => {
    const { actions, cart } = this.props;

    const hasOrder = !!cart && cart.length;
    if (hasOrder) {
      return get(actions, 'openSideCart', f => f)();
    }

    return get(actions, 'setModal', f => f)(CREATE_ORDER_MODAL);
  };

  render() {
    const className = get(this, 'props.className');
    const quantity = get(this, 'props.cart', []).reduce(
      (totalQuantity, currentItem) =>
        parseInt(totalQuantity, 10) +
        parseInt(get(currentItem, 'quantity', 0), 10),
      0
    );
    const hasQuantity = quantity >= 1;

    return (
      <button
        onClick={this.handleClick}
        className={cx(classes.OrderButtonMobile, { [className]: !!className })}
        type="button"
      >
        <span className={classes.textWrapper}>
          <Text variant="accordionLabel" color="darkerGray">
            Order
          </Text>
        </span>
        <span className={cx({ [classes.quantWrapper]: hasQuantity })}>
          <Text variant="accordionLabel" color="white">
            {hasQuantity ? quantity : ''}
          </Text>
        </span>
      </button>
    );
  }
}

const mapStateToProps = state => ({
  cart: get(state, 'brandibble.session.order.orderData.cart', [])
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setModal,
      openSideCart
    },
    dispatch
  )
});

OrderButtonMobile.defaultProps = {
  className: ''
};

OrderButtonMobile.propTypes = {
  className: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderButtonMobile);
