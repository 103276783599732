import { createSelector } from 'reselect';

import { allLocations } from 'state/selectors';

import get from 'utils/get';

export default createSelector(
  state => allLocations(state),
  state => get(state, 'locationsFinder.selectedCity'),
  (allLocations, selectedCity) => {
    if (!selectedCity) return [];

    return allLocations.filter(location => location.city === selectedCity);
  }
);
