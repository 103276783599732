import colors from './colors';

const shadows = {
  headerShadow: {
    boxShadow: `0.1875rem 1.125rem 1.5625rem 0 ${colors.shadow}`
  },
  dropdownShadow: {
    boxShadow: `0 0.3125rem 1.25rem 0 ${colors.shadow}`
  },
  flagShadow: {
    boxShadow: `0.0625rem 0.0625rem 0.625rem 0 ${colors.lightGray}`
  },
    noShadow: {
    textShadow: `0 0 0 0 ${colors.transparent}`
  }
};

export default shadows;
