import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import v from 'vudu';
import { classes as c, colors, format as f, styles as s } from 'styles';

const classes = v({
  quantitySpinner: {
    '@composes': [s.inlineBlock, s.height100],
    minWidth: `112px`,
    border: `solid 1px ${colors.gold}`,
    borderRadius: '2px'
  },
  quantitySpinnerInner: {
    '@composes': [
      s.modalTabLabel,
      s.flex,
      s.spaceAround,
      s.alignCenter,
      s.height100
    ]
  },
  hidden: {
    '@composes': [s.absolute, s.transitionTransform],
    opacity: 0,
    pointerEvents: 'none',
    transform: 'scale(0)'
  },
  showing: {
    '@composes': [s.flex1],
    position: 'static',
    transform: 'scale(1)',
    opacity: 1,
    pointerEvents: 'auto'
  },
  disabled: {
    color: colors.gray,
    cursor: 'not-allowed'
  }
});

const QuantitySpinner = ({
  quantity,
  handleDecrement,
  handleIncrement,
  handleConfiguration,
  canIncrement,
  canDecrement,
  className,
  isConfigurable
}) => {
  const hasQuantity = quantity && quantity >= 1;

  const renderQuantSpinnerInner = () => {
    if (isConfigurable) {
      return (
        <button
          type="button"
          className={cx(
            f(c.py3, c.flex1, c.modalTabLabel, c.pointer, c.hoverOpacity)
          )}
          onClick={handleConfiguration}
        >
          {'ADD'}
        </button>
      );
    }

    return (
      <Fragment>
        <button
          type="button"
          className={cx(f(classes.hidden, c.py3, c.pointer, c.hoverOpacity), {
            [classes.showing]: hasQuantity,
            [classes.disabled]: !canDecrement
          })}
          onClick={canDecrement ? handleDecrement : f => f}
        >
          -
        </button>
        <span
          className={cx(f(classes.hidden, c.center, c.py3), {
            [classes.showing]: hasQuantity
          })}
        >
          {quantity}
        </span>
        <button
          type="button"
          className={cx(
            f(c.py3, c.flex1, c.modalTabLabel, c.pointer, c.hoverOpacity),
            {
              [classes.disabled]: !canIncrement
            }
          )}
          onClick={canIncrement ? handleIncrement : f => f}
        >
          {hasQuantity ? '+' : 'ADD'}
        </button>
      </Fragment>
    );
  };

  return (
    <div className={cx(classes.quantitySpinner, { [className]: !!className })}>
      <div className={classes.quantitySpinnerInner}>
        {renderQuantSpinnerInner()}
      </div>
    </div>
  );
};

QuantitySpinner.defaultProps = {
  className: '',
  quantity: 0,
  canIncrement: true,
  canDecrement: true,
  handleIncrement: f => f,
  handleDecrement: f => f,
  handleConfiguration: f => f,
  isConfigurable: false
};

QuantitySpinner.propTypes = {
  className: PropTypes.string,
  quantity: PropTypes.number,
  canIncrement: PropTypes.bool,
  canDecrement: PropTypes.bool,
  handleIncrement: PropTypes.func,
  handleDecrement: PropTypes.func,
  handleConfiguration: PropTypes.func,
  isConfigurable: PropTypes.bool
};

export default QuantitySpinner;
