import React from 'react';
import PropTypes from 'prop-types';
import { classes as c } from 'styles';

const defaultColor = variant => {
  switch (variant) {
    case 'navItem':
    default:
      return 'black';
  }
};

const variantDefault = (variant, style, combined, children) => {
  switch (variant) {
    case 'navItem':
    case 'dropdownNavItem':
      return (
        <span style={style} className={combined}>
          {children}
        </span>
      );
    case 'title':
    case 'titleLarge':
    case 'subheading':
    case 'eyebrow':
      return (
        <h2 style={style} className={combined}>
          {children}
        </h2>
      );

    default:
      return (
        <p style={style} className={combined}>
          {children}
        </p>
      );
  }
};

const elementOverride = (el, style, combined, children) => {
  switch (el) {
    case 'h1':
      return (
        <h1 style={style} className={combined}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 style={style} className={combined}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 style={style} className={combined}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 style={style} className={combined}>
          {children}
        </h4>
      );
    case 'h5':
      return (
        <h5 style={style} className={combined}>
          {children}
        </h5>
      );
    case 'h6':
      return (
        <h6 style={style} className={combined}>
          {children}
        </h6>
      );
    case 'span':
      return (
        <span style={style} className={combined}>
          {children}
        </span>
      );
    default:
      return (
        <p style={style} className={combined}>
          {children}
        </p>
      );
  }
};

const Text = props => {
  let { variant, style, children, color, className, el } = props;
  const combined = `${c[color || defaultColor(variant)]} ${className} ${
    c[variant]
  }`;
  style = { ...style };

  if (el) return elementOverride(el, style, combined, children);
  return variantDefault(variant, style, combined, children);
};

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array
  ]),
  variant: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  el: PropTypes.string
};

Text.defaultProps = {
  style: {},
  el: '',
  variant: '',
  children: '',
  className: ''
};

export default Text;
