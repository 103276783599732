import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { brandibbleRef, status } from 'constants/PropTypes';

import Language from 'constants/Language';
import { PENDING } from 'constants/Status';
import { InputTypes, ErrorObjectKeys } from 'constants/Forms';
import {
  handleServerError,
  handleValidationErrorMessage,
  validateInput,
  validateForm
} from 'utils/formUtils';

import { Button } from 'components/base';
import TextInput from './TextInput';

import { classes as c, format as f } from 'styles';

const { PASSWORD, CONFIRM_PASSWORD } = InputTypes;
const { ERROR_MESSAGE, SHOW_ERROR_MESSAGE } = ErrorObjectKeys;

class ResetPasswordForm extends PureComponent {
  constructor(props) {
    super(...arguments);

    this.state = {
      values: {
        [PASSWORD]: '',
        [CONFIRM_PASSWORD]: ''
      },
      errors: {
        [PASSWORD]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        },
        [CONFIRM_PASSWORD]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        }
      },
      formIsValid: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.submitError && this.props.submitError) {
      const { values, errors } = this.state;
      return handleServerError(this.props.submitError, values, errors, state =>
        this.setState(state)
      );
    }
  }

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      values: { ...prevState.values, [target.name]: target.value }
    }));
  };

  handleBlur = ({ target }) => {
    const { values, errors } = this.state;
    handleValidationErrorMessage(target.name, values, errors, state =>
      this.setState(state)
    );
  };

  handleKeyUp = ({ target }) => {
    validateInput(target.name, this.state.values, this.state.errors, state => {
      this.setState(state);
      this.checkFormIsValid(state);
    });
  };

  checkFormIsValid = nextState => {
    const formIsValid = validateForm(nextState.values, nextState.errors);

    if (formIsValid) {
      return this.setState({ formIsValid: true });
    }

    this.setState({ formIsValid: false });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { values } = this.state;
    const { onSubmit, token, brandibbleRef } = this.props;

    return onSubmit(brandibbleRef, token, { password: values[PASSWORD] });
  };

  render() {
    const { values, errors } = this.state;
    const { submitStatus } = this.props;
    const isSubmitting = submitStatus === PENDING;

    return (
      <form onSubmit={this.handleSubmit}>
        <TextInput
          name={PASSWORD}
          type="password"
          value={values[PASSWORD]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          error={
            errors[PASSWORD][SHOW_ERROR_MESSAGE]
              ? errors[PASSWORD][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          name={CONFIRM_PASSWORD}
          type="password"
          label="Confirm Password"
          value={values[CONFIRM_PASSWORD]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          error={
            errors[CONFIRM_PASSWORD][SHOW_ERROR_MESSAGE]
              ? errors[CONFIRM_PASSWORD][ERROR_MESSAGE]
              : ''
          }
        />
        <div className={f(c.flex, c.alignCenter, c.justifyEnd)}>
          <Button
            showLoading={isSubmitting}
            isDisabled={!this.state.formIsValid}
            type="submit"
            variant="goldBrick"
            text={Language.t('resetPasswordForm.reset')}
          />
        </div>
      </form>
    );
  }
}

ResetPasswordForm.defaultProps = {
  onSubmit: f => f
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  submitStatus: status,
  brandibbleRef: brandibbleRef
};

export default ResetPasswordForm;
