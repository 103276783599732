import {
  OPEN_MOBILE_MENU,
  CLOSE_MOBILE_MENU
} from 'state/actions/ui/mobileMenuActions';

const initialState = {
  mobileMenuIsOpen: false
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case OPEN_MOBILE_MENU:
      return { ...state, mobileMenuIsOpen: true };
    case CLOSE_MOBILE_MENU:
      return { ...state, mobileMenuIsOpen: false };
    default:
      return state;
  }
};
