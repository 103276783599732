import React, { Component } from 'react';

import { navTheme as colorMap, LIGHT } from 'constants/ColorMaps';

const NavThemeContext = React.createContext({});

class NavThemeProvider extends Component {
  state = {
    color: { name: colorMap[LIGHT].name, hex: colorMap[LIGHT].hex }
  };

  setNavTheme = color =>
    this.setState({
      color: { name: colorMap[color].name, hex: colorMap[color].hex }
    });

  render() {
    return (
      <NavThemeContext.Provider
        value={{ color: this.state.color, setNavTheme: this.setNavTheme }}
      >
        {this.props.children}
      </NavThemeContext.Provider>
    );
  }
}

export { NavThemeContext };
export default NavThemeProvider;
