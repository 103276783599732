import { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

class Accordion extends Component {
  state = {
    openedPanelLabel: null
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.closeAll && this.props.closeAll) {
      this.setState({ openedPanelLabel: null });
    }
  }

  toggleOpen = label => {
    const { openedPanelLabel } = this.state;

    if (label === openedPanelLabel)
      return this.setState({ openedPanelLabel: null });
    this.setState({ openedPanelLabel: label });
  };

  render() {
    const { openedPanelLabel } = this.state;
    const { children } = this.props;

    return Children.map(children, (child, index) =>
      cloneElement(child, {
        isOpen: child.props.label === openedPanelLabel,
        toggleOpen: this.toggleOpen
      })
    );
  }
}

Accordion.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  closeAll: PropTypes.bool
};

export default Accordion;
