import transitions from './transitions';

const hover = {
  hoverOpacity: {
    ...transitions.transitionOpacity,
    opacity: 1,
    ':hover': {
      opacity: 0.8,

    }
  }
};

export { hover };
