import { all, put, select } from 'redux-saga/effects';
import {
  fetchPayments,
  fetchAllCustomerOrders,
  bindCustomerToOrder,
  Status
} from 'brandibble-redux';
import get from 'utils/get';

const { PENDING, FULFILLED, REJECTED } = Status;

export const ON_AUTHENTICATION_SAGA = 'ON_AUTHENTICATION_SAGA';

function* onAuthenticationSaga() {
  yield put({ type: `${ON_AUTHENTICATION_SAGA}_${PENDING}` });

  const brandibble = yield select(state => state.brandibble);

  const brandibbleRef = get(brandibble, 'ref');
  const orderRef = get(brandibble, 'session.order.ref');
  const customerAttributes = get(brandibble, 'user.attributes');
  const customerId = get(customerAttributes, 'customer_id');
  const includeItemDetails = true;

  yield all([
    put(fetchPayments(brandibbleRef)),
    put(fetchAllCustomerOrders(brandibbleRef, customerId, includeItemDetails)),
    put(bindCustomerToOrder(orderRef, customerAttributes))
  ]);
}

/**
 * If all dispatched actions become FULFILLED
 * and ON_AUTHENTICATION_SAGA is in a PENDING state
 * we can assume ON_AUTHENTICATION_SAGA_FULFILLED
 */

function* handleOnAuthenticationSagaFulfilled() {
  const onAuthenticationSagaPending = yield select(
    state => state.status.onAuthenticationSaga === PENDING
  );
  const fetchPaymentsFulfilled = yield select(
    state => state.brandibble.status.fetchPayments === FULFILLED
  );
  const fetchAllCustomerOrdersFulfilled = yield select(
    state => state.brandibble.status.fetchAllCustomerOrders === FULFILLED
  );

  if (
    onAuthenticationSagaPending &&
    fetchPaymentsFulfilled &&
    fetchAllCustomerOrdersFulfilled
  ) {
    yield put({ type: `${ON_AUTHENTICATION_SAGA}_${FULFILLED}` });
  }
}

/**
 * If any dispatched actions become REJECTED
 * and ON_AUTHENTICATION_SAGA is in a PENDING state
 * we can assume ON_AUTHENTICATION_SAGA_REJECTED
 */

function* handleOnAuthenticationSagaRejected() {
  const onAuthenticationSagaPending = yield select(
    state => state.status.onAuthenticationSaga === PENDING
  );
  const fetchPaymentsRejected = yield select(
    state => state.brandibble.status.fetchPayments === REJECTED
  );
  const fetchAllCustomerOrdersRejected = yield select(
    state => state.brandibble.status.fetchAllCustomerOrders === REJECTED
  );

  if (
    onAuthenticationSagaPending &&
    (fetchPaymentsRejected || fetchAllCustomerOrdersRejected)
  ) {
    yield put({ type: `${ON_AUTHENTICATION_SAGA}_${REJECTED}` });
  }
}

export {
  handleOnAuthenticationSagaFulfilled,
  handleOnAuthenticationSagaRejected
};
export default onAuthenticationSaga;
