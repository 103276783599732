import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import v from 'vudu';
import { classes as c, colors, format as f, styles as s } from 'styles';

const classes = v({
  quantitySpinner: {
    '@composes': [s.inlineBlock, s.flex],
    minWidth: `112px`,
    border: `solid 1px ${colors.gold}`,
    borderRadius: '2px'
  },
  quantitySpinnerInner: {
    '@composes': [s.modalTitle, s.flex, s.justifyCenter, s.spaceAround, s.flex1]
  },
  remove: {
    '@composes': [s.flex, s.flex1, s.justifyCenter, s.alignCenter],
    borderLeft: `solid 1px ${colors.gold}`
  },
  hidden: {
    '@composes': [s.absolute, s.transitionTransform],
    opacity: 0,
    pointerEvents: 'none',
    transform: 'scale(0)'
  },
  showing: {
    '@composes': [s.flex1],
    position: 'static',
    transform: 'scale(1)',
    opacity: 1,
    pointerEvents: 'auto'
  },
  disabled: {
    color: colors.gray,
    cursor: 'not-allowed'
  }
});

const QuantitySpinnerWithRemove = ({
  quantity,
  handleDecrement,
  handleIncrement,
  canIncrement,
  canDecrement,
  handleRemove
}) => {
  const hasQuantity = quantity && quantity >= 1;
  return (
    <div className={classes.quantitySpinner}>
      <div className={classes.quantitySpinnerInner}>
        <button
          type="button"
          className={cx(f(classes.hidden, c.py3, c.pointer, c.hoverOpacity), {
            [classes.showing]: hasQuantity,
            [classes.disabled]: !canDecrement
          })}
          onClick={canDecrement ? handleDecrement : f => f}
        >
          -
        </button>
        <span
          className={cx(f(classes.hidden, c.center, c.py3), {
            [classes.showing]: hasQuantity
          })}
        >
          {quantity}
        </span>
        <button
          type="button"
          className={cx(
            f(c.py3, c.flex1, c.modalTitle, c.pointer, c.hoverOpacity),
            {
              [classes.disabled]: !canIncrement
            }
          )}
          onClick={canIncrement ? handleIncrement : f => f}
        >
          {hasQuantity ? '+' : 'ADD'}
        </button>
      </div>
      <div className={classes.remove}>
        <button
          type="button"
          onClick={handleRemove}
          className={f(c.py3, c.flex1, c.modalTitle, c.pointer, c.hoverOpacity)}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

QuantitySpinnerWithRemove.defaultProps = {
  quantity: 0,
  handleIncrement: f => f,
  handleDecrement: f => f,
  canIncrement: true,
  canDecrement: true,
  handleRemove: f => f
};

QuantitySpinnerWithRemove.propTypes = {
  quantity: PropTypes.number,
  handleIncrement: PropTypes.func,
  handleDecrement: PropTypes.func,
  canIncrement: PropTypes.bool,
  canDecrement: PropTypes.bool,
  handleRemove: PropTypes.func
};

export default QuantitySpinnerWithRemove;
