import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'utils/get';
import { brandibbleRef, status } from 'constants/PropTypes';

import { InputTypes, ErrorObjectKeys } from 'constants/Forms';
import {
  handleValidationErrorMessage,
  validateInput,
  validateForm,
  handleServerError
} from 'utils/formUtils';

import TextInput from './TextInput';

const {
  FIRST_NAME,
  LAST_NAME,
  PHONE,
  EMAIL,
  PASSWORD,
  CONFIRM_PASSWORD
} = InputTypes;
const { ERROR_MESSAGE, SHOW_ERROR_MESSAGE } = ErrorObjectKeys;

class GuestPasswordForm extends PureComponent {
  constructor(props) {
    super(...arguments);

    this.state = {
      values: {
        [PASSWORD]: '',
        [CONFIRM_PASSWORD]: ''
      },
      errors: {
        [PASSWORD]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        },
        [CONFIRM_PASSWORD]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        }
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.submitError && this.props.submitError) {
      const { values, errors } = this.state;
      return handleServerError(this.props.submitError, values, errors, state =>
        this.setState(state)
      );
    }
  }

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      values: { ...prevState.values, [target.name]: target.value }
    }));
  };

  handleBlur = ({ target }) => {
    const { values, errors } = this.state;
    handleValidationErrorMessage(target.name, values, errors, state =>
      this.setState(state)
    );
  };

  handleKeyUp = ({ target }) => {
    validateInput(target.name, this.state.values, this.state.errors, state => {
      this.setState(state);
      this.checkFormIsValid(state);
    });
  };

  checkFormIsValid = nextState => {
    const formIsValid = validateForm(nextState.values, nextState.errors);

    if (formIsValid) {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const { onSubmit, orderRef } = this.props;
    const { values } = this.state;

    onSubmit(orderRef, {
      first_name: get(orderRef, `customer.${[FIRST_NAME]}`, ''),
      last_name: get(orderRef, `customer.${[LAST_NAME]}`, ''),
      phone: get(orderRef, `customer.${[PHONE]}`, ''),
      email: get(orderRef, `customer.${[EMAIL]}`, ''),
      password: values[PASSWORD]
    });
  };

  render() {
    const { values, errors } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <TextInput
          name={PASSWORD}
          type="password"
          value={values[PASSWORD]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          error={
            errors[PASSWORD][SHOW_ERROR_MESSAGE]
              ? errors[PASSWORD][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          name={CONFIRM_PASSWORD}
          type="password"
          label="Confirm Password"
          value={values[CONFIRM_PASSWORD]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          error={
            errors[CONFIRM_PASSWORD][SHOW_ERROR_MESSAGE]
              ? errors[CONFIRM_PASSWORD][ERROR_MESSAGE]
              : ''
          }
        />
      </form>
    );
  }
}

GuestPasswordForm.defaultProps = {
  onSubmit: f => f
};

GuestPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  submitStatus: status,
  brandibbleRef: brandibbleRef
};

export default GuestPasswordForm;
