export const breakpoints = {
  sm: '@media (min-width: 40em)', // 640px
  md: '@media (min-width: 52em)', // 832px
  lg: '@media (min-width: 64em)', // 1024px
  xl: '@media (min-width: 76em)', // 1216
  xx: '@media (min-width: 88em)' // 1408
};

const grid = {
  col: { float: 'left' },
  floatRight: { float: 'right' },
  row: {
    ':after': {
      content: '""',
      display: 'table',
      clear: 'both'
    }
  }
};

/* Private */
function setupGridValues(count) {
  const gridArr = [];
  for (let i = 1; i < count + 1; i += 1) {
    gridArr.push(`${((i / count) * 100).toFixed(2)}%`);
  }
  return gridArr;
}

function createOffsets(gridValues) {
  const offsets = {};
  gridValues.forEach((offset, i) => {
    offsets[`offsetRight${i + 1}`] = { marginRight: offset };
    offsets[`offsetLeft${i + 1}`] = { marginLeft: offset };
  });
  return offsets;
}

function addOffsetBreakpoints(
  offsets,
  count,
  gridValues,
  sides = ['left', 'right']
) {
  Object.keys(breakpoints).forEach(b => {
    sides.forEach(s => {
      if (s === 'left') {
        for (let i = 0; i < count; i += 1) {
          const mediaQuery = {};
          mediaQuery[breakpoints[b]] = { marginLeft: gridValues[i] };
          offsets[`${b}OffsetLeft${i + 1}`] = mediaQuery;
        }
      } else if (s === 'right') {
        for (let i = 0; i < count; i += 1) {
          const mediaQuery = {};
          mediaQuery[breakpoints[b]] = { marginRight: gridValues[i] };
          offsets[`${b}OffsetRight${i + 1}`] = mediaQuery;
        }
      }
    });
  });
}

function buildOffsets() {
  const count = 12;
  const gridValues = setupGridValues(count);
  const offsets = createOffsets(gridValues);
  addOffsetBreakpoints(offsets, count, gridValues);
  return offsets;
}

export const offsets = buildOffsets();

export default grid;
