import Vars from 'constants/Vars';
import grainy from 'assets/grainy.png';
import { breakpoints } from './grid';
import { size } from './sizing';
import colors from './colors';

const { headerHeight, paragraphLineHeight } = Vars;

const utils = {
  siteMaxWidth: {
    padding: `0 ${size('gutter')}`,
    margin: `0 auto`,
    maxWidth: `${Vars.siteMaxWidth}px`
  },
  formMaxWidth: {
    padding: `0 ${size('gutter')}`,
    margin: `0 auto`,
    maxWidth: `${Vars.formMaxWidth}px`
  },
  modalFormMaxWidth: {
    maxWidth: `${Vars.modalFormMaxWidth}px`
  },
  blockSpacer: {
    paddingTop: `1.25rem`,
    paddingBottom: `1.25rem`,
    [breakpoints.md]: {
      paddingTop: `2.4rem`,
      paddingBottom: `2.4rem`
    }
  },
  headerPadding: {
    paddingTop: `${headerHeight}px`
  },
  min100vh: { minHeight: `100vh` },
  min100vw: { minWidth: `100vw` },
  pointer: { cursor: `pointer` },
  borderGray: { border: `solid 1px ${colors.gray}` },
  borderTopGray: { borderTop: `solid 1px ${colors.gray}` },
  borderBottomGray: { borderBottom: `solid 1px ${colors.gray}` },
  height100: { height: `100%` },
  bgImageDefault: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  rotate180: {
    transform: 'rotate(180deg)'
  },
  textLeft: { textAlign: 'left' },
  textCenter: { textAlign: 'center' },
  textRight: { textAlign: 'right' },
  uppercase: { textTransform: 'uppercase' },
  lowercase: { textTransform: 'lowercase' },
  capitalize: { textTransform: 'capitalize' },
  overflowGradientWhite: {
    background: `linear-gradient(to top, ${colors.white}, rgba(255,255,255,0))`
  },
  overflowGradientOffWhite: {
    background: `linear-gradient(to top, ${
      colors.offwhite
    }, rgb(253, 249, 245,0))`
  },
  grainyOverlay: {
    '::before': {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '0px',
      left: '0px',
      content: "''",
      backgroundImage: `url(${grainy})`
    }
  },
  resizeNone: {
    resize: 'none'
  },
  twoLineEllipsis: {
    height: `calc(${paragraphLineHeight}px * 2)`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  bgImageCentered: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%'
  },
  linkUnderlines: {
    textDecorationLine: 'underline',
    "&:hover": {
      textDecorationLine: 'none',
    },
    "&:focus": {
      textDecorationLine: 'none',
    }
  },
    linknoUnderlines: {
    textDecorationLine: 'none',
    "&:hover": {
      textDecorationLine: 'underline',
    },
    "&:focus": {
      textDecorationLine: 'underline',
    }
  }
};

export default utils;
