import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import get from 'utils/get';
import { freezeScroll, unfreezeScroll } from 'utils/manageScrollingElement';
import Language from 'constants/Language';
import Vars from 'constants/Vars';
import { CREATE_ORDER_MODAL } from 'constants/ModalVariants';
import FocusTrap from 'focus-trap-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeSideCart } from 'state/actions/ui/sideCartActions';
import { setModal } from 'state/actions/ui/modalActions';
import { cartSummaryData, currentOrderMenuPath } from 'state/selectors';

import CartSummary from 'components/CartSummary';

import cx from 'classnames';
import v from 'vudu';
import {
  breakpoints,
  classes as c,
  colors,
  styles as s,
  format as f,
  size
} from 'styles';

const { sideCartWidth } = Vars;

const classes = v({
  sideCartContainer: {
    '@composes': [
      s.zSideCart,
      s.fixed,
      s.t0,
      s.r0,
      s.b0,
      s.l0,
      s.transitionOpacity
    ],
    transitionDelay: `50ms`,
    opacity: 0,
    pointerEvents: 'none'
  },
  sideCartContainerOpen: {
    opacity: 1,
    pointerEvents: 'auto'
  },
  sideCartUnderlay: {
    display: 'none',
    [breakpoints.md]: {
      '@composes': [
        s.block,
        s.absolute,
        s.col12,
        s.height100,
        s.transitionOpacity
      ],
      backgroundColor: colors.gray,
      opacity: 0
    }
  },
  sideCartUnderlayActive: {
    opacity: 0.75
  },
  sideCart: {
    '@composes': [
      s.z1,
      s.col12,
      s.absolute,
      s.t0,
      s.r0,
      s.b0,
      s.l0,
      s.transitionTransform
    ],
    backgroundColor: colors.white,
    transform: `translateX(100%)`,

    [breakpoints.md]: {
      width: `${sideCartWidth}px`,
      left: 'auto'
    }
  },
  sideCartOpen: {
    '@composes': [s.transitionTransform],
    transitionDelay: `50ms`,
    transform: `translateX(0)`
  },
  sideCartInner: {
    '@composes': [s.px10, s.py7, s.height100]
  },
  orderSummaryContainer: {
    '@composes': [s.pt7, s.height100],
    [breakpoints.md]: {
      paddingTop: `calc(${size(11)} * 2)`
    }
  }
});

class SideCart extends PureComponent {
  componentDidUpdate(prevProps) {
    const hadCartItems = get(prevProps, 'orderData.cart', []).length;
    const hasCartItems = get(this, 'props.orderData.cart', []).length;

    const sideCartWasOpen = get(prevProps, 'sideCartIsOpen', false);
    const sideCartIsOpen = get(this, 'props.sideCartIsOpen', false);

    const modalWasOpen = get(prevProps, 'modalIsVisible', false);
    const modalIsOpen = get(this, 'props.modalIsVisible', false);

    /* 
      Prevent scroll and retain scroll 
      top when cart is open, reverse if 
      cart becomes closed
    */
    if (!sideCartWasOpen && sideCartIsOpen) {
      freezeScroll();
    }

    if (sideCartWasOpen && !sideCartIsOpen) {
      unfreezeScroll();
    }

    /* 
      All items were removed from  cart
    */
    if (hadCartItems && !hasCartItems && sideCartIsOpen) {
      get(this, 'props.actions.closeSideCart', f => f)();
    }

    /* 
      Modal was triggered with cart open
    */
    if (sideCartIsOpen && !modalWasOpen && modalIsOpen) {
      get(this, 'props.actions.closeSideCart', f => f)();
    }
  }

  handleChangeLocation = () => {
    const setModal = get(this, 'props.actions.setModal', f => f);

    return setModal(CREATE_ORDER_MODAL);
  };

  render() {
    const sideCartIsOpen = get(this, 'props.sideCartIsOpen', false);
    const closeSideCart = get(this, 'props.actions.closeSideCart', f => f);

    return (
      <FocusTrap
        active={sideCartIsOpen}
        focusTrapOptions={{
          escapeDeactivates: false,
          returnFocusOnDeactivate: true
        }}
      >
        <div
          className={cx(classes.sideCartContainer, {
            [classes.sideCartContainerOpen]: sideCartIsOpen
          })}
        >
          <div
            className={cx(classes.sideCart, {
              [classes.sideCartOpen]: sideCartIsOpen
            })}
          >
            <div className={classes.sideCartInner}>
              <div className={f(c.flex, c.spaceBetween)}>
                <button
                  type="button"
                  onClick={closeSideCart}
                  className={f(
                    c.modalTabLabel,
                    c.monoBold,
                    c.hoverOpacity,
                    c.pointer
                  )}
                >
                  {Language.t('sideCart.close')}
                </button>
                <button
                  type="button"
                  onClick={this.handleChangeLocation}
                  className={f(
                    c.modalTabLabel,
                    c.monoBold,
                    c.hoverOpacity,
                    c.pointer
                  )}
                >
                  {Language.t('sideCart.changeLocation')}
                </button>
              </div>
              <div className={classes.orderSummaryContainer}>
                <CartSummary
                  cartSummaryData={get(this, 'props.cartSummaryData')}
                  closeSideCart={get(
                    this,
                    'props.actions.closeSideCart',
                    f => f
                  )}
                  sideCartIsOpen={sideCartIsOpen}
                  currentOrderMenuPath={get(this, 'props.currentOrderMenuPath')}
                />
              </div>
            </div>
          </div>
          <div
            onClick={closeSideCart}
            className={cx(classes.sideCartUnderlay, {
              [classes.sideCartUnderlayActive]: sideCartIsOpen
            })}
          />
        </div>
      </FocusTrap>
    );
  }
}

const mapStateToProps = state => ({
  modalIsVisible: get(state, 'modal.isVisible', false),
  sideCartIsOpen: get(state, 'sideCart.sideCartIsOpen', false),
  orderData: get(state, 'brandibble.session.order.orderData', {}),
  cartSummaryData: cartSummaryData(state),
  currentOrderMenuPath: currentOrderMenuPath(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeSideCart,
      setModal
    },
    dispatch
  )
});

SideCart.defaultProps = {
  sideCartIsOpen: false,
  actions: {
    closeSideCart: f => f
  }
};

SideCart.propTypes = {
  actions: PropTypes.shape({
    closeSideCart: PropTypes.func
  }),
  sideCartIsOpen: PropTypes.bool
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideCart);
