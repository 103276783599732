import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { brandibbleRef, status } from 'constants/PropTypes';

import Language from 'constants/Language';
import { InputTypes, ErrorObjectKeys } from 'constants/Forms';
import { PENDING } from 'constants/Status';
import {
  handleServerError,
  handleValidationErrorMessage,
  validateInput,
  validateForm
} from 'utils/formUtils';

import { Button } from 'components/base';
import TextInput from './TextInput';

import { classes as c, format as f } from 'styles';

const { FIRST_NAME, LAST_NAME, EMAIL, PHONE, PASSWORD } = InputTypes;
const { ERROR_MESSAGE, SHOW_ERROR_MESSAGE } = ErrorObjectKeys;

class SignUpForm extends PureComponent {
  constructor(props) {
    super(...arguments);

    this.state = {
      values: {
        [FIRST_NAME]: '',
        [LAST_NAME]: '',
        [EMAIL]: '',
        [PHONE]: '',
        [PASSWORD]: ''
      },
      errors: {
        [FIRST_NAME]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        },
        [LAST_NAME]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        },
        [EMAIL]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        },
        [PHONE]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        },
        [PASSWORD]: {
          [ERROR_MESSAGE]: '',
          [SHOW_ERROR_MESSAGE]: false
        }
      },
      formIsValid: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.submitError && this.props.submitError) {
      const { values, errors } = this.state;
      return handleServerError(this.props.submitError, values, errors, state =>
        this.setState(state)
      );
    }
  }

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      values: { ...prevState.values, [target.name]: target.value }
    }));
  };

  handleBlur = ({ target }) => {
    const { values, errors } = this.state;
    handleValidationErrorMessage(target.name, values, errors, state =>
      this.setState(state)
    );
  };

  handleKeyUp = ({ target }) => {
    validateInput(target.name, this.state.values, this.state.errors, state => {
      this.setState(state);
      this.checkFormIsValid(state);
    });
  };

  checkFormIsValid = nextState => {
    const formIsValid = validateForm(nextState.values, nextState.errors);

    if (formIsValid) {
      return this.setState({ formIsValid: true });
    }

    this.setState({ formIsValid: false });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { onSubmit, brandibbleRef } = this.props;
    const { values } = this.state;

    return onSubmit(brandibbleRef, {
      first_name: values[FIRST_NAME],
      last_name: values[LAST_NAME],
      email: values[EMAIL],
      phone: values[PHONE],
      password: values[PASSWORD]
    });
  };

  render() {
    const { values, errors } = this.state;
    const { submitStatus } = this.props;
    const isSubmitting = submitStatus === PENDING;

    return (
      <form onSubmit={this.handleSubmit}>
        <TextInput
          name={FIRST_NAME}
          label="First Name"
          value={values[FIRST_NAME]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={false}
          error={
            errors[FIRST_NAME][SHOW_ERROR_MESSAGE]
              ? errors[FIRST_NAME][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          name={LAST_NAME}
          label="Last Name"
          value={values[LAST_NAME]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={false}
          error={
            errors[LAST_NAME][SHOW_ERROR_MESSAGE]
              ? errors[LAST_NAME][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          name={EMAIL}
          value={values[EMAIL]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={false}
          error={
            errors[EMAIL][SHOW_ERROR_MESSAGE]
              ? errors[EMAIL][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          name={PHONE}
          label="phone number"
          value={values[PHONE]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={false}
          error={
            errors[PHONE][SHOW_ERROR_MESSAGE]
              ? errors[PHONE][ERROR_MESSAGE]
              : ''
          }
        />
        <TextInput
          type={PASSWORD}
          name="password"
          value={values[PASSWORD]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          autoComplete={false}
          error={
            errors[PASSWORD][SHOW_ERROR_MESSAGE]
              ? errors[PASSWORD][ERROR_MESSAGE]
              : ''
          }
        />
        <div className={f(c.flex, c.alignCenter, c.justifyEnd)}>
          <Button
            showLoading={isSubmitting}
            isDisabled={!this.state.formIsValid}
            type="submit"
            variant="goldBrick"
            text={Language.t('signUpForm.signUp')}
          />
        </div>
      </form>
    );
  }
}

SignUpForm.defaultProps = {
  onSubmit: f => f
};

SignUpForm.propTypes = {
  onSubmit: PropTypes.func,
  submitStatus: status,
  brandibbleRef: brandibbleRef
};

export default SignUpForm;
