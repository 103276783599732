import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { ENTER } from 'constants/EventKeys';

const AccordionItemHeader = ({
  label,
  children,
  toggleOpen,
  isOpen,
  tabIndex = 0
}) => {
  const handleKeyDown = e => {
    if (e.key === ENTER) {
      toggleOpen(label);
    }
  };

  return (
    <div
      role="button"
      onKeyDown={handleKeyDown}
      onClick={() => toggleOpen(label)}
      tabIndex={tabIndex}
    >
      {Children.map(children, child => React.cloneElement(child, { isOpen }))}
    </div>
  );
};

AccordionItemHeader.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string /* React.cloneElement supplied Prop */,
  toggleOpen: PropTypes.func /* React.cloneElement supplied Prop */,
  isOpen: PropTypes.bool /* React.cloneElement supplied Prop */
};

export default AccordionItemHeader;
