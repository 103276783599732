import ContainerBase from 'lib/ContainerBase';
import ContentfulData from '../lib/ContentfulData';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withDeviceWidthContext from '../lib/withDeviceWidthContext';
import withNavThemeContext from '../lib/withNavThemeContext';

class CitiesShowLocationShowMenuContainer extends ContainerBase {
  view = import('views/CitiesShowLocationsShowMenuView');

  model = () => {
    const {
      match: {
        params: { menuName }
      }
    } = this.props;

    return ContentfulData.getEntries({
      content_type: 'pageMenu',
      'fields.menuSlug': menuName,
      include: 3
    }).then(res => res.items[0]);
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withDeviceWidthContext(
    withNavThemeContext(CitiesShowLocationShowMenuContainer)
  )
);
