import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import { Status, actionTypes as brandibbleActionTypes } from 'brandibble-redux';

import onUnauthenticationSaga, {
  handleOnUnauthenticationSagaFulfilled,
  handleOnUnauthenticationSagaRejected
} from './onUnauthenticationSaga';
import onSetDefaultPaymentSaga, {
  handleOnSetDefaultPaymentSagaFulfilled,
  handleOnSetDefaultPaymentSagaRejected
} from './onSetDefaultPaymentSaga';
import onSubmitOrderSaga, {
  handleOnSubmitOrderSagaFulfilled,
  handleOnSubmitOrderSagaRejected
} from './onSubmitOrderSaga';
import onCreatePaymentMethodSaga, {
  handleOnCreatePaymentMethodSagaFulfilled,
  handleOnCreatePaymentMethodSagaRejected
} from './onCreatePaymentMethodSaga';
import onSetRequestedAtSaga, {
  handleOnSetRequestedAtSagaFulfilled,
  handleOnSetRequestedAtSagaRejected
} from './onSetRequestedAtSaga';

const { FULFILLED, REJECTED } = Status;
const {
  UNAUTHENTICATE_USER,
  CREATE_NEW_ORDER,
  SET_DEFAULT_PAYMENT,
  SET_PAYMENT_METHOD,
  SUBMIT_ORDER,
  CREATE_PAYMENT,
  SET_REQUESTED_AT,
  FETCH_MENU,
  FETCH_LOCATION
} = brandibbleActionTypes;

export default function*() {
  yield all([
    takeLatest(`${UNAUTHENTICATE_USER}_${FULFILLED}`, onUnauthenticationSaga),
    takeEvery(
      `${CREATE_NEW_ORDER}_${FULFILLED}`,
      handleOnUnauthenticationSagaFulfilled
    ),
    takeEvery(
      `${CREATE_NEW_ORDER}_${REJECTED}`,
      handleOnUnauthenticationSagaRejected
    ),
    /**
     * onSetDefaultPaymentSaga
     */
    takeLatest(`${SET_DEFAULT_PAYMENT}_${FULFILLED}`, onSetDefaultPaymentSaga),
    takeEvery(
      `${SET_PAYMENT_METHOD}_${FULFILLED}`,
      handleOnSetDefaultPaymentSagaFulfilled
    ),
    takeEvery(
      `${SET_PAYMENT_METHOD}_${REJECTED}`,
      handleOnSetDefaultPaymentSagaRejected
    ),
    /**
     * onSubmitOrderSaga
     */
    takeLatest(`${SUBMIT_ORDER}_${FULFILLED}`, onSubmitOrderSaga),
    takeEvery(
      `${CREATE_NEW_ORDER}_${FULFILLED}`,
      handleOnSubmitOrderSagaFulfilled
    ),
    takeEvery(
      `${CREATE_NEW_ORDER}_${REJECTED}`,
      handleOnSubmitOrderSagaRejected
    ),
    /**
     * onCreatePaymentMethodSaga
     */
    takeLatest(`${CREATE_PAYMENT}_${FULFILLED}`, onCreatePaymentMethodSaga),
    takeEvery(
      `${SET_DEFAULT_PAYMENT}_${FULFILLED}`,
      handleOnCreatePaymentMethodSagaFulfilled
    ),
    takeEvery(
      `${SET_DEFAULT_PAYMENT}_${REJECTED}`,
      handleOnCreatePaymentMethodSagaRejected
    ),
    /**
     * onSetRequestedAtSaga
     */
    takeLatest(`${SET_REQUESTED_AT}_${FULFILLED}`, onSetRequestedAtSaga),
    takeEvery(
      [`${FETCH_MENU}_${FULFILLED}`, `${FETCH_LOCATION}_${FULFILLED}`],
      handleOnSetRequestedAtSagaFulfilled
    ),
    takeEvery(
      [`${FETCH_MENU}_${REJECTED}`, `${FETCH_LOCATION}_${REJECTED}`],
      handleOnSetRequestedAtSagaRejected
    )
  ]);
}
