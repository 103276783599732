export default {
  meta: {
    title: `Tartine Bakery`,
    description: `Tartine is a thoughtful expression of modern craft through good food and warm experiences. We are a product of our communities and our friends.`,
    image: `assets/tartine-share.jpg`,
    handle: `@tartinebakery`,
    siteName: `Tartine`,
    url: `https://www.tartinebakery.com`
  },
  header: {
    locationLinks: [
      {
        label: `SF`,
        path: `/`,
        subLinks: [
          {
            label: `Bakery`,
            path: `/san-francisco/bakery`
          },
          {
            label: `Manufactory`,
            path: `/san-francisco/manufactory`
          }
        ]
      },
      {
        titleLinkLabel: `LA`,
        path: `/`,
        subLinks: [
          {
            label: `The Row`,
            path: `/los-angeles/the-row`
          },
          {
            label: `Location 1`,
            path: `/los-angeles/location-1`
          }
        ]
      },
      {
        titleLinkLabel: `SEOUL`,
        path: `/`,
        subLinks: [
          {
            label: `Yongsan-Gu`,
            path: `/seoul/yongsan-gu`
          }
        ]
      }
    ],
    aboutUsLinks: [
      {
        label: 'Story',
        path: '/about/our-story'
      },
      {
        label: 'Bread',
        path: '/about/our-bread'
      },
      {
        label: 'Cookbooks',
        path: '/about/cookbooks'
      }
    ]
  },
  mobileMenu: {
    orderingEnabledLinks: [
      {
        label: 'Order',
        path: '/'
      }
    ],
    navLinks: [
      {
        label: 'Join Tartine',
        path: '/stories/join-the-team'
      },
      {
        label: 'Press',
        path: '/press'
      }
    ]
  },
  footer: {
    navLinks: [
      {
        label: 'Locations',
        path: '/cities',
        mailto: false,
        external: false,
        orderingEnabledOnly: false
      },
      {
        label: 'National Shipping',
        path: 'https://www.goldbelly.com/tartine-bakery',
        mailto: false,
        external: true,
        orderingEnabledOnly: false
      },
      {
        label: 'About Us',
        path: '/about',
        mailto: false,
        external: false,
        orderingEnabledOnly: false
      },
      {
        label: 'Join Tartine',
        path: '/stories/join-the-team',
        mailto: false,
        external: false,
        orderingEnabledOnly: false
      },
      {
        label: 'Press',
        path: '/press',
        mailto: false,
        external: false,
        orderingEnabledOnly: false
      }
    ],
    contactLinks: [
      {
        label: 'info@tartinebakery.com',
        path: 'info@tartinebakery.com',
        mailto: true,
        external: false
      },
      {
        label: 'info@tartinemanufactory.com',
        path: 'info@tartinemanufactory.com',
        mailto: true,
        external: false
      },
      {
        label: 'press@tartinebakery.com',
        path: 'press@tartinebakery.com',
        mailto: true,
        external: false
      }
    ],
    socialLinks: [
      {
        label: 'Facebook',
        path: 'https://www.facebook.com/tartinebakerysf/',
        mailto: false,
        external: true
      },
      {
        label: 'Instagram',
        path: 'https://www.instagram.com/tartinebakery/?hl=en',
        mailto: false,
        external: true
      }
    ],
    logo: {
      path: '/'
    },
    privacy: {
      path: '/privacy-policy'
    },
    terms: {
      path: '/terms-of-service'
    },
    sitemap: {
      path: '/site-map'
    }
  }
};
