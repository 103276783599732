import { createSelector } from 'reselect';
import get from 'utils/get';

export default createSelector(
  state => get(state, 'brandibble.session.menus'),
  state => get(state, 'brandibble.session.order.orderData'),
  (allMenus, currentOrder) => {
    if (!allMenus) return null;

    const locationId = get(currentOrder, 'location_id');
    const serviceType = get(currentOrder, 'service_type');
    const requestedAt = get(currentOrder, 'requested_at');

    const currentMenuKey = `${locationId}_${serviceType}_${requestedAt}`;
    const currentMenu = allMenus[currentMenuKey];

    if (!currentMenu) return null;

    return currentMenu;
  }
);
