import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';

const UnauthenticatedRoute = ({
  component: Component,
  redirectPath,
  isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Redirect to={redirectPath} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

UnauthenticatedRoute.defaultProps = {
  redirectPath: '/profile',
  isAuthenticated: false
};

UnauthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  redirectPath: PropTypes.string,
  isAuthenticated: PropTypes.bool
};

export default UnauthenticatedRoute;
