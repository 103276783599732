import { createSelector } from 'reselect';

import get from 'utils/get';
import isEmptyObject from 'utils/isEmptyObject';

import _flattenDeep from 'lodash/flattenDeep';

export default createSelector(
  state => get(state, 'global.data', {}),
  globalData => {
    if (isEmptyObject(globalData)) return [];

    const { fields } = globalData;

    return _flattenDeep(
      get(fields, 'cities', []).map(city =>
        get(city, 'fields.locations', []).map((location, index) => ({
          id: get(location, 'sys.id'),
          city: get(city, 'fields.title'),
          listIndex: (index += 1),
          ...get(location, 'fields', [])
        }))
      )
    );
  }
);
