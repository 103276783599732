import { createSelector } from 'reselect';
import get from 'utils/get';
import calculateTotalLineItemPrice from 'utils/calculateTotalLineItemPrice';
import currency from 'currency.js';
import { OLO, CATERING } from 'constants/MenuTypes';
import { allLocations, matchingMenuItemsForLineItems } from 'state/selectors';

export default createSelector(
  state => get(state, 'brandibble.session.order.orderData', {}),
  state => get(state, 'brandibble.session.order.lineItemsData', []),
  state => matchingMenuItemsForLineItems(state),
  state => get(state, 'brandibble.data.locations', {}),
  state => allLocations(state),
  (
    orderData,
    lineItemsData,
    menuItemsForLineItemsData,
    locations,
    allLocations
  ) => {
    if (!orderData.location_id || !orderData.cart.length) return null;

    const currentOrderLocationId = get(orderData, 'location_id');
    const currentOrderMenuLocation = get(locations, 'locationsById', {})[
      currentOrderLocationId
    ];
    const isCatering = allLocations.find(
      location =>
        get(location, 'brandibbleCateringLocationId') === currentOrderLocationId
    );
    const totalFromLineItemsData = lineItemsData.reduce((total, lineItem) => {
      const lineItemTotal = calculateTotalLineItemPrice(lineItem);
      return currency(total).add(lineItemTotal);
    }, '');

    return {
      locationName: get(currentOrderMenuLocation, 'name', ''),
      locationCity: get(currentOrderMenuLocation, 'city', ''),
      locationStateCode: get(currentOrderMenuLocation, 'state_code', ''),
      menuType: isCatering ? CATERING : OLO,
      lineItems: lineItemsData,
      menuItemsForLineItems: menuItemsForLineItemsData,
      cartSubtotal: totalFromLineItemsData
    };
  }
);
