import { createSelector } from 'reselect';

import get from 'utils/get';
import { DateTime } from 'luxon';

export default createSelector(
  state => get(state, 'brandibble.data.customerOrders.recentSubmission', null),
  state => get(state, 'brandibble.data.locations.locationsById', {}),
  (recentSubmission, locationsById) => {
    if (!recentSubmission) return {};

    return {
      orders_id: get(recentSubmission, 'orders_id'),
      requested_datetime: DateTime.fromISO(
        get(recentSubmission, 'requested_at')
      ),
      location_name: get(
        locationsById[get(recentSubmission, 'location_id')],
        'name',
        ''
      ),
      items: get(recentSubmission, 'cart', []),
      status: get(recentSubmission, 'status', 'open'),
      subtotal: get(recentSubmission, 'subtotal'),
      tax: get(recentSubmission, 'tax'),
      tip: get(recentSubmission, 'tip'),
      total: get(recentSubmission, 'total'),
      isRecent: true
    };
  }
);
