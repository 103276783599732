import React, { PureComponent } from 'react';
import currency from 'currency.js';
import { brandibbleLineItem, brandibbleMenuItem } from 'constants/PropTypes';
import get from 'utils/get';
import calculateTotalLineItemPrice from 'utils/calculateTotalLineItemPrice';
import {
  getPresentOptionItems,
  getOptionGroupData,
  getOptionItemData
} from 'utils/getMenuItemsForLineItems';

import withLineItemActions from 'lib/withLineItemActions';
import { Text } from 'components/base';
import SpecialInstructionsForLineItem from 'components/SpecialInstructionsForLineItem';
import QuantitySpinnerWithRemove from 'components/QuantitySpinnerWithRemove';

import v from 'vudu';
import { styles as s, classes as c, format as f } from 'styles';

const classes = v({
  cartItemCard: {
    '@composes': [s.mb7]
  },
  cartItemDetails: {
    '@composes': [s.mb4]
  },
  quantitySpinnerWrapper: {
    maxWidth: `250px`
  }
});

class CartSummaryItemCard extends PureComponent {
  renderOptions = (lineItem, lineItemData) => {
    const optionGroupMappings = get(lineItem, 'optionGroupMappings', []);
    if (!optionGroupMappings.length) return null;

    const presentOptionItems = getPresentOptionItems(optionGroupMappings);

    return (
      <div className={c.ml5}>
        {presentOptionItems.map(presentOption => {
          const optionGroupData = getOptionGroupData(
            get(lineItemData, 'option_groups', []),
            get(presentOption, 'optionItemData.group_id')
          );

          const optionItemData = getOptionItemData(
            get(optionGroupData, 'option_items', []),
            get(presentOption, 'optionItemData.id')
          );

          const optionName = get(optionItemData, 'name');
          const optionQuantity = get(presentOption, 'quantity');
          const hasMoreThanOne = optionQuantity > 1;

          return (
            <Text key={get(optionItemData, 'id')} variant="paragraph">
              {hasMoreThanOne
                ? `- ${optionQuantity}x ${optionName}`
                : `- ${optionName}`}
            </Text>
          );
        })}
      </div>
    );
  };

  render() {
    const { lineItem, lineItemData } = this.props;
    const totalLineItemPrice = calculateTotalLineItemPrice(lineItem);
    const lineItemQuantity = get(this, 'props.lineItem.quantity', 0);
    const lineItemMinQuantity = get(lineItem, 'productData.min_quantity', 0);
    const lineItemMaxQuantity = get(lineItem, 'productData.max_quantity', 0);

    return (
      <div className={classes.cartItemCard}>
        <div className={classes.cartItemDetails}>
          <div className={f(c.flex, c.alignCenter, c.spaceBetween)}>
            <Text variant="paragraph">{get(lineItemData, 'name', '')}</Text>
            {get(lineItemData, 'price') && (
              <Text variant="paragraph">
                {currency(totalLineItemPrice, {
                  formatWithSymbol: true
                }).format()}
              </Text>
            )}
          </div>
          {this.renderOptions(lineItem, lineItemData)}
          <SpecialInstructionsForLineItem
            instructions={get(lineItem, 'instructions', '')}
          />
        </div>
        <div className={classes.quantitySpinnerWrapper}>
          <QuantitySpinnerWithRemove
            quantity={lineItemQuantity}
            canDecrement={lineItemQuantity > lineItemMinQuantity}
            canIncrement={lineItemQuantity < lineItemMaxQuantity}
            handleIncrement={get(this, 'props.addOrIncrementLineItem')}
            handleDecrement={get(this, 'props.decrementOrRemoveLineItem')}
            handleRemove={get(this, 'props.removeLineItem')}
          />
        </div>
      </div>
    );
  }
}

CartSummaryItemCard.defaultProps = {
  lineItem: {},
  lineItemData: {}
};

CartSummaryItemCard.propTypes = {
  lineItem: brandibbleLineItem,
  lineItemData: brandibbleMenuItem
};

export default withLineItemActions(CartSummaryItemCard);
