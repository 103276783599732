import { ARROW_LEFT, ARROW_RIGHT } from 'constants/Glyphs';
import { size } from './sizing';
import colors from './colors';
import type, { fontClasses } from './type';
import transitions from './transitions';

const buttonVariants = {
  basicText: {
    ...type.modalTabLabel,
    ...transitions.transitionBorder,
    ...fontClasses.monoBold,
    letterSpacing: `0.166875rem`,
    // Offsets last letter's spacing
    span: {
      marginRight: `-0.166875rem`
    },
    borderBottom: `solid 1px ${colors.transparent}`,
    ':hover': {
      borderBottomColor: colors.darkGray
    }
  },
  blackPill: {
    ...type.cta,
    ...transitions.transitionBackground,
    padding: `0.375rem 2.1666rem`,
    borderRadius: `1px`,
    border: `solid 1px ${colors.darkGray}`,
    backgroundColor: colors.darkGray,
    color: 'white',
    ':hover': {
      backgroundColor: colors.transparent,
      color: colors.darkGray
    }
  },
  blackPillSmall: {
    ...type.labelSmall,
    ...transitions.transitionBackground,
    padding: `0.5rem`,
    borderRadius: `1px`,
    border: `solid 1px ${colors.darkGray}`,
    backgroundColor: colors.darkGray,
    color: 'white',
    ':hover': {
      backgroundColor: colors.transparent,
      color: colors.darkGray
    }
  },
  goldBrick: {
    ...type.cta,
    ...transitions.transitionBackground,
    padding: `${size(5)} ${size(10)}`,
    borderRadius: `1px`,
    border: `solid 1px ${colors.gold}`,
    backgroundColor: colors.transparent,
    color: colors.darkerGray,
    ':hover': {
      backgroundColor: colors.gold
    }
  },
  goldBrickSmall: {
    ...type.cta,
    ...transitions.transitionBackground,
    padding: `${size(5)} ${size(6)}`,
    borderRadius: `1px`,
    border: `solid 1px ${colors.gold}`,
    backgroundColor: colors.transparent,
    color: colors.black
  },
  goldBrickSmallSolid: {
    ...type.modalFooter,
    ...transitions.transitionBackground,
    padding: `${size(4)} ${size(6)}`,
    borderRadius: `1px`,
    border: `solid 1px ${colors.gold}`,
    backgroundColor: colors.gold,
    color: colors.darkerGray,
    ':hover': {
      backgroundColor: colors.transparent
    }
  },
  goldBrickSolid: {
    ...type.cta,
    ...transitions.transitionBackground,
    padding: `${size(5)} ${size(8)}`,
    borderRadius: `1px`,
    border: `solid 1px ${colors.gold}`,
    backgroundColor: colors.gold,
    color: colors.darkerGray,
    ':hover': {
      backgroundColor: colors.transparent
    }
  },
  goldUnderlineWithArrow: {
    ...type.cta,
    ...transitions.transitionBorder,
    padding: `${size(5)} 0`,
    borderBottom: `solid 1px ${colors.gold}`,
    color: colors.darkGray,
    '::after': {
      content: `"${ARROW_RIGHT}"`,
      marginLeft: `${size(3)}`
    },
    ':hover': {
      borderBottomColor: colors.transparent
    }
  },
  blackUnderlineWithArrow: {
    ...type.announcement,
    ...transitions.transitionBorder,
    padding: `${size(2)} 0`,
    borderBottom: `solid 1px ${colors.darkGray}`,
    color: colors.darkGray,
    '::after': {
      content: `"${ARROW_RIGHT}"`,
      marginLeft: `${size(3)}`
    },
    ':hover': {
      borderBottomColor: colors.transparent
    }
  },
  blackWithArrow: {
    ...type.cta,
    color: colors.darkGray,
    '::before': {
      content: `"${ARROW_LEFT}"`,
      marginRight: `${size(3)}`
    }
  },
  goldUnderline: {
    ...type.attribution,
    ...transitions.transitionBorder,
    padding: `${size(3)} 0`,
    borderBottom: `solid 1px ${colors.gold}`,
    color: colors.darkGray,
    ':hover': {
      borderBottomColor: colors.transparent
    }
  },
  basicTextDisabled: {
    color: colors.gray,
    cursor: 'not-allowed',
    pointerEvents: 'none'
  },
  disabled: {
    backgroundColor: colors.lightGray,
    color: colors.gray,
    borderColor: colors.lightGray,
    cursor: 'not-allowed',
    pointerEvents: 'none'
  }
};

export default buttonVariants;
