import get from 'utils/get';
import nowInTimezone from 'utils/nowInTimezone';
import {
  fetchMenu,
  fetchLocation,
  validateCurrentCart,
  removeLineItem
} from 'brandibble-redux';
import { setModal } from 'state/actions/ui/modalActions';
import { timezoneForCurrentLocation } from 'state/selectors';
import { ASAP, PICKUP, ErrorCodes } from 'constants/Brandibble';
import {
  INVALID_ITEMS_MODAL,
  LOCATION_CLOSED_MODAL
} from 'constants/ModalVariants';

export const FETCH_AND_SETUP_MENU = 'FETCH_AND_SETUP_MENU';
export const VALIDATE_AND_REMOVE_INVALID_ITEMS =
  'VALIDATE_AND_REMOVE_INVALID_ITEMS';
export const REMOVE_INVALID_ITEMS = 'REMOVE_INVALID_ITEMS';

export const removeInvalidItems = invalidItems => (dispatch, getState) => {
  const state = getState();
  const orderRef = get(state, 'brandibble.session.order.ref');

  const promises = invalidItems.map(invalidItem =>
    dispatch(removeLineItem(orderRef, invalidItem))
  );

  const payload = Promise.all(promises);

  return dispatch({
    type: REMOVE_INVALID_ITEMS,
    payload
  });
};

export const validateAndRemoveInvalidItems = (locationId, requestedAt) => (
  dispatch,
  getState
) => {
  const state = getState();
  const brandibbleRef = get(state, 'brandibble.ref');

  const validationsHash = Object.assign(
    {},
    !!locationId && { location_id: locationId },
    !!requestedAt && { requested_at: requestedAt }
  );

  const payload = dispatch(
    validateCurrentCart(brandibbleRef, validationsHash)
  ).catch(res => {
    if (res && res.errors) {
      if (
        get(res.errors[0], 'code') === ErrorCodes.validateCart.locationIsClosed
      ) {
        // If location is closed
        // find next available daypart
        // and notify user, they will be ordering for then
        return dispatch(
          setModal(LOCATION_CLOSED_MODAL, {
            locationId
          })
        );
      }

      // If there are invalid items in the cart
      // show the invalid items modal
      if (get(res.errors[0], 'code') === ErrorCodes.validateCart.invalidItems) {
        const [, ...invalidItems] = res.errors;
        return dispatch(
          setModal(INVALID_ITEMS_MODAL, {
            invalidItems,
            locationId
          })
        );
      }
    }
  });

  return dispatch({ type: VALIDATE_AND_REMOVE_INVALID_ITEMS, payload });
};

export const fetchAndSetupMenu = locationId => (dispatch, getState) => {
  const state = getState();
  const brandibbleRef = get(state, 'brandibble.ref');
  const currentOrderData = get(state, 'brandibble.session.order.orderData');
  const serviceType = get(currentOrderData, 'service_type', PICKUP);
  const currentOrderRequestedAt = get(currentOrderData, 'requested_at');

  const timezoneForCurrentOrder = timezoneForCurrentLocation(state);
  const requestedAtIsAsap = currentOrderRequestedAt === ASAP;
  const requestedAtAsValidISO8601Format = requestedAtIsAsap
    ? nowInTimezone(timezoneForCurrentOrder)
    : currentOrderRequestedAt;

  const payload = Promise.all([
    dispatch(
      fetchMenu(
        brandibbleRef,
        {
          locationId,
          serviceType,
          requestedAt: requestedAtAsValidISO8601Format
        },
        {
          isAsap: requestedAtIsAsap
        }
      )
    ),
    dispatch(
      fetchLocation(brandibbleRef, locationId, {
        service_type: serviceType,
        requested_at: requestedAtAsValidISO8601Format,
        include_times: true
      })
    )
  ]);

  return dispatch({
    type: FETCH_AND_SETUP_MENU,
    payload
  });
};
