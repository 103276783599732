import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { link, linkWithSublinks } from 'constants/PropTypes';

import Arrow from 'components/svg/Arrow';
import { SmartLink, Text } from 'components/base';
import { TAB, ENTER } from 'constants/EventKeys';

import get from 'utils/get';

import cx from 'classnames';
import v from 'vudu';
import { format as f, colors, size, classes as c, styles as s } from 'styles';
import keyboardAccessible from '../../utils/keyboardAccessible';

const classes = v({
  dropdownLink: {
    '@composes': [s.relative, s.inlineBlock, s.bgTransparent]
  },
  dropdownContainer: {
    '@composes': [s.absolute, s.dropdownShadow],
    top: '100%',
    opacity: 0,
    visibility: `hidden`,
    pointerEvents: `none`,
    backgroundColor: colors.white,
    padding: `${size(9)}`,
    textAlign: `left`
  },
  dropdownSection: {
    '@composes': [s.flex, s.flexColumn, s.mt6]
  },
  dropdownOpen: {
    opacity: 1,
    visibility: `visible`,
    pointerEvents: `auto`
  },
  dropdownLinkItem: {
    '@composes': [s.block, s.nowrap, s.py1, s.hoverOpacity]
  }
});

class DropdownLink extends PureComponent {
  state = {
    dropdownIsOpen: false
  };

  toggleDropdownOpen = () => {
    this.setState({ dropdownIsOpen: true });
  };

  toggleDropdownClose = () => {
    this.setState({ dropdownIsOpen: false });
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownIsOpen: !prevState.dropdownIsOpen
    }));
  };

  lastDropdownElement = () => {
    const links = get(this, 'props.links', []);
    const lastLink = links[links.length - 1];
    const lastLinkSubLinks = get(lastLink, 'links', []);

    if (lastLinkSubLinks.length) {
      return lastLinkSubLinks[lastLinkSubLinks.length - 1];
    }

    return lastLink;
  };

  handleKeyDownOnTrigger = e => {
    if (e.key === ENTER) {
      e.preventDefault();
      this.toggleDropdown();
    }

    if (e.shiftKey && e.key === TAB) {
      this.toggleDropdownClose();
    }
  };

  // Tab on last dropdown item to close menu
  handleKeyDownOnLastItem = e => {
    if (!e.shiftKey && e.key === TAB) {
      this.toggleDropdownClose();
    }
  };

  // TODO: Use better accessibility / keyboard navigation
  handleKeyDownItem = (e, link) => {
    if (
      link.label === get(this.lastDropdownElement(), 'label') &&
      !e.shiftKey &&
      e.key === TAB
    ) {
      this.toggleDropdownClose();
      return;
    }

    if (e.key === ENTER) {
      link.onClick();
    }
  };

  renderLink = link => {
    const lastDropdownElement = this.lastDropdownElement();
    return link.path ? (
      <SmartLink
        className={classes.dropdownLinkItem}
        key={link.label}
        to={link.path}
        external={get(link, 'external', false)}
        nav={get(link, 'nav', false)}
        onKeyDown={
          link.path === get(lastDropdownElement, 'path')
            ? this.handleKeyDownOnLastItem
            : null
        }
      >
        <Text variant="dropdownNavItem">{link.label}</Text>
      </SmartLink>
    ) : (
      <SmartLink
        controlled
        key={link.label}
        className={classes.dropdownLinkItem}
        onClick={link.onClick}
        onKeyDown={e => this.handleKeyDownItem(e, link)}
      >
        <Text variant="dropdownNavItem">{link.label}</Text>
      </SmartLink>
    );
  };

  renderSections = (subLink, index) => {
    if (get(subLink, 'links')) {
      return (
        <div className={classes.dropdownSection} key={index}>
          <SmartLink
            to={get(subLink, 'path')}
            external={get(subLink, 'external', false)}
            nav={get(subLink, 'nav', false)}
          >
            <Text
              className={classes.dropdownLinkItem}
              variant="dropdownNavHeadingItem"
              color="black"
            >
              {get(subLink, 'label')}
            </Text>
          </SmartLink>
          {subLink.links.map(this.renderLink)}
        </div>
      );
    }

    return this.renderLink(subLink);
  };

  render() {
    const alignDropdownRight = get(this, 'props.alignDropdownRight');
    const className = get(this, 'props.className');
    const label = get(this, 'props.label');
    const path = get(this, 'props.path');
    const links = get(this, 'props.links');
    const color = get(this, 'props.color');

    return (
      <div
        tabIndex={0}
        onKeyDown={e => e.key === ENTER && this.toggleDropdownOpen()}
        className={f(classes.dropdownLink, className)}
        type="button"
        onMouseEnter={this.toggleDropdownOpen}
        onMouseLeave={this.toggleDropdownClose}
        onClick={this.toggleDropdown}
      >
        {!!path ? (
          <SmartLink
            {...keyboardAccessible}
            nav
            className={f(
              c.inlineFlex,
              c.justifyCenter,
              c.alignCenter,
              c.hoverOpacity
            )}
            to={path}
            onKeyDown={this.handleKeyDownOnTrigger}
          >
            <Text color={color} variant="navItem">
              {label}
            </Text>
            <Arrow className={f(c.ml3, c.rotate180)} />
          </SmartLink>
        ) : (
          <span
            className={f(
              c.inlineFlex,
              c.justifyCenter,
              c.alignCenter,
              c.hoverOpacity
            )}
          >
            <Text color={color} variant="navItem">
              {label}
            </Text>
            <Arrow className={f(c.ml3, c.rotate180)} />
          </span>
        )}
        <div
          className={cx(classes.dropdownContainer, {
            [c.r0]: !!alignDropdownRight,
            [classes.dropdownOpen]: this.state.dropdownIsOpen
          })}
        >
          {links.map(this.renderSections)}
        </div>
      </div>
    );
  }
}

DropdownLink.defaultProps = {
  className: null,
  path: null,
  alignDropdownRight: false
};

DropdownLink.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.oneOfType([link, linkWithSublinks]))
    .isRequired,
  alignDropdownRight: PropTypes.bool
};

export default DropdownLink;
