import React from 'react';
import PropTypes from 'prop-types';

import { Redirect, Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from 'lib/AuthenticatedRoute';
import UnauthenticatedRoute from 'lib/UnauthenticatedRoute';

import HomeContainer from 'containers/HomeContainer';
import PressContainer from 'containers/PressContainer';
import PrivacyContainer from 'containers/PrivacyContainer';
import TermsContainer from 'containers/TermsContainer';
import SitemapContainer from 'containers/SitemapContainer';
import CheckoutContainer from 'containers/CheckoutContainer';
import SupportContainer from 'containers/SupportContainer';

// Unauthenticated Routes
import SignInContainer from 'containers/SignInContainer';
import SignUpContainer from 'containers/SignUpContainer';
import ForgotPasswordContainer from 'containers/ForgotPasswordContainer';
import ResetPasswordContainer from 'containers/ResetPasswordContainer';

// Authenticated Routes
import ProfileContainer from 'containers/ProfileContainer';

import StoriesContainer from 'containers/StoriesContainer';
import StoriesIndexContainer from 'containers/StoriesIndexContainer';
import StoriesShowContainer from 'containers/StoriesShowContainer';

import CitiesContainer from 'containers/CitiesContainer';
import CitiesShowContainer from 'containers/CitiesShowContainer';

import CitiesShowLocationsContainer from 'containers/CitiesShowLocationsContainer';
import CitiesShowLocationsShowContainer from 'containers/CitiesShowLocationsShowContainer';
import CitiesShowLocationsShowOrderMenuContainer from 'containers/CitiesShowLocationsShowOrderMenuContainer';
import CitiesShowLocationsShowCateringMenuContainer from 'containers/CitiesShowLocationsShowCateringMenuContainer';

import OrdersContainer from 'containers/OrdersContainer';
import OrdersShowContainer from 'containers/OrdersShowContainer';

// Google Tag Manager
import TagManager from 'react-gtm-module';
import CitiesShowLocationShowMenuContainer from './containers/CitiesShowLocationsShowMenuContainer';
import CareersContainer from './containers/CareersContainer';

const tagManagerArgs = {
  gtmId: 'GTM-M3BZ4WX'
};

TagManager.initialize(tagManagerArgs);

const Redirects = [{ path: '/bread', redirectTo: '/about/tartine-bread' }];

const Routes = ({ location, userIsAuthenticated }) => (
  <Switch location={location}>
    {(Redirects || []).map(redirect => (
      <Route
        exact
        path={redirect.path}
        component={() => <Redirect to={redirect.redirectTo} />}
      />
    ))}
    <Route exact path="/" component={HomeContainer} />
    <Route
      exact
      path="/sign-up"
      component={SignUpContainer}
      userIsAuthenticated={userIsAuthenticated}
    />
    <UnauthenticatedRoute
      exact
      path="/sign-in"
      component={SignInContainer}
      isAuthenticated={userIsAuthenticated}
    />
    <UnauthenticatedRoute
      exact
      path="/forgot-password"
      component={ForgotPasswordContainer}
      isAuthenticated={userIsAuthenticated}
    />
    <UnauthenticatedRoute
      exact
      path="/reset-password"
      component={ResetPasswordContainer}
      isAuthenticated={userIsAuthenticated}
    />
    <AuthenticatedRoute
      exact
      path="/profile"
      redirectPath="/sign-in"
      component={ProfileContainer}
      isAuthenticated={userIsAuthenticated}
    />
    <Route exact path="/press" component={PressContainer} />
    <Route path="/job-openings" component={CareersContainer} />
    <Route exact path="/privacy-policy" component={PrivacyContainer} />
    <Route exact path="/terms-of-service" component={TermsContainer} />
    <Route exact path="/site-map" component={SitemapContainer} />
    <Route exact path="/cities" component={CitiesContainer} />
    <Route exact path="/checkout" component={CheckoutContainer} />
    <Route exact path="/support" component={SupportContainer} />
    <Route path="/orders" component={OrdersContainer} />
    <Route path="/about" component={StoriesContainer} />
    <Route path="/stories" component={StoriesContainer} />
    <Route path="/:cityName" component={CitiesShowContainer} />
    <Redirect to="/" />
  </Switch>
);

export const StoriesOutlet = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={StoriesIndexContainer} />
    <Route
      exact
      path={`${match.path}/:storySlug`}
      component={StoriesShowContainer}
    />
    <Redirect to="/" />
  </Switch>
);

export const CitiesShowOutlet = ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} component={CitiesShowLocationsContainer} />
    <Redirect to="/" />
  </Switch>
);

export const CitiesShowLocationsOutlet = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.path}/:locationName`}
      component={CitiesShowLocationsShowContainer}
    />
    <Route
      exact
      path={`${match.path}/:locationName/order`}
      component={CitiesShowLocationsShowOrderMenuContainer}
    />
    <Route
      exact
      path={`${match.path}/:locationName/catering`}
      component={CitiesShowLocationsShowCateringMenuContainer}
    />
    <Route
      exact
      path={`${match.path}/:locationName/menu/:menuName`}
      component={CitiesShowLocationShowMenuContainer}
    />
    <Redirect to="/" />
  </Switch>
);

export const OrdersOutlet = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.path}/:orderId`}
      component={OrdersShowContainer}
    />
    <Redirect to="/" />
  </Switch>
);

Routes.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    key: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  userIsAuthenticated: PropTypes.bool.isRequired
};

export default Routes;
